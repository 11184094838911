/* eslint-disable */
require('./advice')
require('./arrow_big')
require('./arrow-long-2')
require('./arrow-long-3')
require('./arrow-long-4')
require('./arrow-long')
require('./attention')
require('./book-open')
require('./book-presentation')
require('./briefcase')
require('./calendar-2')
require('./calendar')
require('./check-circle')
require('./check')
require('./circle-replacement')
require('./circle-tank-storage')
require('./circle-windmill')
require('./close')
require('./closure')
require('./cn')
require('./co2')
require('./conformity')
require('./cooperation')
require('./covid_plan')
require('./covid')
require('./cu')
require('./danger_mini')
require('./danger')
require('./decline')
require('./delivery-ship')
require('./delivery')
require('./development')
require('./dometory')
require('./download')
require('./efficiency-big')
require('./efficiency')
require('./enrichment')
require('./esg-big')
require('./esg-numbers')
require('./esg-round')
require('./esg')
require('./excursion')
require('./external')
require('./eye')
require('./faq-answer')
require('./faq-attention')
require('./faq-question')
require('./fax')
require('./file-big')
require('./file-small')
require('./file-text')
require('./file')
require('./filtering')
require('./financial-growth')
require('./financial-results')
require('./flag')
require('./flagbanner')
require('./folder')
require('./fon')
require('./gear-line')
require('./gear')
require('./geological-exploration')
require('./goal-big')
require('./gold')
require('./google')
require('./hands')
require('./ical')
require('./icon_element_backbone_infrastructure')
require('./icon_element_culture')
require('./icon_element_demography')
require('./icon_element_digital_economy')
require('./icon_element_ecology')
require('./icon_element_education')
require('./icon_element_healthcare')
require('./icon_element_housing_and_urban_environment')
require('./icon_element_labor_productivity')
require('./icon_element_science_and_universities')
require('./icon_element_small_and_medium_enterprises')
require('./icon_element_tourism')
require('./icon_element')
require('./icon_elementTe')
require('./icon-brand-care-text')
require('./icon-brand-care')
require('./icon-brand-efficiency-text')
require('./icon-brand-efficiency')
require('./icon-brand-safety-text')
require('./icon-brand-safety')
require('./Icon')
require('./icons')
require('./img')
require('./industrial')
require('./leader_big')
require('./leader')
require('./leaf-air')
require('./loader')
require('./logistics-sales')
require('./logo_sulfur')
require('./logo-for-contactcard')
require('./m1')
require('./m2')
require('./m3')
require('./m4')
require('./m5')
require('./magnifier')
require('./man')
require('./map_all-default')
require('./map_asset_default')
require('./map_asset')
require('./map_delivery')
require('./map_development_default')
require('./map_development')
require('./map_sales_default')
require('./map_sales')
require('./map_ship_default')
require('./map_ship')
require('./map-all')
require('./marketing')
require('./medical-case')
require('./medical-ventilators')
require('./megaworker')
require('./menu')
require('./message')
require('./metal-production')
require('./mic')
require('./microscope')
require('./mine-digital')
require('./mine')
require('./mini_history')
require('./minilogo')
require('./mining-industry')
require('./mining-processing')
require('./mining')
require('./minning-2')
require('./mintransport')
require('./monitor-play')
require('./monitor')
require('./ni')
require('./niokr')
require('./Odnoklassniki')
require('./ore')
require('./outlook')
require('./paperclip')
require('./pause_bold')
require('./people-default')
require('./phone')
require('./pin-nn')
require('./pin')
require('./piople_save')
require('./play_bold')
require('./play-block')
require('./play')
require('./plus')
require('./point')
require('./product-lme')
require('./product-shfe')
require('./products-burger')
require('./professionalism')
require('./profit')
require('./protect-mask')
require('./pulse')
require('./quote')
require('./reanimobiles')
require('./Rectangle 9634')
require('./refining')
require('./refresh')
require('./reliability')
require('./replacement')
require('./reporting')
require('./reserves')
require('./reward')
require('./rf-big-white')
require('./rf-block')
require('./rf-small-blue')
require('./RocketLaunch')
require('./round_mail _hover')
require('./round_mail')
require('./round_pdf')
require('./round-arrow-folder')
require('./round-arrow')
require('./rss')
require('./sap')
require('./search')
require('./server')
require('./share-2')
require('./share')
require('./ship')
require('./smartlab')
require('./smelting')
require('./social-card')
require('./social-group')
require('./sort-down')
require('./sort-up')
require('./sources')
require('./statistics')
require('./storage')
require('./sufficiency')
require('./tadviser')
require('./tags')
require('./tank-storage')
require('./tech-leadership')
require('./techno-machine')
require('./telegram')
require('./tests')
require('./text')
require('./time')
require('./tooltip-hover')
require('./tooltip')
require('./tutor')
require('./two-arrow')
require('./upload')
require('./user')
require('./video')
require('./vk')
require('./wifi')
require('./win')
require('./windmill')
require('./worker')
require('./workers')
require('./youtube')
require('./сopy_link')
