/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/upload': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31979)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M71.6 65a.6.6 0 10-1.2 0v10.552l-3.976-3.976a.6.6 0 10-.848.849l5 5a.6.6 0 00.848 0l5-5a.6.6 0 00-.848-.849L71.6 75.552V65zm-9 12a.6.6 0 10-1.2 0v4a2.6 2.6 0 002.6 2.6h14a2.6 2.6 0 002.6-2.6v-4a.6.6 0 10-1.2 0v4a1.4 1.4 0 01-1.4 1.4H64a1.4 1.4 0 01-1.4-1.4v-4z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31979" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31979"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31979" result="effect2_dropShadow_9226_31979"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31979" result="shape"/></filter></defs>'
  }
})
