import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

class FormButtonAnimation {
    constructor (startOffset) {
        this.btn = document.querySelector('[data-form-btn]')
        this.endTrigger = document.querySelector('[data-end-trigger]')
        this.startOffset = startOffset || window.screen.height
        this.tl = null
        this.expand = this.expand.bind(this)
        this.collpase = this.collpase.bind(this)

        this.init()
    }

    init () {
        this.setAnimation()
        this.addEvents()
        window.addEventListener('resize', () => this.updateStartOffset())
    }

    setAnimation () {
        this.tl = gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: this.btn,
                start: this.startOffset,
                endTrigger: this.endTrigger,
                end: 'top center',
                onEnter: (st) => { st.refresh(); st.animation.play() },
                onLeaveBack: (st) => { st.animation.reverse() }
            }
        })

        this.tl.to('[data-form-btn-text]', {
            autoAlpha: 0, width: 0, margin: 0, duration: 0.5, ease: 'linear'
        })

        ScrollTrigger.create({
            trigger: this.btn,
            endTrigger: this.endTrigger,
            end: 'top center',
            onLeave: () => { gsap.to(this.btn, { autoAlpha: 0 }); this.tl.play() },
            onEnterBack: () => gsap.to(this.btn, { autoAlpha: 1 })
        })

        const mm = gsap.matchMedia()
        mm.add('(min-width: 1024px)', () => {
            this.tl.scrollTrigger.enable()

            return () => {
                this.tl.scrollTrigger.disable()
            }
        })
    }

    addEvents () {
        this.btn.addEventListener('mouseenter', this.expand)
        this.btn.addEventListener('mouseleave', this.collpase)
    }

    removeEvents () {
        this.btn.removeEventListener('mouseenter', this.expand)
        this.btn.removeEventListener('mouseleave', this.collpase)
    }

    expand () {
        if (this.isRunEvent()) this.tl.reverse()
    }

    collpase () {
        if (this.isRunEvent()) this.tl.play()
    }

    isRunEvent () {
        return this.tl.scrollTrigger.isActive && window.innerWidth > 1024
    }

    updateStartOffset () {
        this.startOffset = window.screen.height
        this.tl.scrollTrigger.refresh()
    }
}

export default FormButtonAnimation
