/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/map-map': {
    width: 91,
    height: 76,
    viewBox: '0 0 91 76',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M31.942 39.356l.13.02 14.56 2.34 15.609-2.34.128-.02.128.02 15.214 2.361.488.076.175.461 12.067 31.74.56 1.472-1.538-.342-23.404-5.2-19.21 5.191-.208.056-.21-.05-21.842-5.2-22.891 5.202L0 75.53l.742-1.575 14.952-31.74.193-.41.447-.067 15.476-2.361.132-.02zm-6.314 29.088L45.78 73.24V43.3l-13.21-2.123-6.94 27.268zM30.8 41.247l-6.901 27.11-20.88 4.745 14.018-29.756 13.763-2.1zm16.676 2.06v29.9l17.628-4.764-3.47-27.26-14.158 2.123zm15.875-2.08l3.455 27.144 21.489 4.775-11.332-29.807-13.612-2.113z" _fill="url(#paint0_linear_10351_2023)"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M47.005 50.89L61.91 35.983a21.08 21.08 0 10-29.81 0l14.905 14.905z" _fill="#0077C8"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M26.331 25.192A21.08 21.08 0 0032.1 35.985L47.005 50.89 61.91 35.985a21.08 21.08 0 10-35.579-10.793zm34.38 9.593L47.005 48.492 33.3 34.785a19.382 19.382 0 1127.412 0zm-6.187-13.59a7.52 7.52 0 11-15.038 0 7.52 7.52 0 0115.038 0zm1.504 0a9.023 9.023 0 11-18.046 0 9.023 9.023 0 0118.046 0z" _fill="#fff"/><defs><linearGradient id="paint0_linear_10351_2023" x1="45.5" y1="39.356" x2="45.5" y2="75.529" gradientUnits="userSpaceOnUse"><stop stop-color="#fff" stop-opacity=".1"/><stop offset="1" stop-color="#fff"/></linearGradient></defs>'
  }
})
