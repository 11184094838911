/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.285 4.184l-3.477 3.477-.707-.707 2.553-2.553A7.613 7.613 0 005.02 15.749a.5.5 0 11-.866.5 8.613 8.613 0 018.652-12.837L10.1.707 10.808 0l3.477 3.477.353.354-.353.353zm3.921 3.952a.5.5 0 11.866-.5 8.612 8.612 0 01-8.536 12.852.498.498 0 01-.14-.039l2.73 2.73-.707.708L8.94 20.41l-.353-.354.353-.354 3.478-3.477.707.708-2.561 2.56a.506.506 0 01.096.003 7.612 7.612 0 007.545-11.36zM10.1 19.958l-.098.098.14.14a.496.496 0 01-.042-.238z" _fill="#0077C8"/>'
  }
})
