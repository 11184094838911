<template>
    <div class="header-navigation-top">
        <nav class="header-navigation-top__nav">
            <ul class="header-navigation-top__list" v-if="data.length">
                <template v-for="(item, index) in data">
                    <li class="header-navigation-top__list-item"
                        :class="[
                            {'header-navigation-top__list-item--opened': `top-link-${index}` === currentNavId.value},
                            {'header-navigation-top__list-item--active': item.active}
                        ]"
                        :key="`top-nav-${index}`">
                        <a :href="getHref(item)"
                           class="header-navigation-top__link"
                           :target="isBlank(item)"
                           @click="navHandler($event, `top-link-${index}`, item)"
                        >
                            <span class="header-navigation-top__link-text">{{ item.name }}</span>
                            <span v-if="item.params.classes === 'external'" class="header-navigation-top__external">
                                <z-icon name="external"
                                        width="14"
                                        height="14"
                                        color="#fff"
                                />
                            </span>
                            <span class="header-navigation-top__arrow">
                                <z-icon v-if="item.items && item.items.length"
                                        name="round-arrow-folder/arrow"
                                        width="14"
                                        height="14"
                                        dir="down"
                                        color="#fff"
                                />
                            </span>
                        </a>
                    </li>
                </template>

                <li class="header-navigation-top__list-item" v-if="lang === 'ru'">
                    <a href="/other-sites/" class="group-sites-button">
                        <span class="group-sites-button__button-text">Сайты группы</span>
                        <span class="group-sites-button__button-arrow">
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 9L5 5L1 1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'header-navigation-top',
    inject: ['currentNav', 'currentNavId', 'navHandler', 'getHref', 'isBlank'],
    data () {
        return {
            data: this.$root?.app?.components?.navigation?.header || [],
            lang: this.$root?.app?.page?.lang
        }
    }
}
</script>

<style lang="scss">
.header-navigation-top {
    $parent: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin-right: 74px;

    @media (max-width: 1148px) {
        display: none;
    }

    &__nav {
        flex-grow: 1;
        display: flex;
    }

    &__list {
        display: flex;
        padding: 0;
        list-style: none;
        font-size: 14px;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
    }

    &__list-item {
        margin-right: 80px;
        white-space: nowrap;

        @include breakpoint(tablet) {
            margin-right: 30px;
        }

        &:last-child {
            margin-right: 0;
        }

        &--opened {
            #{$parent}__link {
                color: $token-colors-blue-30;

                #{$parent}__link-text {
                    position: relative;

                    &:before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        bottom: -4px;
                        border-radius: 6px 6px 0 0;
                        position: absolute;
                        left: 0;
                        background-color: currentColor;
                    }
                }

                #{$parent}__arrow {
                    transform: rotate(180deg);
                    path {
                        fill: $token-colors-blue-30;
                    }
                }
            }
        }

        &--active {
            #{$parent}__link {
                color: #82CCFF;

                #{$parent}__arrow {
                    path {
                        color: #82CCFF
                    }
                }
            }
        }
    }

    &__link {
        color: #F5F5F5;
        text-decoration: none;

        &:hover {
            color: #0098FF;

            #{$parent}__arrow {
                path {
                    fill: #0098FF;
                }
            }

            #{$parent}__external {
                .z-icon {
                    path {
                        fill: #0098FF;
                    }
                }
            }
        }

        #{$parent}__arrow {
            display: inline-block;
            transition: transform 300ms ease;
        }
    }

    .white-theme {
        #{$parent}__link {
            color: #000;
        }
    }

    &__external {
        &:hover,
        &:active {
            .z-icon {
                path {
                    fill: #0098FF;
                }
            }
        }
    }
}
</style>
