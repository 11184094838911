<template>
    <div class="products">
        <div class="products__hero container">
            <z-breadcrumbs class="hide-me--v-tablet" />
        </div>
        <div class="products__wrapper">
            <div class="products__img"></div>
                <div class="products__layout">
                    <aside class="products__aside">
                        <products-menu :data="menu" :hide-child-menu="item.isMetal.value === 'Y'">
                            <template v-slot:child-menu>
                                <metal-card :data="metal" />
                                <z-filelist
                                    class="production-chain u-top-margin--s u-bottom-margin--s"
                                    v-if="productionChain"
                                >
                                    <z-filelist-item
                                        :name="text.productionChain"
                                        :href="productionChain.link"
                                        icon-name="file/pdf"
                                        :file-size="productionChain.size"
                                    ></z-filelist-item>
                                </z-filelist>
                            </template>
                            <template v-slot:child-menu-footer>
                                <z-link
                                    class="text-size-l"
                                    :href="metal.commodityResearch.description"
                                    theme="white"
                                    target="_blank"
                                    v-if="metal.commodityResearch && metal.commodityResearch.value && metal.commodityResearch.description"
                                >
                                    <template v-slot:right>
                                        <z-icon name="round-arrow" dir="right" width="32" height="32"></z-icon>
                                    </template>
                                    <span v-html="metal.commodityResearch.value"></span>
                                </z-link>
                            </template>
                        </products-menu>
                    </aside>
                    <div class="products__content">
                        <div class="products__header">
                            <div class="u-flex-grow--1">
                                <z-caption tag="h1" class="products__title" size="4xl" weight="600" decor-right>
                                    <span v-html="item.name"></span>
                                </z-caption>
                                <z-caption tag="p" class="black-60-text u-collapse--bottom u-top-margin--3xs products__subtitle" size="l" weight="600">
                                    <span v-html="item.manufacturer.value"></span>
                                </z-caption>
                                <div class="products-registration u-top-margin--3xs">
                                    <div class="products-registration__list">
                                        <div class="products-registration__item" v-if="item.lmeRegistration.value === 'Y'">
                                            <z-icon width="30" height="30" name="product-lme" />
                                            <p class="products-registration__text" v-html="text.lmeRegistration"></p>
                                        </div>
                                        <div class="products-registration__item" v-if="item.shfeRegistration.value === 'Y'">
                                            <!-- <z-icon width="30" height="30" name="product-shfe" /> -->
                                            <img src="/images/shfeRegistration.png" alt="" width="30" height="30">
                                            <p class="products-registration__text" v-html="text.shfeRegistration"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pdf-button />
                        </div>
                        <div class="row">
                            <template v-if="item.isPowder.value === 'Y'">
                                <div class="col-default-12">
                                    <div class="products__physical" v-if="item.physicalConditions.value">
                                        <z-caption size="2xl" tag="h3" class="u-bottom-margin--xs-important">
                                            <span v-html="text.physical"></span>
                                        </z-caption>
                                        <vue-raw :raw="item.physicalConditions.value"></vue-raw>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="row print-result">
                            <div class="col-default-6 col-tablet-12">
                                <results :item="item" :order="1" />
                            </div>
                            <div class="col-default-6 col-tablet-12">
                                <results :item="item" :order="2" />
                            </div>
                        </div>

                        <div class="products__files" v-if="files.length">
                            <z-caption class="products__title" size="2xl" weight="600" decor-right>
                                <span v-html="text.files"></span>
                            </z-caption>
                            <div class="row u-top-margin--no-important">
                                <template v-for="(file, index) in files">
                                    <div class="col-default-6 col-mobile-12" :key="`product-file-${index}`">
                                        <z-filelist size="xl">
                                            <z-filelist-item
                                                :name="file.name"
                                                :href="file.link"
                                                icon-name="file-big/pdf"
                                                :file-size="file.size"
                                            ></z-filelist-item>
                                        </z-filelist>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="products__contacts" v-if="!!$slots[slotName]">
                            <div class="products__contacts-title">
                                <z-caption size="2xl" tag="h3" class="u-bottom-margin--s-important">
                                    <span v-html="text.contacts"></span>
                                </z-caption>
                                <div class="pdf-current-link col-default-6">
                                    <z-button kind="text" size="m">
                                        <template v-slot:left>
                                            <z-icon dir="right" name="round-arrow" width="32" height="32" />
                                        </template>
                                        <span>
                                            <span v-html="text.currentLink"></span> {{ currentLink }}
                                        </span>
                                    </z-button>
                                </div>
                            </div>

                            <slot :name="slotName"></slot>
                        </div>
                    </div>
                </div>
                <page-changer
                    v-if="pageNav"
                    :data="pageNav"
                    class="u-top-margin--l"
                />
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import ProductsMenu from './components/Menu.vue'
import MetalCard from './components/MetalCard.vue'
import Results from './components/Results.vue'
import PdfButton from './components/PdfButton.vue'

export default {
    name: 'products',
    components: {
        ProductsMenu,
        MetalCard,
        Results,
        PdfButton
    },
    props: {
        productionChain: Object
    },
    computed: {
        slotName () {
            if (this.$slots[this.metal.code]) return this.metal.code
            return this.$root.lang
        },
        pageNav () {
            if (!(this.data.nav && (this.data.nav.next || this.data.nav.prev))) return
            let nav = Object.assign({}, this.data.nav)
            if (nav.next) nav.next.name = ''
            if (nav.prev) nav.prev.name = ''
            return nav
        },
        currentLink () {
            return this.$root.app.page.lang === 'ru' ? 'https://www.nornickel.ru/' : 'https://www.nornickel.com/'
        }
    },
    data () {
        return {
            data: this.$root.app.components?.products,
            menu: this.$root.app.components.products?.menu,
            item: this.$root.app.components.products?.item,
            files: this.$root.app.components?.products?.item?.files || [],
            metal: this.$root.app.components.products?.item.metal,
            text: {
                physical: localize({
                    ru: 'Физические характеристики',
                    en: 'Physical characteristics&nbsp;(%)'
                }),
                lmeRegistration: localize({
                    ru: 'Марка зарегистрирована на Лондонской бирже металлов',
                    en: 'LME approved brand'
                }),
                shfeRegistration: localize({
                    ru: 'Марка зарегистрирована на Шанхайской фьючерсной бирже',
                    en: 'SHFE approved brand'
                }),
                productionChain: localize({
                    ru: 'Производственная цепочка',
                    en: 'Production chain'
                }),
                chemicalCompositionNote: localize({
                    ru: 'Chemical composition corresponds ASTM B39-79 (2013) standard specification for Nickel',
                    en: 'Chemical composition corresponds ASTM B39-79 (2013) standard specification for Nickel'
                }),
                contacts: localize({
                    ru: 'Контакты сбытовых офисов',
                    en: 'Sales Network'
                }),
                currentLink: localize({
                    ru: 'Ссылка на страницу:',
                    en: 'Link to this page:'
                }),
                files: localize({
                    ru: 'Документы',
                    en: 'Documents'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.products {
    @media print {
        .print-result {
            display: block;

            &:after {
                content: '';
                display: table;
                clear: both;
            }

            > [class*=col-] {
                width: 50%;
                float: right;

                &:first-child {
                    float: left;
                }
            }
        }
    }
    &__wrapper {
        position: relative;
        @include padding-level(top, 4XL);
    }

    &__hero {
        background: $token-colors-white;
        @include padding-level(top, L);
        @include padding-level(bottom, M);

        @include breakpoint (mobile) {
            display: none;
        }
    }

    &__img {
        background-image: url(/images/top-img/product-detail.jpg);
        background-size: cover;
        background-position: 50% -50%;
        background-repeat: no-repeat;
        padding-top: 33%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;

        @include breakpoint (v-tablet) {
            padding-top: 66%;
        }
    }

    &__aside {
        border-radius: 30px 0px 0px 0px;
        overflow: hidden;
        width: 30%;
        @include margin-level(top, L);
        flex-shrink: 0;

        @include breakpoint (tablet) {
            width: 40%;
        }

        @include breakpoint (v-tablet) {
            width: 100%;
            padding-right: $token-spacers-2-xl;
            padding-left: $token-spacers-2-xl;
            margin-top: 0;
            border-radius: 15px 0px 0px 0px;
        }

        @include breakpoint (mobile) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__content {
        background: $token-colors-white;
        @include padding-level(bottom, 2XL);
        @include padding-level(top, XL);
        box-shadow: 0px 8px 8px rgba(0, 32, 51, 0.04), 0px 12px 28px rgba(0, 32, 51, 0.1);
        border-radius: 0px 40px;
        overflow: hidden;
        flex-grow: 1;

        padding-left: $token-grids-desktop-offset;
        padding-right: $token-spacers-xl;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
            padding-right: $token-grids-laptop-offset;
        }

        @include breakpoint(v-tablet) {
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
            border-radius: 0px 15px;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }
    }

    &__results {
        display: flex;
        flex-direction: column;
        margin-top: -$token-spacers-s;

        p {
            color: $token-colors-gray-60;
            @include margin-level(bottom, 2XS);
        }
    }

    &__contacts-title {
        display: flex;

        @media print {
            .z-caption {
                width: 50%;
            }
        }
    }

    &__block {
        margin-top: $token-spacers-s;

        &--order-1 {
            order: 1;
        }

        &--order-2 {
            order: 2;
        }

        &--order-3 {
            order: 3;
        }

        &--order-4 {
            order: 4;
        }
    }

    &__layout {
        display: flex;
        max-width: 1920px;
        margin: 0 auto;
        padding-right: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-right: $token-grids-laptop-offset;
        }

        @include breakpoint(v-tablet) {
            padding: 0;
            flex-wrap: wrap;
        }
    }

    &__physical {
        background: $token-colors-gray-10;
        @include padding-level(top, XS);
        @include padding-level(bottom, S);
        @include padding-level(left, 2XL);
        @include padding-level(right, XL);
        @include margin-level(left, 2XL, true);
        @include margin-level(right, XL, true);
    }

    &__header {
        display: flex;
        align-items: flex-start;
        padding-bottom: $token-spacers-s;

        @include breakpoint (tablet) {
            flex-direction: column;
        }
    }

    &__title {
        margin: 0 !important;
        align-items: flex-start !important;

        &:after {
            top: 0.6em;
            position: relative;
        }

        @include breakpoint (v-tablet) {
            margin-bottom: $token-spacers-s !important;
        }
    }

    &__subtitle {
        @include breakpoint (v-tablet) {
            margin-bottom: $token-spacers-xs !important;
        }
    }

    &__chemical {
        background: $token-colors-gray-10;
        padding: $token-spacers-xs 20px 0;

        .z-table {
            margin-left: -20px;
            margin-right: -20px;
            width: auto;
            border-bottom: 1px solid $token-colors-gray-20;
        }
    }

    &__files {
        @include margin-level(bottom, M);
    }

    &__contacts {
        background: $token-colors-gray-10;
        padding-top: $token-spacers-s;
        padding-bottom: $token-spacers-s;
        @include padding-level(left, 2XL);
        @include padding-level(right, XL);
        @include margin-level(left, 2XL, true);
        @include margin-level(right, XL, true);
        @include margin-level(bottom, 2XL, true);

        .contact-card__title {
            font-size: 16px !important;
            font-weight: 400;
            line-height: 1;
            min-width: 85px;
        }

        .contact-card__link {
            @include typo-level(M);
            font-weight: 400;
            line-height: 1.2;
        }

        .contact-card__link-icon {
            display: none;
        }

        .contact-card__title,
        .contact-card__link {
            margin-top: 8px;
        }

        .contact-card__row {
            margin-top: -8px;

            & + .contact-card__row {
                margin-top: 0;
            }
        }
    }

    .z-list {
        .z-list-item:not(.z-filelist-item) {
            padding-left: 48px;

            &:before {
                left: 19px;
            }
        }
    }

    .z-table {
        background: $token-colors-white;

        table {
            border-bottom: none;
        }

        .z-table-row--head {
            font-size: 14px;
            color: $token-colors-gray-40;
        }

        .z-table-row--body {
            font-size: 16px;
            font-weight: 500;
        }

        .z-table-col {
            padding: 8px;
            min-width: auto;

            &:first-child {
                padding-left: 24px;
            }

            &:last-child {
                padding-right: 24px;
            }
        }
    }
}

.products-registration {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: $token-spacers-2-xs;
        margin-left: -$token-spacers-2-xs;
        margin-right: -$token-spacers-2-xs;
        margin-top: -$token-spacers-2-xs;
    }

    &__item {
        width: 50%;
        padding: 0 $token-spacers-2-xs;
        margin-top: $token-spacers-2-xs;
        display: flex;
        align-items: center;

        @include breakpoint (v-tablet) {
            width: 100%;
        }

        .z-icon, img {
            margin-right: $token-spacers-3-xs;
        }
    }

    &__text {
        @include typo-level(XS);
        line-height: 1.2;
        font-weight: 400;
        text-transform: uppercase;
        color: $token-colors-gray-30;
    }
}

.production-chain {
    .z-filelist-item__name {
        color: $token-colors-white !important;
        font-weight: 600;
        line-height: 1.2 !important;

        &:hover {
            color: $token-colors-blue-30 !important;
        }
    }

    .z-icon path {
        fill: $token-colors-white;
    }

    .z-filelist-item__info {
        color: $token-colors-blue-30;
    }

    .z-filelist-item__details,
    .z-filelist-item__icon {
        margin-top: 0;
    }
}

.carbon-footprint {
    background: $token-colors-gray-10;
    font-weight: 600;
    border-radius: 0px 30px;
    padding: 24px 32px;
    font-size: 20px;

    sub,
    sup {
        font-weight: 600;
    }

    @include breakpoint (v-tablet) {
        border-radius: 0px 15px;
    }
}

.pdf-current-link {
    display: none;

    .z-button {
        span {
            text-align: left;
        }
    }

    @media print {
        display: block;
    }
}
</style>
