/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_science_and_universities': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M25.886 19c2.311-3.245 3.49-6.598 1.851-8.237-1.639-1.639-4.992-.46-8.237 1.85-3.245-2.31-6.598-3.489-8.237-1.85-1.639 1.639-.46 4.992 1.85 8.237-2.31 3.244-3.489 6.598-1.85 8.237.528.528 1.233.763 2.054.763 1.733 0 3.983-1.047 6.188-2.614C21.7 26.953 23.95 28 25.688 28c.82 0 1.527-.236 2.054-.763 1.634-1.639.455-4.992-1.856-8.237zm.79-7.177c.717.716.233 3.038-1.736 5.933a28.158 28.158 0 00-1.986-2.21 28.179 28.179 0 00-2.21-1.984c2.895-1.968 5.216-2.456 5.933-1.739zM24.028 19a27.1 27.1 0 01-2.134 2.393 27.1 27.1 0 01-2.393 2.134 27.1 27.1 0 01-2.393-2.134A27.1 27.1 0 0114.973 19a27.992 27.992 0 014.527-4.527c.84.663 1.639 1.376 2.393 2.133.758.755 1.47 1.555 2.134 2.394zm-11.704-7.177c.207-.207.547-.314.996-.314 1.108 0 2.877.647 4.936 2.053a28.474 28.474 0 00-2.209 1.984 28.16 28.16 0 00-1.983 2.21c-1.97-2.895-2.456-5.216-1.74-5.933zm0 14.353c-.716-.716-.23-3.037 1.74-5.932a28.14 28.14 0 004.193 4.193c-2.895 1.97-5.216 2.457-5.933 1.74zm14.354 0c-.717.718-3.038.233-5.933-1.736a28.546 28.546 0 002.21-1.986c.7-.701 1.362-1.44 1.984-2.21 1.968 2.895 2.455 5.216 1.739 5.932zM20.625 19a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z" _fill="#fff"/>'
  }
})
