/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'esg-big': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<g clip-path="url(#clip0_10567_2202)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.894 33.4l-1.9-.7c4.8-12.4 16.8-20.7 30-20.7h2.4l-6.7 6.7-1.4-1.4 3.2-3.2c-11.4 1-21.5 8.5-25.6 19.3zm60.1 13l-6.7-6.7 1.4-1.4 3.2 3.2c-1-11.4-8.5-21.5-19.3-25.6l.7-1.9c12.4 4.8 20.7 16.8 20.7 30v2.4zM41.594 76h2.4c13.2 0 25.2-8.3 29.9-20.6l-1.9-.7c-4.1 10.7-14.1 18.2-25.5 19.2l3.2-3.2-1.4-1.4-6.7 6.7zm-9-2.1c-12.3-4.7-20.6-16.7-20.6-29.9v-2.4l6.7 6.7-1.4 1.4-3.2-3.2c1 11.4 8.5 21.4 19.2 25.5l-.7 1.9zm10.7-6.1l.7.6.7-.7.052-.052c.908-.886 11.248-10.987 11.248-23.648 0-12.035-9.34-21.755-11.046-23.53a6.899 6.899 0 01-.254-.27l-.7-.6-.7.7-.052.05c-.908.887-11.248 10.988-11.248 23.65 0 12.034 9.34 21.754 11.046 23.529.137.142.224.234.254.27zM39.07 28.677a43.53 43.53 0 013.924-5.19v9.114l-3.924-3.924zm-1.059 1.741l4.983 4.983v7.2l-7.3-7.3-.012.012a34.218 34.218 0 012.33-4.895zm4.983 22.183v-7.2l-8.01-8.01c-.577 1.973-.946 4.059-.986 6.213l8.996 8.997zm0 2.8v9.11c-2.742-3.105-7.898-9.919-8.847-17.958l8.847 8.848zm2 0l8.848-8.848c-.95 8.04-6.106 14.853-8.848 17.958v-9.11zm8.996-11.797l-8.996 8.997v-7.2l8.01-8.01c.578 1.973.946 4.059.986 6.214zm-1.696-8.303l.012.012a34.225 34.225 0 00-2.33-4.895l-4.982 4.983v7.2l7.3-7.3zm-3.376-6.624a43.502 43.502 0 00-3.924-5.19v9.114l3.924-3.924z" _fill="#0077C8"/></g><defs><clipPath id="clip0_10567_2202"><path pid="1" _fill="#fff" transform="translate(11.994 12)" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
