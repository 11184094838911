/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/photo': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31976)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M83.44 65.95H64.5a1.8 1.8 0 00-1.8 1.8v12.305l3.382-2.623a1.546 1.546 0 011.748-.145l4.14 2.403 5.966-5.2.001-.001c.219-.19.49-.308.774-.348.283-.04.575-.001.838.113l.006.003 5.684 2.554V67.75a1.8 1.8 0 00-1.8-1.8zm1.8 12.177l-6.173-2.774a.36.36 0 00-.19-.023.299.299 0 00-.153.065l-6.29 5.482-.324.282-.372-.215-4.51-2.62a.346.346 0 00-.374.027l-.012.01-.013.01-4.129 3.202v3.454a1.8 1.8 0 001.8 1.8h18.94a1.8 1.8 0 001.8-1.8v-6.9zM64.5 64.75a3 3 0 00-3 3v17.277a3 3 0 003 3h18.94a3 3 0 003-3V67.75a3 3 0 00-3-3H64.5zm3.202 6.201a.982.982 0 10-1.389-1.389.982.982 0 001.389 1.39zm.849.849a2.182 2.182 0 10-3.086-3.086A2.182 2.182 0 0068.55 71.8z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31976" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31976"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31976" result="effect2_dropShadow_9226_31976"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31976" result="shape"/></filter></defs>'
  }
})
