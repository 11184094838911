<template>
    <div class="innovation">
        <section-hero />
        <div class="container">
            <section-development />
            <section-branches id="areas-of-activity" />
            <section-news />
        </div>
        <section-contact
            data-end-trigger
            id="section-contact" />
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import SectionHero from './sections/SectionHero.vue'
import SectionDevelopment from './sections/SectionDevelopment.vue'
import SectionBranches from './sections/SectionBranches.vue'
import SectionNews from './sections/SectionNews.vue'
import SectionContact from './sections/SectionContact.vue'

export default {
    name: 'innovation-page',
    components: {
        SectionHero,
        SectionDevelopment,
        SectionBranches,
        SectionNews,
        SectionContact
    },
    data () {
        return {
            text: {
                contactButton: localize({
                    ru: 'Напишите нам',
                    en: 'Contact Us'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.innovation {
    position: relative;

    section {

        &:not(.section-contact) {
            margin-bottom: 100px;

            @include breakpoint(laptop) {
                @include margin-level(bottom, 2XL);
            }

            @include breakpoint(v-tablet) {
                @include margin-level(bottom, L);
            }
        }
    }
}
</style>
