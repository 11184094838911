<template>
    <form @submit.prevent="submitForm" class="innovations-form">
        <div class="row">
            <div class="col-default-4 col-tablet-12">
                <div class="innovations-form__left">
                    <z-caption
                        size="2xl"
                        class="innovations-form__title"
                        v-html="text.formTitle"
                    ></z-caption>

                    <z-input
                        v-model="formData.name"
                        type="text"
                        name="name"
                        ref="name"
                        :placeholder="text.name"
                        required
                        class="innovations-form__input"
                    ></z-input>

                    <z-input
                        v-model="formData.email"
                        type="email"
                        ref="email"
                        name="email"
                        :placeholder="text.email"
                        required
                        class="innovations-form__input"
                    ></z-input>

                    <z-select
                        v-model="formData.direction"
                        :data="directionOptions"
                        type="text"
                        name="direction"
                        ref="direction"
                        :placeholder="text.direction"
                        required
                        class="innovations-form__input"
                    ></z-select>
                </div>
            </div>

            <div class="col-default-8 col-tablet-12">
                <div class="innovations-form__right">
                    <div class="row">
                        <div class="col-default-6 col-tablet-12">

                        </div>
                    </div>

                    <z-textarea
                        v-model="formData.detailText"
                        name="detailText"
                        ref="detailText"
                        rows="5"
                        required
                        class="innovations-form__input-textarea u-collapse--top"
                        :placeholder="text.detailText"
                    ></z-textarea>

                    <z-input-file
                        @change="changeFiles"
                        :accept="[
                            'ppt', 'xsl', 'xlsx', 'pdf', 'pptx', 'doc', 'docx', 'zip', 'rar', 'tar'
                            ]"
                        :label="text.files"
                        :description="text.filesDescription"
                        name="files"
                        ref="files"
                        multiple
                        class="innovations-form__input-file"
                    ></z-input-file>

                    <div class="innovations-form__captcha-container">
                        <p class="innovations-form__captcha-text">{{ text.notificationCaptchaEmpty }}</p>

                        <div class="form-captcha">
                            <div class="form-captcha__wrapper">
                                <div class="form-captcha__captcha">
                                    <img
                                        class="form-captcha__img"
                                        v-if="isUpdatedCaptcha"
                                        :src="'/api/captcha/?' + captchaSalt"
                                        width="100"
                                        height="50"
                                        alt=""
                                    />
                                    <div v-else class="form-captcha__img"></div>

                                    <z-button
                                        class="form-captcha__reset-btn"
                                        type="button"
                                        rounded
                                        kind="secondary"
                                        @click="updateCaptcha"
                                    >
                                        <z-icon
                                            name="refresh"
                                            width="24"
                                            height="24"
                                        ></z-icon>
                                    </z-button>
                                </div>

                                <z-input
                                    v-model="captcha"
                                    type="text"
                                    name="captcha"
                                    ref="captcha"
                                    required
                                    class="innovations-form__captcha-input form-captcha__input"
                                ></z-input>
                            </div>
                        </div>
                    </div>

                    <z-checkbox
                        name="policy"
                        ref="policy"
                        v-model="isChecked"
                        class="innovations-form__checkbox"
                        required
                        :data="[{ value: '1' }]"
                    >
                        <template #text>
                            <z-caption
                                tag="p"
                                weight="400"
                                size="xs">
                                {{ text.policy }}
                            </z-caption>
                        </template>
                    </z-checkbox>

                    <z-button kind="bordered-dark">
                        <template v-slot:append>
                            <span></span>
                        </template>
                        {{ text.button }}
                    </z-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendOfferProject } from '@/api/offer-project.js'
import { sendCaptcha } from '@/api/captcha.js'
import { text, directionOptions } from './constants'

export default {
    name: 'innovations-form',
    data () {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                name: '',
                email: '',
                direction: '',
                detailText: '',
                files: []
            },
            text,
            directionOptions,
            captchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCaptcha: true
        }
    },
    methods: {
        changeFiles (payload) {
            this.formData.files = payload
        },

        validateInputFormat () {
            if (this.formData.email || this.formData.files) {
                const requiredFields = ['email', 'files']
                let isValid = true

                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }

            return true
        },

        validateRequiredInputs () {
            const requiredFields = [
                'name', 'email', 'direction', 'detailText', 'policy', 'captcha'
            ]
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        changeTextarea (data) {
            this.formData[data.name] = data.value
        },

        updateCaptcha () {
            this.isUpdatedCaptcha = false
            this.captcha = ''
            this.captchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCaptcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                const isValidInputFormat = this.validateInputFormat()

                if (!isValidInputFormat) {
                    showNotyfications(this.text.notificationValidateError, { type: 'error' })
                    return
                }

                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        if (key === 'files') {
                            this.formData[key].forEach((el) => data.append(`${key}[]`, el))
                        } else data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendOfferProject(data)

                        if (formResponse.status === 'success') {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })

                            this.formData.name = ''
                            this.formData.email = ''
                            this.formData.direction = ''
                            this.formData.detailText = ''
                            this.formData.files = ''
                            this.captcha = ''
                            this.isChecked = []

                            this.$refs.direction.clear()
                            this.$refs.files.clear()
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                        }
                    }
                    this.updateCaptcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.innovations-form {

    &__title {
        display: inline-block;
        color: $token-colors-gray-58 !important;
        margin-bottom: 42px !important;

        @include breakpoint(tablet) {
            max-width: none;
        }

        @include breakpoint(mobile) {
            margin-bottom: 32px !important;
        }
    }

    &__left {
        @include breakpoint(tablet) {
            margin-bottom: -32px;
        }
    }

    &__right {
        padding-left: $token-spacers-xl;

        @include breakpoint(laptop) {
            padding-left: $token-spacers-m;
        }

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

    &__input {
        margin-bottom: $token-spacers-xs;
        position: relative;

        .z-input__input,
        .z-select {
            background-color: $token-colors-white;
            box-shadow: inset 0 0 0 1px #A1A7B5;
        }

        .z-input__error,
        .z-select__error {
            position: absolute;
        }
    }

    &__input-file {
        margin-bottom: $token-spacers-xs;

        .z-input-file__icon {
            width: 18px !important;
            height: 18px !important;
            transform: rotate(45deg);

            path {
                stroke: none;
            }
        }

        .z-input-file__description {
            color: #626C84;
        }

        .z-link__text {
            word-break: break-all;
        }
    }

    &__input-textarea {
        height: auto !important;
        background-color: $token-colors-white;
        margin-bottom: $token-spacers-xs;

        &:not(.is-error) {
            box-shadow: inset 0 0 0 1px #A1A7B5 !important;
        }

        textarea {
            max-height: 500px;
            resize: vertical !important;
        }
    }

    &__textarea-title {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

    &__captcha-container {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: $token-spacers-xs;
    }

    &__captcha-text {
        margin-bottom: 20px;
    }

    &__captcha-img {
        margin: 0 16px 0 0;
        width: 100px;
        height: 50px;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-button {
        margin: 0 8px 0 0;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-input {
        position: relative;

        .z-input__input {
            background-color: $token-colors-white;
            box-shadow: inset 0 0 0 1px #A1A7B5;
        }

        .z-input__error {
            left: 0;
        }
    }

    &__checkbox {
        margin-bottom: $token-spacers-xs;
        color: $token-colors-gray-50 !important;
        position: relative;

        .z-checkbox-group__list {
            margin-top: 0;
        }

        .z-checkbox__text {
            font-size: 13px !important;
        }

        .z-checkbox-group__error {
            position: absolute;
        }
    }
}
</style>
