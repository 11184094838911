<template>
    <div class="project-detail" v-if="subProject">
        <div class="project-detail__top">
            <div class="project-detail__prerequisites project-detail-prerequisites">
                <span class="project-detail__title" v-html="text.prerequisites"></span>
                <div class="project-detail-prerequisites__body">
                    <p
                        v-if="subProject.prerequisites.title"
                        class="project-detail-prerequisites__title"
                        v-html="subProject.prerequisites.title"
                    ></p>
                    <div
                        v-if="subProject.prerequisites.description"
                        class="project-detail-prerequisites__description"
                        v-html="subProject.prerequisites.description"
                    ></div>
                </div>
            </div>

            <div
                v-if="subProject.keyEffects"
                ref="effects"
                class="project-detail__effects project-detail-effects"
            >
                <span class="project-detail__title" v-html="text.keyEffects"></span>
                <div class="project-detail-effects__body">
                    <div class="project-detail-effects__inner" v-html="subProject.keyEffects"></div>
                </div>
            </div>

            <div
                v-if="subProject.roleOfPalladium"
                ref="effects"
                class="project-detail__effects project-detail-effects"
            >
                <span class="project-detail__title" v-html="text.roleOfPalladium"></span>
                <div class="project-detail-effects__body">
                    <div class="project-detail-effects__inner" v-html="subProject.roleOfPalladium"></div>
                </div>
            </div>

            <div v-if="!subProject.stagesOfProcess" class="project-detail__top-bg">
                <img src="/images/innovation/digital-manufacturing-chain/project-img-bg.png" />
            </div>
        </div>

        <div v-if="subProject.principleOfOperation" class="project-detail__principle project-detail-principle">
            <span class="project-detail__title" v-html="text.principleOfOperation"></span>
            <div class="project-detail-principle__body">
                <principle-card
                    v-for="(numSrc, index) in stepsNum"
                    v-if="subProject.principleOfOperation[index + 1]"
                    :key="index"
                    :numberSrc="numSrc"
                    :imageSrc="subProject.principleOfOperation[index + 1].image"
                    :description="subProject.principleOfOperation[index + 1].description"
                    @open-modal="openModal"
                />
            </div>
        </div>

        <div v-if="subProject.stagesOfProcess" class="project-detail__stages main-stages-of-work">
            <span class="project-detail__title" v-html="text.stagesOfProcess"></span>
            <div class="main-stages-of-work__description" v-html="subProject.stagesOfProcess">
            </div>
        </div>

        <div v-if="subProject.advantages" class="project-detail__advantages project-detail-advantages">
            <div class="project-detail-advantages__body">
                <span class="project-detail__title" v-html="text.advantages"></span>
                <div
                    class="project-detail-advantages__description"
                    v-html="subProject.advantages"
                ></div>
            </div>
            <div class="project-detail-advantages__bg">
                <video
                    autoplay
                    loop
                    muted
                    playsinline
                    preload="metadata"
                    poster="/upload/iblock/advantages-bg.mp4"
                >
                    <source src="/upload/iblock/advantages-bg.mp4#t=0.1" type="video/mp4">
                </video>
            </div>
        </div>

        <z-modal
            v-if="modalData"
            id="projectDetailModal"
            :data="modalData"
            :body-lock="false"
            @close="closeModal"
            class="project-detail__modal"
        ></z-modal>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
import PrincipleCard from './PrincipleCard.vue'

export default {
    name: 'project-detail',
    components: { PrincipleCard },
    mixins: [mixinDevice],
    props: {
        subProject: Object
    },
    data () {
        return {
            modalData: null,
            text: {
                prerequisites: localize({
                    ru: 'Предпосылки',
                    en: 'Background'
                }),
                keyEffects: localize({
                    ru: 'Ключевые эффекты',
                    en: 'Key Effects'
                }),
                roleOfPalladium: localize({
                    ru: 'Роль палладия',
                    en: 'Роль палладия'
                }),
                principleOfOperation: localize({
                    ru: 'Принцип работы',
                    en: 'How It&nbsp;Works'
                }),
                stagesOfProcess: localize({
                    ru: 'Основные этапы работы',
                    en: 'Stages of&nbsp;process'
                }),
                advantages: localize({
                    ru: 'Преимущества',
                    en: 'Advantages'
                })
            },
            stepsNum: [
                '/images/innovation/number-1.png',
                '/images/innovation/number-2.png',
                '/images/innovation/number-3.png'
            ]
        }
    },
    methods: {
        openModal (data) {
            this.modalData = data

            this.$nextTick(() => {
                this.$root.$bus.$emit('open-modal', { id: 'projectDetailModal' })
            })
        },
        closeModal () {
            this.modalData = null
        }
    }
}
</script>

<style lang="scss">
.project-detail {
    &__top {
        display: flex;
        gap: 104px;
        position: relative;
        margin-bottom: $token-spacers-xl;

        @include breakpoint(tablet) {
            flex-direction: column;
            gap: $token-spacers-xl;
        }

        @include breakpoint(mobile) {
            gap: $token-spacers-m;
            margin-bottom: $token-spacers-m;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 1.8px;
        color: $token-colors-gray-40;
        text-transform: uppercase;
        margin-bottom: $token-spacers-s;
        position: relative;

        @include breakpoint(mobile) {
            justify-content: center;
            white-space: nowrap;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $token-colors-gray-40;

            @include breakpoint(mobile) {
                max-width: none !important;
            }
        }

        &:before {
            max-width: 64px;
            margin-right: $token-spacers-2-xs;
        }

        &:after {
            max-width: 44px;
            margin-left: $token-spacers-2-xs;
        }
    }

    &__top-bg {
        width: 60%;
        position: absolute;
        right: -85px;
        bottom: -130px;

        @include breakpoint(mobile) {
            display: none;
        }
    }

    .z-list {

        &:not(:last-child) {
            margin-bottom: $token-spacers-xs;
        }

        .z-list {
            margin-top: $token-spacers-xs;
        }
    }

    &__modal {
        .modal-image__img {
            img {
                width: 100%;
            }
        }

        .z-modal__container {
            border-radius: 0 50px 0 50px;
        }

        .z-modal__close {
            background: $token-colors-white;
            border-radius: 12px;

            path {
                fill: $token-colors-blue;
            }
        }
    }
}

.project-detail-prerequisites {
    max-width: 1046px;
    color: $token-colors-black;
    position: relative;
    z-index: 2;

    &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.12px;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            font-size: 20px;
            margin-bottom: $token-spacers-2-xs;
        }
    }

    &__description {
        font-size: 22px;
        line-height: 140%;
        letter-spacing: 0.22px;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }
}

.project-detail-effects {
    max-width: 380px;
    flex-shrink: 0;
    color: $token-colors-gray-60;
    position: relative;
    z-index: 2;

        @include breakpoint(tablet) {
            max-width: none;
        }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__top {
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.12px;
        margin: $token-spacers-2-xs 0;

        @include breakpoint(mobile) {
            font-size: 20px;
            margin: 0 0 $token-spacers-2-xs;
        }
    }

    &__indicator {
        font-size: 40px;
        font-weight: 600;
        line-height: 130%;
        color: $token-colors-navy-blue;
        margin-bottom: 10px;

        @include breakpoint(v-tablet) {
            font-size: 32px;
        }

        @include breakpoint(mobile) {
            font-size: 24px;
        }

        &--big {
            font-size: 80px;

            @include breakpoint(v-tablet) {
                font-size: 56px;
            }

            @include breakpoint(mobile) {
                font-size: 40px;
            }
        }
    }

    &__bottom {
        font-size: 22px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.22px;

        @include breakpoint(mobile) {
            font-size: 20px;
        }
    }
}

.project-detail-principle {
    &__body {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-xs;
        margin-bottom: $token-spacers-2-xl;
        position: relative;
        z-index: 1;
        transition: min-height 0.8s ease-in;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobile) {
            margin-bottom: $token-spacers-m;
        }
    }
}

.main-stages-of-work {
    margin-bottom: $token-spacers-xl;
}

.project-detail-advantages {
    border-radius: 0px 40px 0px 0px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #0570B8 0%, #013B75 99.5%);
    position: relative;

    .project-detail__title {
        color: $token-colors-gray-10;

        &:before,
        &:after {
            background-color: $token-colors-gray-10;
        }
    }

    &__body {
        max-width: 62%;
        color: $token-colors-gray-10;
        border-radius: 0px 40px 0px 0px;
        background: rgba(2, 46, 90, 0.80);
        backdrop-filter: blur(10px);
        padding: $token-spacers-m $token-spacers-m $token-spacers-2-xl $token-spacers-2-xl;
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
            max-width: 87%;
            padding: $token-spacers-s $token-spacers-s $token-spacers-xl $token-spacers-xl;
        }

        @include breakpoint(mobile) {
            max-width: 100%;
            padding: $token-spacers-m $token-spacers-xs $token-spacers-m $token-spacers-xs;

            & .z-list-item {
                padding-left: 52px;
            }
        }
    }

    &__description {
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.12px;

        @include breakpoint(mobile) {
            font-size: 20px;
        }
    }

    &__bg {
        width: 100%;
        height: 100%;
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
