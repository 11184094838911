<template>
    <div
        :class="[
            'slider',
            {
                'slider--with-thumbs': $slots.thumbs,
                'slider--with-further': this.further,
                'slider--half-screen': halfScreen
            }
        ]"
    >
        <div v-if="name" class="slider__name">
            <div class="container">
                <z-caption
                    class="white-text"
                    decor-right
                    decor-left
                    :href="link"
                >{{ name }}</z-caption>
            </div>
        </div>

        <div class="swiper-container slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="slider__footer">
            <div class="container u-relative">
                <div class="slider__controls">
                    <div class="slider__fraction">
                        <span class="modern-slider__fraction-active">
                            {{ getFormattedNumber(activeSlide) }}
                        </span>
                        /
                        <span class="modern-slider__fraction-count">
                            {{ getFormattedNumber(countSlides) }}
                        </span>
                    </div>

                    <button class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                        <z-icon
                            name="round-arrow-folder/arrow"
                            dir="left"
                            width="24px"
                            height="24px"
                            class="slider__nav-icon"
                        />
                    </button>
                    <div style="display: none;" class="slider__pagination" ref="pagination"></div>
                    <button class="slider__nav-btn slider__nav-btn--next" ref="next">
                        <z-icon
                            name="round-arrow-folder/arrow"
                            dir="right"
                            width="24px"
                            height="24px"
                            class="slider__nav-icon"
                        />
                    </button>
                </div>
            </div>
            <div class="slider__thumbs" v-if="!!$slots.thumbs">
                <div class="container u-all-padding--no u-relative">
                    <slider-thumbs-container :data="thumbs" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import SliderThumbsContainer from './components/SliderThumbsContainer.js'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'slider',
    mixins: [mixinDevice],
    components: {
        SliderThumbsContainer
    },
    data () {
        return {
            slider: undefined,
            activeSlide: 1,
            countSlides: 1
        }
    },
    props: {
        name: String,
        link: String,
        swiperOptions: {
            type: Object
        },
        further: {
            type: Boolean,
            default: false
        },
        effect: {
            type: String,
            default: 'slide'
        },
        halfScreen: {
            type: Boolean
        }
    },
    methods: {
        getFormattedNumber (number) {
            return number < 10 ? `0${number}` : number
        },
        init () {
            const _this = this

            let options = {
                allowTouchMove: false, // ОДУМАЙТЕСЬ!
                observer: true,
                loop: true,
                observeParents: true,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                effect: this.effect,
                slidesPerView: 1,
                on: {
                    init () {
                        _this.countSlides = this.slides.length - 2
                    },
                    slideChange () {
                        _this.activeSlide = _this.slider.realIndex + 1
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, Object.assign({}, this.swiperOptions, options))
        }
    },
    computed: {
        thumbs () {
            if (!this.$slots.thumbs) return []
            let arr = this.$slots.thumbs
            const part1 = arr.slice(0, this.activeSlide)
            const part2 = arr.slice(this.activeSlide)

            arr = part2.concat(part1)
            if (arr.length > this.navCount) arr = arr.slice(0, this.navCount)

            return arr
        },
        navCount () {
            if (this.device === 'laptop' || this.device === 'tablet') return 2
            if (this.device === 'v-tablet' || this.device === 'mobile') return 1
            return 3
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.slider {
    $parent: &;
    position: relative;
    width: 100%;

    @include breakpoint (mobile) {
        width: auto;
    }

    .z-image {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: linear-gradient(0deg, rgba(0, 76, 151, 0.20) 0%, rgba(0, 76, 151, 0.20) 100%), linear-gradient(269deg, rgba(0, 76, 151, 0.40) 6.1%, rgba(0, 0, 0, 0.00) 45.31%), rgba(0, 0, 0, 0) 50%;
        }
    }

    &--with-further {
        #{$parent}__container {
            height: 100%;
        }

        @include breakpoint (mobile) {
            margin: 0;
            width: 100%;
        }

        #{$parent}__thumbs {
            opacity: 1;
        }

        #{$parent}__thumbs {
            background: rgba(0, 120, 200, 0.9);
            opacity: 1;
        }
    }

    &__name {
        position: absolute;
        z-index: 99;
        left: 0;
        right: 0;
        top: $token-spacers-xl;

        @include breakpoint (v-tablet) {
            top: $token-spacers-s;
        }

        .z-caption {
            &::before,
            &::after {
                background: $token-colors-white;
            }
        }
    }

    &__fraction {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto Mono";
        font-size: 24px;
        font-weight: 300;
        color: #ffffff;
        margin-right: $token-spacers-3-xs;

        @include breakpoint(v-tablet) {
            font-weight: 600;
        }
    }

    &__fraction-active {
        font-size: 28px;
    }

    &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 2;
    }

    &__controls {
        display: flex;
        align-items: center;
        position: absolute;
        gap: $token-spacers-2-xs;
        bottom: calc(100% + 40px);
        right: $token-grids-desktop-offset;
        z-index: 3;

        @include breakpoint (v-tablet) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            justify-content: center;
        }
    }

    &__nav-icon {
        path {
            fill: $token-colors-white;
            transition: fill $transition;
        }
    }

    &__nav-btn {
        width: 72px;
        height: 72px;
        background-color: rgba(255, 255, 255, 0.4);
        border: none;
        border-radius: 50%;
        transition: opacity $transition, background-color $transition;

        @include breakpoint (mobile) {
            width: 64px;
            height: 64px;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.9);

            #{$parent}__nav-icon {
                path {
                    fill: #0077c8;
                }
            }
        }
    }

    &__pagination {
        display: flex;
        align-items: center;

        .swiper-pagination-bullet {
            background: $token-colors-blue-10;
            margin: 0 8px;
            width: 6px;
            height: 6px;
            vertical-align: middle;
            opacity: 1;

            &-active {
                background: white;
                width: 12px;
                height: 12px;
            }
        }
    }

    .swiper-slide {
        height: auto;

        &__content,
        &__wrapper {
            height: 100%;
        }

        .z-image {
            display: block;
            &__img {
                display: block;
                width: 100%;
            }
        }
    }

    &__thumbs {
        position: relative;
        background: $token-colors-blue;
        opacity: 0.9;
    }
}
</style>
