<template>
    <div class="sale-page-list" v-if="items.length">
        <div class="sale-page-list-actions">
            <z-button
                kind="text"
                class="sale-page-list-actions__item"
                @click="toggleSelectAllCheckboxes"
            >
                <template #left>
                    <z-icon name="check-circle" width="20" height="20"/>
                </template>
                {{ isAllSelected ? 'Очистить все позиции' : 'Выбрать все позиции' }}
            </z-button>
            <z-button
                kind="text"
                class="sale-page-list-actions__item"
                @click="toggleShowSelected"
                :disabled="!checkedItems.length"
            >
                <template #left>
                    <z-icon name="eye" width="20" height="20"/>
                </template>
                {{ !showSelectedOnly ? 'Посмотреть выбранные' : 'Посмотреть все' }}
            </z-button>

            <download-excel-btn
                class="sale-page-list-actions__item"
                urlSections="illiquid/sale"
                :filters="getDownloadExcelFilters()"
                :disabled="!checkedItems.length"
            />

        </div>
        <template v-for="(item, index) in items">
            <div class="sale-page-card" :key="`sale-${index}`">
                <div class="sale-page-card__header">
                    <span class="sale-page-card__tag" v-html="item.roks" />
                </div>
                <div class="sale-page-card__wrapper">
                    <label class="sale-page-card__checkbox">
                        <input
                            type="checkbox"
                            :value="item.id"
                            :checked="checkedItems.includes(item.id)"
                            @change="setCheckboxArray"
                        >
                        <span class="sale-page-card__checkbox-box">
                            <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill z-top" style="width: 22px; height: 22px;">
                                <path pid="0" fill-rule="evenodd" clip-rule="evenodd"
                                      d="M20.424 6.425a.6.6 0 10-.848-.849L9 16.152l-4.576-4.576a.6.6 0 00-.848.849l5 5a.6.6 0 00.848 0l11-11z"
                                      fill="#0077C8"
                                >
                                </path>
                            </svg>
                        </span>
                    </label>

                    <div class="sale-page-card__body">
                        <span class="sale-page-card__text" v-html="item.name" />
                        <sale-page-table>
                            <sale-page-table-item class="sale-page-card__type" title="Наименование группы материалов">
                                <span v-if="item.type" v-html="item.type" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__subtype" title="Наименование подгруппы материалов">
                                <span v-if="item.subType" v-html="item.subType" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__tech-props" title="Технические характеристики">
                                <span v-if="item.techProps" v-html="item.techProps" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__brand" title="Марка">
                                <span v-if="item.brand" v-html="item.brand" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__gost" title="ГОСТ">
                                <span v-if="item.gost" v-html="item.gost" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__code" title="Код ЕНС">
                                <span v-if="item.code" v-html="item.code" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__amount" title="Кол-во к&nbsp;реал-ии">
                                <span v-if="item.amount" v-html="item.amount" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__amount-name" title="ЕИ кол.">
                                <span v-if="item.amountName" v-html="item.amountName" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__price" title="Цена за&nbsp;единицу">
                                <span v-if="item.price" v-html="formattedNumber(item.price)" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                        </sale-page-table>

                        <sale-page-table-item v-if="item.comments" class="sale-page-card__comments" title="Примечание">
                            <span v-html="item.comments" />
                        </sale-page-table-item>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SalePageTable from './SalePageTable.vue'
import SalePageTableItem from './SalePageTableItem.vue'
import { clearEmptyParams } from '@/utils/clearEmptyParams'

export default {
    name: 'sale-page-list',
    components: {
        SalePageTable,
        SalePageTableItem
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        filterParams: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            checkedItems: [], // чекбокс не работает без v-model
            isAllSelected: false,
            showSelectedOnly: false,
            unCheckedItems: []
        }
    },
    created() {
        localStorage.removeItem('saleCheckboxArray')
    },
    methods: {
        setCheckboxArray(e) {
            this.isAllSelected = false
            let previousChecked = JSON.parse(localStorage.getItem('saleCheckboxArray')) || []

            if (e.target.checked) {
                this.checkedItems.push(e.target.value)
            } else {
                previousChecked = previousChecked.filter(id => id !== e.target.value)
                this.checkedItems = this.checkedItems.filter(id => id !== e.target.value)
            }
            localStorage.setItem('saleCheckboxArray', JSON.stringify(Array.from(new Set(this.checkedItems.concat(previousChecked)))))
        },

        toggleSelectAllCheckboxes () {
            this.isAllSelected = !this.isAllSelected

            if (!this.isAllSelected) {
                this.checkedItems = []
            } else {
                this.checkedItems = this.items.map(item => item.id)
            }

            localStorage.setItem('saleCheckboxArray', JSON.stringify(this.isAllSelected ? 'all' : []))

            this.$emit('all-selected', {
                id: JSON.parse(localStorage.getItem('saleCheckboxArray')),
            })
        },
        toggleShowSelected() {
            this.showSelectedOnly = !this.showSelectedOnly

            if (!this.showSelectedOnly) {
                this.checkedItems = []
            }

            this.$emit('show-selected', {
                id: this.checkedItems
            })

        },
        formattedNumber (value) {
            const formattedValue = this.$options.filters.numberFilter(value)
            const normalizedValue = formattedValue.replace(',', '.').replace(/\s+/g, '')
            const numberValue = parseFloat(normalizedValue)
            if (!isNaN(numberValue)) {
                return numberValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
            return formattedValue
        },
        getDownloadExcelFilters () {
            return { ...clearEmptyParams(this.filterParams), id: this.isAllSelected ? [] : [...this.checkedItems] }
        }
    },
}
</script>

<style lang="scss">
.sale-page-list {
    @include margin-level(bottom, L);
}

.sale-page-list-actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $token-spacers-s;
    margin-top: -$token-spacers-2-xs;

    &__item {
        margin-top: $token-spacers-2-xs;
        margin-right: $token-spacers-m;
        justify-content: flex-start !important;

        &:first-child {
            min-width: 240px;
        }

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint(mobile) {
            &.z-button {
                text-align: left;
            }
        }
    }
}

.sale-page-card {
    padding: $token-spacers-s $token-spacers-2-xs;
    border-top: 1px solid $token-colors-gray-20;

    @include breakpoint(v-tablet) {
        padding: $token-spacers-s 0;
    }

    &:last-child {
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__wrapper {
        display: flex;

        .z-checkbox-group {
            min-width: 40px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__tag {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        text-align: center;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
        background-color: $token-colors-blue;
        margin-left: $token-spacers-m;
    }

    &__date-wrapper {
        margin-left: $token-spacers-xs;
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            margin-left: 0;
            margin-top: $token-spacers-3-xs;
        }
    }

    &__date-key {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-right: $token-spacers-3-xs;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__date-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;
        font-family: $robotoMono;

        &--hot {
            color: $token-colors-alert-text;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin-left: $token-spacers-3-xs;
    }

    &__text {
        display: block;
        color: $token-colors-gray-60;
        width: 100%;
        max-width: 1040px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        font-family: $robotoMono;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__type,
    &__subtype,
    &__tech-props,
    &__code {
        max-width: 33.3333%;

        @include breakpoint (tablet) {
            max-width: 50%;
        }
    }

    &__brand,
    &__gost {
        max-width: 16.66665%;

        @include breakpoint (tablet) {
            max-width: 50%;
        }
    }

    &__amount,
    &__price {
        max-width: 12.66665%;
    }

    &__amount-name {
        max-width: 8%;
    }

    &__amount,
    &__price,
    &__amount-name {
        @include breakpoint (tablet) {
            max-width: 50%;
        }
    }

    &__comments {
        font-family: $robotoMono;
        padding: 0;
    }

    &__checkbox {
        & input {
            display: none;

            &:checked {
                + .sale-page-card__checkbox-box {
                    border-color: $token-colors-blue;

                    .z-icon {
                        display: block;
                        path {
                            fill: $token-colors-blue !important;
                        }
                    }
                }
            }
        }

        &-box {
            display: block;
            width: 24px;
            height: 24px;
            background-color: $token-colors-white;
            border: 1px solid $token-colors-gray-30;
            border-radius: 4px;
            transition: all 0.2s ease-in;
            flex-shrink: 0;
            cursor: pointer;

            .z-icon {
                display: none;
            }
        }
    }
}
</style>
