<template>
    <component
        :is="tag"
        v-on="$listeners"
        class="z-button"
        :class="classObject"
        :href="tag === 'a' ? href : false"
    >
        <div v-if="hasLeftSlot" class="z-button__icon z-button__icon--align-left">
            <slot name="left"></slot>
        </div>
        <span class="z-button__text">
            <slot/>
        </span>
        <div v-if="hasRightSlot" class="z-button__icon z-button__icon--align-right">
            <slot name="right"></slot>
        </div>
        <div v-if="hasAppendSlot" class="z-button__append">
            <slot name="append"></slot>
        </div>
        <z-icon v-if="hasHoverArrow" class="z-button__icon-hover" name="arrow-long" dir="right"/>
    </component>
</template>

<script>
export default {
    name: 'z-button',
    props: {
        tag: {
            type: String,
            default: 'button'
        },
        kind: {
            type: String,
            validator: prop => [
                'primary',
                'secondary',
                'text',
                'white',
                'bordered-dark',
                'bordered-light',
                'bordered-white'
            ].includes(prop),
            default: 'primary'
        },
        href: String,
        rounded: Boolean,
        size: {
            type: String,
            validator: prop => ['xs', 's', 'm', 'l'].includes(prop),
            default: 'm'
        },
        inverted: Boolean,
        disabled: Boolean,
        showArrowOnHover: {
            type: Boolean,
            default: false
        },
        isCircleIcon: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        hasLeftSlot () {
            return !!this.$slots['left']
        },
        hasRightSlot () {
            return !!this.$slots['right']
        },
        hasAppendSlot () {
            return !!this.$slots['append']
        },
        hasHoverArrow () {
            if (this.showArrowOnHover) {
                return true
            } else return !this.hasLeftSlot && !this.hasRightSlot && !this.hasAppendSlot && (this.kind === 'bordered-dark' || this.kind === 'bordered-light')
        },
        classObject () {
            let arrClass = [
                {
                    'z-button--rounded': this.rounded,
                    'z-button--inverted': this.inverted,
                    'z-button--circled': this.isCircleIcon
                }
            ]

            if (this.kind) {
                arrClass.push(`z-button--kind-${this.kind}`)
            }

            if (this.size) {
                arrClass.push(`z-button--size-${this.size}`)
            }

            if (this.hasHoverArrow) {
                arrClass.push('z-button--kind-hover-icon')
            }

            if (this.disabled) {
                arrClass.push(`z-button--disabled`)
            }

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
