<template>
    <div class="envelope">
        <div
            class="envelope__mail mail"
            ref="mail">
            <div
                class="mail__bg"
                ref="mail-bg"></div>
            <div class="mail__envelope-container">
                <img
                    class="mail__envelope"
                    src="/images/innovation/envelope/mail.png"
                    alt=""
                    draggable="false"
                    ref="mail-envelope" />
                <img
                    class="mail__envelope-shadow"
                    src="/images/innovation/envelope/mail-shadow.png"
                    alt=""
                    draggable="false"
                    ref="mail-envelope-shadow" />
            </div>
            <img
                class="mail__frame"
                src="/images/innovation/envelope/frame.png"
                alt=""
                draggable="false" />
        </div>
        <div
            class="envelope__platform platform"
            ref="platform">
            <img
                class="platform__shadow"
                src="/images/innovation/envelope/platform-shadow.png"
                alt=""
                draggable="false" />
            <img
                class="platform__block"
                src="/images/innovation/envelope/platform.png"
                alt=""
                draggable="false" />
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'

export default {
    name: 'envelope',
    methods: {
        setTimelineAnimation() {
            const tl = gsap.timeline({
                repeat: -1,
                defaults: {
                    duration: 6,
                    ease: 'power3'
                }
            })
            tl.addLabel('first-step', 0)
                .to(
                    this.$refs.mail,
                    {
                        y: '-40px'
                    },
                    'first-step'
                )
                .to(
                    this.$refs.platform,
                    {
                        y: '-20px'
                    },
                    'first-step'
                )
                .fromTo(
                    this.$refs['mail-bg'],
                    {
                        width: '70%',
                        height: '70%'
                    },
                    {
                        width: '100%',
                        height: '100%'
                    },
                    'first-step'
                )
                .fromTo(
                    this.$refs['mail-envelope'],
                    {
                        width: '30%',
                        height: '20%'
                    },
                    {
                        width: '80%',
                        height: '60%'
                    },
                    'first-step'
                )
                .fromTo(
                    this.$refs['mail-envelope-shadow'],
                    {
                        width: '40%',
                        height: '20px',
                        bottom: '16px'
                    },
                    {
                        width: '80%',
                        height: '40px',
                        bottom: '-20px'
                    },
                    'first-step'
                )
                .addLabel('second-step', 6)
                .to(
                    this.$refs['mail-bg'],
                    {
                        width: '46.5%',
                        height: '46.8%'
                    },
                    'second-step'
                )
                .to(
                    this.$refs['mail-envelope'],
                    {
                        width: '47%',
                        height: '30%'
                    },
                    'second-step'
                )
                .to(
                    this.$refs['mail-envelope-shadow'],
                    {
                        height: '20px',
                        bottom: '30px'
                    },
                    'second-step'
                )
                .addLabel('third-step', 12)
                .to(
                    this.$refs['mail'],
                    {
                        y: 0
                    },
                    'third-step'
                )
                .to(
                    this.$refs['mail-bg'],
                    {
                        width: '70%',
                        height: '70%'
                    },
                    'third-step'
                )
                .to(
                    this.$refs['mail-envelope'],
                    {
                        width: '30%',
                        height: '20%'
                    },
                    'third-step'
                )
                .to(
                    this.$refs['mail-envelope-shadow'],
                    {
                        width: '40%',
                        bottom: '16px'
                    },
                    'third-step'
                )
                .to(
                    this.$refs.platform,
                    {
                        y: 0
                    },
                    'third-step'
                )
        }
    },
    mounted() {
        this.setTimelineAnimation()
    }
}
</script>

<style lang="scss">
.envelope {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 173px;

    @include breakpoint(mobile) {
        width: 143px;
    }

    img {
        user-select: none;
        object-fit: contain;
    }
}
.mail {
    margin-bottom: 40px;
    pointer-events: none;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    isolation: isolate;

    height: 220px;

    @include breakpoint(mobile) {
        height: 178px;
    }

    &__frame {
        position: relative;
        z-index: 2;
        object-fit: contain;

        width: 100%;
        height: 100%;
    }

    &__envelope-container {
        position: absolute;
        z-index: 1;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        width: 70%;
        height: 70%;
    }

    &__envelope {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 30%;
        height: 20%;
    }

    &__envelope-shadow {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        width: 40%;
        height: 20px;
        bottom: 16px;
    }

    &__bg {
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        right: 0;
        background: radial-gradient(#ebf7ff, #89c4ff), linear-gradient(#b7d0e5, #ffffff);
        border-radius: 15px;
        isolation: isolate;

        width: 70%;
        height: 70%;
    }
}

.platform {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

    height: 37px;

    &__block {
        position: absolute;
        z-index: 1;

        width: 100%;
    }

    &__shadow {
        position: absolute;
        z-index: 0;

        top: -14px;
        width: 175%;
    }
}
</style>
