/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'briefcase': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 2.4A2.6 2.6 0 007.4 5v1.4H4A2.6 2.6 0 001.4 9v10A2.6 2.6 0 004 21.6h16a2.6 2.6 0 002.6-2.6V9A2.6 2.6 0 0020 6.4h-3.4V5A2.6 2.6 0 0014 2.4h-4zm5.4 4V5A1.4 1.4 0 0014 3.6h-4A1.4 1.4 0 008.6 5v1.4h6.8zM8.6 7.6h6.8v12.8H8.6V7.6zm-1.2 0H4A1.4 1.4 0 002.6 9v10A1.4 1.4 0 004 20.4h3.4V7.6zm9.2 12.8V7.6H20A1.4 1.4 0 0121.4 9v10a1.4 1.4 0 01-1.4 1.4h-3.4z" _fill="#0077C8"/>'
  }
})
