/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faq-question': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.541 16C1.541 8.016 8.015 1.543 16 1.543c7.985 0 14.459 6.473 14.459 14.459 0 7.985-6.474 14.458-14.459 14.458-7.985 0-14.459-6.473-14.459-14.458zM16 .343C7.352.342.341 7.352.341 16c0 8.648 7.01 15.658 15.659 15.658 8.648 0 15.659-7.01 15.659-15.658C31.659 7.352 24.649.34 16 .34zM12.89 13.6a3.219 3.219 0 016.438 0v.224a3.443 3.443 0 01-1.533 2.865l-.523.348c-.351.235-.563.63-.563 1.053v.748h-1.2v-.748c0-.824.412-1.594 1.098-2.051l.522-.348a2.244 2.244 0 001-1.867V13.6a2.019 2.019 0 00-4.039 0h-1.2zm3.723 7.49a.941.941 0 11-1.882 0 .941.941 0 011.882 0z" _fill="#0077C8"/>'
  }
})
