/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play-block': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<circle pid="0" opacity=".8" cx="50" cy="50" r="50" _fill="#004C97"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M35.586 28.66c0-1.583 1.75-2.538 3.081-1.683l31.297 20.12a2 2 0 010 3.364l-31.297 20.12c-1.331.856-3.081-.1-3.081-1.682v-40.24zm33.297 20.12L37.586 28.66v40.239l31.297-20.12z" _fill="#fff"/>'
  }
})
