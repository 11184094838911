<template>
    <div class="container">
        <z-caption
            tag="h2"
            size="3xl"
            decor-right
            class="u-bottom-margin--m"
        >
            <vue-raw :raw="text.title" />
        </z-caption>
        <div class="row u-flex-align--flex-start u-top-margin--m-important">
            <div class="col-default-8 col-tablet-12">
                <div class="row ratings-block ratings-block__row">
                    <div class="col-default-6 col-mobile-12 ratings-block__col u-collapse--top">
                        <credit-rating
                            score="B"
                            image="/images/sustainability/rating--msci.svg"
                        />
                    </div>
                    <div class="col-default-6 col-mobile-12 ratings-block__col u-collapse--top">
                        <credit-rating
                            score="58"
                            score-subtitle="/100"
                            image="/images/sustainability/rating--ecovadis.png"
                        />
                    </div>
                    <div class="col-default-6 col-mobile-12 ratings-block__col">
                        <credit-rating
                            :score="this.$root.lang !== 'ru' ? '44.0' : '44,0'"
                            score-subtitle="/100"
                            image="/images/sustainability/rating--sustainalytics.svg"
                            details="ESG Risk Rating"
                        />
                    </div>
                    <div class="col-default-6 col-mobile-12 ratings-block__col">
                        <credit-rating
                            image="/images/sustainability/moex-logo.png"
                            :details="this.$root.lang !== 'ru' ? 'as part of the MOEX ESG-index' : 'в составе ESG индекса'"
                        />
                    </div>
                </div>
            </div>
            <div class="col-default-4 col-tablet-12">
                <div class="prizes-block">
                    <simple-slider>
                        <slide>
                            <div
                                v-for="(prize, index) in text.prizes.slide1"
                                :key="index"
                                class="prizes-block__item"
                            >
                                <div class="prizes-block__image-wrapper">
                                    <img
                                        :src="prize.imageSrc"
                                        :alt="prize.imageAlt"
                                        :title="prize.imageAlt"
                                    >
                                </div>
                                <z-caption
                                    tag="p"
                                    size="xl"
                                    class="prizes-block__text u-left-margin--s"
                                >
                                    <vue-raw :raw="prize.title" />
                                </z-caption>
                            </div>
                        </slide>
                        <slide>
                            <div
                                v-for="(prize, index) in text.prizes.slide2"
                                :key="index"
                                class="prizes-block__item"
                            >
                                <div class="prizes-block__image-wrapper">
                                    <img
                                        :src="prize.imageSrc"
                                        :alt="prize.imageAlt"
                                        :title="prize.imageAlt"
                                    >
                                </div>
                                <z-caption
                                    tag="p"
                                    size="xl"
                                    class="prizes-block__text u-left-margin--s"
                                >
                                    <vue-raw :raw="prize.title" />
                                </z-caption>
                            </div>
                        </slide>
                        <slide>
                            <div
                                v-for="(prize, index) in text.prizes.slide3"
                                :key="index"
                                class="prizes-block__item"
                            >
                                <div class="prizes-block__image-wrapper">
                                    <img
                                        :src="prize.imageSrc"
                                        :alt="prize.imageAlt"
                                        :title="prize.imageAlt"
                                    >
                                </div>
                                <z-caption
                                    tag="p"
                                    size="xl"
                                    class="prizes-block__text u-left-margin--s"
                                >
                                    <vue-raw :raw="prize.title" />
                                </z-caption>
                            </div>
                        </slide>
                    </simple-slider>
                </div>
            </div>
        </div>

        <div class="u-top-margin--m-important">
            <z-button
                tag="a"
                kind="text"
                class="u-normal"
                :href="text.linkHref"
            >
                <template v-slot:left>
                    <z-icon
                        name="arrow-long"
                        dir="right"
                        width="16px"
                        height="16px"
                    ></z-icon>
                </template>

                <span v-html="text.linkTitle"></span>
            </z-button>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'results-and-targets',
    data () {
        return {
            text: {
                title: localize({
                    'ru': 'Оценка ESG: рейтинги, индексы и&nbsp;инициативы',
                    'en': 'ESG assessment: ratings, indexes and initiatives'
                }),
                ratings: [
                    {
                        details: localize({
                            'ru': 'Экология',
                            'en': 'Environment'
                        })
                    }
                ],
                prizes: {
                    slide1: [
                        {
                            title: localize({
                                'ru': 'В&nbsp;2016 году &laquo;Норникель&raquo; присоединился к&nbsp;Глобальному договору ООН.',
                                'en': 'In&nbsp;2016, the Company joined the United Nations Global Compact'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-1.svg',
                                'en': '/images/sustainability/prize-1.svg'
                            }),
                            imageAlt: localize({
                                'ru': 'The Global Compact',
                                'en': 'The Global Compact'
                            })
                        },
                        {
                            title: localize({
                                'ru': 'Экологический менеджмент компании соответствует международным стандартам ISO 14001: 2015',
                                'en': 'Norilsk Nickel&rsquo;s environmental management is&nbsp;in&nbsp;compliance with international standards ISO 14001:2015'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/assessment/iso14001.png',
                                'en': '/images/sustainability/assessment/iso14001.png'
                            }),
                            imageAlt: localize({
                                'ru': 'ISO',
                                'en': 'ISO'
                            })
                        },
                        {
                            title: localize({
                                'ru': 'Соблюдение GRI (глобальные инициативы по&nbsp;отчетности) и&nbsp;процедуры публичной проверки РСПП. Первый отчет о&nbsp;социальной ответственности в&nbsp;2003&nbsp;г.',
                                'en': 'Compliance with GRI (global reporting initiatives) and RSPP public verification procedure. First social responsibility report in&nbsp;2003.'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-3.svg',
                                'en': '/images/sustainability/prize-3.svg'
                            }),
                            imageAlt: localize({
                                'ru': 'GRI',
                                'en': 'GRI'
                            })
                        }
                    ],
                    slide2: [
                        {
                            title: localize({
                                'ru': 'Членство в&nbsp;Никелевом институте с&nbsp;2005&nbsp;г.',
                                'en': 'Nickel institute&nbsp;&mdash; member since 2005'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-4.svg',
                                'en': '/images/sustainability/prize-4.svg'
                            }),
                            imageAlt: localize({
                                'ru': 'Никелевый институт',
                                'en': 'Nickel institute'
                            })
                        },
                        {
                            title: localize({
                                'ru': 'Членство в&nbsp;Ассоциации металлов международной платиновой группы с&nbsp;1999&nbsp;г.',
                                'en': 'International Platinum group metals association&nbsp;&mdash; member since 1999'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-5.png',
                                'en': '/images/sustainability/prize-5.png'
                            }),
                            imageAlt: localize({
                                'ru': 'IPA',
                                'en': 'IPA'
                            })
                        },
                        {
                            title: localize({
                                'ru': 'Членство в&nbsp;Альянсе производителей аккумуляторных батарей с&nbsp;2021&nbsp;г.',
                                'en': 'Global Battery Alliance&nbsp;&mdash; member since 2021'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-6.png',
                                'en': '/images/sustainability/prize-6.png'
                            }),
                            imageAlt: localize({
                                'ru': 'Global Battery Alliance',
                                'en': 'Global Battery Alliance'
                            })
                        }
                    ],
                    slide3: [
                        {
                            title: localize({
                                'ru': 'Присоединение к&nbsp;международной блокчейн-сети ответственного выбора поставщиков в&nbsp;Декабре 2020',
                                'en': 'Joined Responsible sourcing block chain network in&nbsp;December 2020'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-7.svg',
                                'en': '/images/sustainability/prize-7.svg'
                            }),
                            imageAlt: localize({
                                'ru': 'RSC | Global Group',
                                'en': 'RSC | Global Group'
                            })
                        },
                        {
                            title: localize({
                                'ru': 'Присоединение к&nbsp;инициативе Re|Source в&nbsp;Августе 2021',
                                'en': 'Joined Re|Source in&nbsp;August 2021'
                            }),
                            imageSrc: localize({
                                'ru': '/images/sustainability/prize-8.svg',
                                'en': '/images/sustainability/prize-8.svg'
                            }),
                            imageAlt: localize({
                                'ru': 'Re|Source',
                                'en': 'Re|Source'
                            })
                        }
                    ]
                },
                linkHref: localize({
                    'ru': '/sustainability/assessment/',
                    'en': '/sustainability/assessment/'
                }),
                linkTitle: localize({
                    'ru': 'Подробнее об оценке ESG',
                    'en': 'Lean more about ESG assessment'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.ratings-block {
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);
    @include padding-level(left, XL);
    @include padding-level(right, XL);
    background: $token-colors-white;
    box-shadow: 0px 8px 8px rgba(0, 32, 51, 0.04), 0px 12px 28px rgba(0, 32, 51, 0.12);
    border-radius: 0px $boder-radius-l;

    &__inner {}

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: -#{$token-spacers-2-xs} -#{$token-spacers-xs};
    }

    &__col {
        display: flex;
        justify-content: center;
        padding: $token-spacers-2-xs $token-spacers-xs;
        max-width: 446px;

        &:last-child {
            margin-top: 88px!important;

            @include breakpoint(mobile) {
                margin-top: 32px!important;
            }
        }
    }

    @include breakpoint(mobile) {
        @include padding-level(left, XS);
        @include padding-level(right, XS);

        &__col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.prizes-block {
    @include margin-level(left, M);

    &__item {
        display: flex;
        align-items: center;

        & + & {
            @include margin-level(top, XL);
        }
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 120px;
        max-width: 120px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__text {
        color: $token-colors-gray-60;
    }

    @include breakpoint(tablet) {
        margin-left: 0;
        @include margin-level(top, L);
        @include margin-level(bottom, S);
    }
}
</style>
