/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round_pdf': {
    width: 62,
    height: 36,
    viewBox: '0 0 62 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.276 35H44c9.389 0 17-7.611 17-17S53.389 1 44 1H18.276c-9.39 0-17 7.611-17 17s7.61 17 17 17zm-18-17c0 9.941 8.059 18 18 18H44c9.941 0 18-8.059 18-18S53.941 0 44 0H18.276c-9.941 0-18 8.059-18 18zm17.159 4.5h1.638v-3.486h2.464c1.946 0 3.052-1.344 3.052-2.926s-1.092-2.926-3.052-2.926h-4.102V22.5zm5.474-6.412c0 .896-.672 1.484-1.596 1.484h-2.24v-2.968h2.24c.924 0 1.596.588 1.596 1.484zm4.443 6.412h3.472c2.912 0 4.886-1.918 4.886-4.662 0-2.744-1.974-4.676-4.886-4.676h-3.472V22.5zm6.678-4.662c0 1.764-1.176 3.22-3.206 3.22H28.99v-6.454h1.834c2.086 0 3.206 1.428 3.206 3.234zm4.662 4.662h1.638v-4.032h4.662v-1.442H40.33v-2.422h4.76v-1.442h-6.398V22.5z" _fill="#0077C8"/>'
  }
})
