<template>
    <div class="stocks-card glass-effect">
        <z-caption
            tag="h2"
            decor-left
            decor-right
            size="m"
            weight="700"
            class="stocks-card__header"
            uppercase
            theme="white"
        >
            <span data-scroll-heading>
                {{ textLocales.shares }}
            </span>
        </z-caption>

        <iframe
            data-scroll-translate
            :src="textLocales.stocksLink"
            onload="iFrameResize({}, this);"
            width="100%"
            height="200px"
            border="0"
            frameborder="0"
            allowtransparency="true"
            framespacing="0"
            scrolling="no"
            class="stocks-card__iframe" />

        <div class="stocks-card__button-wrapper" data-scroll-translate>
            <z-button
                href="/investors/shareholders/stockchart/"
                tag="a"
                kind="text"
                size="l"
                class="u-right-margin--l stocks-card__button"
                @click="handlerTrackGoal(goal)"
            >
                <template v-slot:left>
                    <z-icon
                        name="arrow-long"
                        dir="right"
                        width="16"
                        height="16"
                    />
                </template>
                <span v-html="textLocales.quotes"></span>
            </z-button>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { trackGoal } from '@/utils/analytics'
export default({
    name: 'stocks-card',
    props: {
        goal: String,
    },
    data () {
        return {
            textLocales: {
                shares: localize({
                    ru: 'Aкции',
                    en: 'Shares'
                }),
                quotes: localize({
                    ru: 'Котировки',
                    en: 'Quotes'
                }),
                stocksLink: localize({
                    ru: 'https://nornik-ru.injector.3ebra.net/nornik/ru/',
                    en: 'https://nornik-en.injector.3ebra.net/nornik/en/'
                })
            }
        }
    },
    methods: {
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
})
</script>

<style lang="scss">
.stocks-card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $token-colors-navy-blue;
    border-radius: $boder-radius-l 0px $boder-radius-m;
    padding: $token-spacers-m;

    & .z-key-indicator__value {
        margin-right: 40px;
    }

    & .z-key-indicator__footer {
        margin-top: 8px;
    }

    & .z-key-indicator {
        margin-bottom: 24px;
    }

    .z-caption {
        margin-bottom: $token-spacers-s;
    }

    @include breakpoint(tablet) {
        padding: 40px;
        flex: 1 1 35%;
        flex-shrink: 0;
    }

    @include breakpoint(v-tablet) {
        padding: 40px $token-spacers-s;

        .z-caption {
            margin-bottom: $token-spacers-xs;
        }
    }

    @include breakpoint(mobile) {
        padding: $token-spacers-xs;

        & .z-key-indicator {
            padding: 0 16px;
        }

        & .z-key-indicator__value {
            margin-right: 40px;
        }

        & .z-key-indicator__footer {
            margin-top: 18px;
        }

        & .z-key-indicator {
            margin-bottom: 28px;
            padding: 0;
        }

        .z-caption {
            margin-bottom: 1.25rem;

            &:after,
            &:before {
                display: block !important;
                min-width: 64px;
            }
        }

        .z-caption__text {
            padding-left: 16px !important;
        }
    }

    .z-button {
        text-align: start;
    }

    .z-key-indicator {
        .z-key-indicator__value-text {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: $token-colors-success;
        }

        &.is-negative {
            .z-key-indicator__value-text {
                color: $token-colors-warning;
            }
        }
    }

    &__iframe {
        min-height: 201px;

        @media (max-width: 1330px) {
            min-height: 177px;
        }

        @media (max-width: 1167px) {
            min-height: 146px;
        }

        @media (max-width: 1023px) {
            min-height: 201px;
        }

        @media (max-width: 408px) {
            min-height: 177px;
        }

        @media (max-width: 348px) {
            min-height: 146px;
        }
    }

    &__button.z-button {
        @include typo-level(L);
        font-size: 20px;
        line-height: 140%;
        color: $token-colors-blue-30;

        .z-button__icon {
            path {
                fill: $token-colors-blue-30;
            }
        }

        &:hover,
        &:active,
        &:focus {
            color: $token-colors-blue-10;

            .z-button__icon {
                background-color: $token-colors-white;

                path {
                    fill: $token-colors-navy-blue;
                }
            }
        }

        @include breakpoint(tablet) {
            &:not(:last-child) {
                @include margin-level(bottom, S);
            }
        }
    }

    &__button-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        margin-top: $token-spacers-s;

        @include breakpoint(tablet) {
            flex-direction: column;
            align-items: start;
            flex-wrap: wrap;
        }

        @include breakpoint(mobile) {
            margin-top: 1.25rem;
        }
    }
}
</style>
