<template>
    <div class="sg-icons">
        <div
            v-for="icon in icons"
            :key="icon"
            class="sg-icons__el"
        >
        <z-icon
            width="48"
            height="48"
            :name="icon"
        ></z-icon>
        <span class="sg-icons__name">
            {{ icon }}
        </span>
    </div>
    </div>
</template>

<script>
export default {
    name: 'icons',
    data () {
        return {
            icons: []
        }
    },
    methods: {
        getIconsList () {
            const modules = require.context('../../components/ZIcon', true, /\.js$/)

            modules.keys()
                .filter(file => !file.endsWith('index.js'))
                .forEach((file) => {
                    const iconName = file.replace('./', '').replace('.js', '')
                    this.icons.push(iconName)
                })
        }
    },
    mounted () {
        this.getIconsList()
    }
}
</script>

<style lang="scss">
</style>
