var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'slider',
        {
            'slider--with-thumbs': _vm.$slots.thumbs,
            'slider--with-further': this.further,
            'slider--half-screen': _vm.halfScreen
        }
    ]},[(_vm.name)?_c('div',{staticClass:"slider__name"},[_c('div',{staticClass:"container"},[_c('z-caption',{staticClass:"white-text",attrs:{"decor-right":"","decor-left":"","href":_vm.link}},[_vm._v(_vm._s(_vm.name))])],1)]):_vm._e(),_vm._v(" "),_c('div',{ref:"slider",staticClass:"swiper-container slider__container"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._t("default")],2)]),_vm._v(" "),_c('div',{staticClass:"slider__footer"},[_c('div',{staticClass:"container u-relative"},[_c('div',{staticClass:"slider__controls"},[_c('div',{staticClass:"slider__fraction"},[_c('span',{staticClass:"modern-slider__fraction-active"},[_vm._v("\n                        "+_vm._s(_vm.getFormattedNumber(_vm.activeSlide))+"\n                    ")]),_vm._v("\n                    /\n                    "),_c('span',{staticClass:"modern-slider__fraction-count"},[_vm._v("\n                        "+_vm._s(_vm.getFormattedNumber(_vm.countSlides))+"\n                    ")])]),_vm._v(" "),_c('button',{ref:"prev",staticClass:"slider__nav-btn slider__nav-btn--prev"},[_c('z-icon',{staticClass:"slider__nav-icon",attrs:{"name":"round-arrow-folder/arrow","dir":"left","width":"24px","height":"24px"}})],1),_vm._v(" "),_c('div',{ref:"pagination",staticClass:"slider__pagination",staticStyle:{"display":"none"}}),_vm._v(" "),_c('button',{ref:"next",staticClass:"slider__nav-btn slider__nav-btn--next"},[_c('z-icon',{staticClass:"slider__nav-icon",attrs:{"name":"round-arrow-folder/arrow","dir":"right","width":"24px","height":"24px"}})],1)])]),_vm._v(" "),(!!_vm.$slots.thumbs)?_c('div',{staticClass:"slider__thumbs"},[_c('div',{staticClass:"container u-all-padding--no u-relative"},[_c('slider-thumbs-container',{attrs:{"data":_vm.thumbs}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }