<template>
    <div class="sale-page">
        <card-link
            class="u-bottom-margin--m"
            :link="warningLink"
            :target="isFrame ? '_blank' : '_self'"
            theme="warning"
            image="/images/spy.svg"
            style="max-width: 1360px;"
        >
            Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
        </card-link>

        <sale-page-filter class="u-bottom-margin--l" :filters-data="filtersData" @update-filters="filtersHandler" />
        <sale-page-list :items="items" :filter-params="params" @all-selected="onAllSelected" @show-selected="onShowSelected" />
        <z-pagination
            v-if="nav"
            :data="{
                currentPage: nav.current,
                totalPages: nav.total,
                pageRange: 1
            }"
            @change-page="pageHandler"
        />
        <template v-else-if="loading">
            <z-preloader v-if="loading"/>
        </template>
        <template v-else>
            <z-not-found />
        </template>
    </div>
</template>

<script>
import SalePageFilter from './components/SalePageFilter.vue'
import SalePageList from './components/SalePageList.vue'
import { getSalePage } from '@/api/illiquid'
// import { getSalePageFile } from '@/api/illiquid-file'
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'
import { clearEmptyParams } from '@/utils/clearEmptyParams'

export default {
    name: 'sale-page',
    components: {
        SalePageFilter,
        SalePageList
    },
    data () {
        return {
            filtersData: this.$root.app?.components['illiquid-property']?.filters || {},
            items: this.$root.app?.components['illiquid-property']?.items || [],
            nav: this.$root.app?.components['illiquid-property']?.nav || null,
            isFrame: false,
            page: 1,
            isMore: false,
            loading: false,
            params: {
                id: [],
                excludeId: []
            }
        }
    },
    created () {
        // For iframe visual
        if (window.self !== window.top) {
            this.isFrame = true
            setTimeout(() => {
                try {
                    document.querySelector('.header').remove()
                    document.querySelector('.footer').remove()
                    document.querySelector('.z-hero').remove()
                    document.querySelector('.main__page-nav').remove()
                    document.querySelector('.main__container').style.cssText = 'padding: 0 !important; margin: 0 !important;'
                    document.querySelector('.main__container .content-container').style.cssText = 'padding: 0 !important; margin: 0 !important; border-radius: 0 !important; box-shadow: none !important;'
                } catch (error) {
                    console.log(error)
                }
            }, 0)
        }
    },
    methods: {
        onAllSelected (payload) {
            this.params = { ...this.params, ...payload }
        },
        onShowSelected (payload) {
            this.page = 1
            this.params = { ...this.params, ...payload }
            this.send({
                ...this.params,
                page: this.page
            })
        },
        send: debounce(300, function (params) {
            this.loading = true
            getSalePage(clearEmptyParams(params))
                .then((response) => {
                    this.filtersData = response.filters
                    this.nav = response.nav
                    this.page = this.nav.current

                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                    } else {
                        this.items = response.items
                    }
                })
                .catch((err) => {
                    showNotyfications('error', err)
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        filtersHandler (payload) {
            this.page = 1
            this.params = { ...this.params, ...payload }
            this.send({
                ...this.params,
                page: this.page
            })
        },
        pageHandler (payload) {
            this.isMore = payload.type === 'load-more'
            this.page = payload.page
            this.send({
                ...this.params,
                page: this.page
            })
        }
    },
    computed: {
        warningLink () {
            return this.isFrame ? 'https://www.nornickel.ru/suppliers/warning/' : '/suppliers/warning/'
        }
    },
    beforeMount () {
        this.$nextTick(() => {
            const initialFilters = localStorage.getItem('illiquid-property-filter')

            if (initialFilters) {
                this.params = { ...this.params, ...JSON.parse(initialFilters) }
            }
        })
    }
}
</script>

<style lang="scss">
.sale-page {
    max-width: 1360px;
    width: 100%;
}
</style>
