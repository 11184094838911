/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closure': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M9.63.615h12.74l9.015 9.015V22.37l-9.016 9.015H9.631L.615 22.37V9.63L9.631.615z" _fill="#fff" _stroke="#EB5757" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M21.05 11.82a.615.615 0 00-.87-.87L16 15.13l-4.18-4.18a.615.615 0 10-.87.87L15.13 16l-4.18 4.18a.615.615 0 10.87.87L16 16.87l4.18 4.18a.615.615 0 00.87-.87L16.87 16l4.18-4.18z" _fill="#EB5757"/>'
  }
})
