/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long-4': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_10835_2215)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.842.274a.498.498 0 00-.196.12L4.25 7.792a.5.5 0 10.707.707L11.5 1.955V23.25a.5.5 0 001 0V1.955L19.044 8.5a.5.5 0 00.707-.707L12.36.4a.499.499 0 00-.517-.126z" _fill="#0077C8"/></g><defs><clipPath id="clip0_10835_2215"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
