/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.826 1.566c.385.042.639.41.57.79-.068.382-.432.633-.818.595a9.097 9.097 0 108.188 9.192c.006-.387.297-.72.684-.744a.667.667 0 01.72.658 10.5 10.5 0 11-9.344-10.491zm3.57.327h6.434v.643a.9.9 0 010 .033v5.759a.66.66 0 01-1.32 0V4.145l-9.373 9.374a.66.66 0 11-.933-.933l9.374-9.374h-4.183a.66.66 0 010-1.319z" _fill="#0077C8"/>'
  }
})
