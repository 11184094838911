<template>
    <div class="z-key-indicator" :class="classObject">
        <div class="z-key-indicator__body">
            <span v-if="name" class="z-key-indicator__name">
                <template v-if="theme === 'stock'">
                    <z-tooltip icon-hidden placement="bottom" :content="lang === 'ru' ? 'Изменение к цене закрытия предыдущего дня торгов' : ''">
                        <vue-raw :raw="buildPercent(name)"/>
                    </z-tooltip>

                </template>
                <vue-raw v-else :raw="name"/>
            </span>
            <div class="z-key-indicator__container" v-if="value || valueText">
                <div
                    v-if="circleColor || circleTheme"
                    :class="[
                        'z-key-indicator__circle',
                        { [`z-key-indicator__circle--theme-${this.circleTheme}`]: this.circleTheme }
                    ]"
                    :style="{
                        backgroundColor: circleColor
                    }"
                ></div>
                <div
                    :class="[
                        'z-key-indicator__value-wrapper',
                        { 'z-key-indicator__value-wrapper--column': column }
                    ]"
                >
                    <div v-if="textLeft || value" class="z-key-indicator__value-container">
                        <span v-if="textLeft" class="z-key-indicator__value-text z-key-indicator__value-text--left">
                            <vue-raw :raw="textLeft" />
                        </span>
                        <span v-if="value" v-html="value" class="z-key-indicator__value"/>
                    </div>
                    <vue-raw :raw="valueText"  v-if="valueText" class="z-key-indicator__value-text"/>
                </div>
            </div>
            <span v-if="caption" class="z-key-indicator__caption">
                <vue-raw :raw="caption" />
            </span>
        </div>
        <div v-if="!!this.$slots['footer']" class="z-key-indicator__footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import verge from 'verge'
import { CountUp } from 'countup.js'

export default {
    name: 'z-key-indicator',
    data () {
        return {
            options: {
                ru: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: '',
                    decimal: ',',
                    prefix: '',
                    suffix: ''
                },
                en: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: '',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            },
            viewport: false,
            counter: null,
            lang: this.$root.lang
        }
    },
    props: {
        value: [String, Number],
        textLeft: String,
        valueText: String,
        name: String,
        caption: String,
        column: Boolean,
        animation: {
            type: Boolean,
            default: false
        },
        circleTheme: {
            type: String,
            validator: (prop) => ['positive', 'neutral', 'negative', 'clean-norilsk'].includes(prop)
        },
        circleColor: String,
        theme: {
            type: String,
            validator: (prop) => ['stock', 'blue', 'white', 'gray'].includes(prop)
        },
        size: {
            type: String,
            validator: (prop) => ['l', 'm', 's', 'xs'].includes(prop),
            default: 's'
        },
        reverseColumn: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classObject () {
            return {
                'z-key-indicator--circle': this.circleColor || this.circleTheme,
                [`z-key-indicator--theme-${this.theme}`]: this.theme,
                [`z-key-indicator--size-${this.size}`]: this.size,
                [`z-key-indicator--reverse`]: this.reverseColumn
            }
        },
        valueParsed () {
            return Number.parseFloat(this.value.replace(',', '.'))
        }
    },
    methods: {
        buildPercent (number) {
            let str = number.toString()
            let style = 'color: #08AD83'

            str = this.$options.filters.numberFilter(number)

            if (!(str.indexOf('.') >= 0 || str.indexOf(',') >= 0)) {
                if (this.lang === 'ru') {
                    str += ',00'
                } else {
                    str += '.00'
                }
            }

            if (str[0] === '-') {
                style = 'color: #D70C1E'
            } else {
                str = '+' + str
            }

            return `<span style="${style}">${str}%</span>`
        },
        getDecimals () {
            if (this.value) {
                let valueParsed = Number.parseFloat(this.value.replace(',', '.'))
                return valueParsed.toString().includes('.')
                    ? valueParsed.toString().split('.').pop().length
                    : 0
            }
            return 0
        },
        inView () {
            if (verge.inViewport(this.$el)) {
                this.viewport = true
                if (this.counter) {
                    this.counter.start()
                }
            }
            this.viewport && window.removeEventListener('scroll', this.inView)
        }
    },
    mounted () {
        if (this.animation && !isNaN(Number(this.value))) {
            window.addEventListener('scroll', this.inView)
            if (this.value) {
                this.counter = new CountUp(
                    this.$el.getElementsByClassName('z-key-indicator__value')[0],
                    this.valueParsed,
                    this.options[this.$root.lang]
                )
            }
        }
        this.inView()
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.inView)
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
