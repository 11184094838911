/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_digital_economy': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M16.875 17.915a.75.75 0 00.375-.65v-6.75a.75.75 0 00-1-.703 9.755 9.755 0 00-6.338 10.97.75.75 0 001.113.513l5.85-3.38zm-1.125-6.263v5.18l-4.489 2.59c-.011-.14-.011-.283-.011-.422a8.26 8.26 0 014.5-7.348zm12.22 2.522l-.028-.052-.029-.046A9.753 9.753 0 0019.5 9.25a.75.75 0 00-.75.75v8.61l-7.389 4.302a.749.749 0 00-.269 1.031A9.75 9.75 0 0029.25 19a9.69 9.69 0 00-1.28-4.826zm-7.72-3.39a8.253 8.253 0 015.99 3.463l-5.99 3.488v-6.951zM19.5 27.25a8.293 8.293 0 01-6.702-3.439l7.068-4.116.021-.013 7.104-4.138A8.25 8.25 0 0119.5 27.25z" _fill="#fff"/>'
  }
})
