<template>
    <section ref="hero" class="section-hero">
        <video
            ref="video"
            class="section-hero__banner"
            src="/images/innovation/bg-video.mp4"
            type="video/mp4"
            muted
            loop
            :controls="false"
            :disablePictureInPicture="true"
            playsinline
        />
        <div class="section-hero__text-wrapper">
            <div class="section-hero__text-container container">
                <p
                    class="section-hero__text"
                    data-animated-text
                    v-html="text.lead"
                >                    
                </p>
            </div>
        </div>
        <form-button @click="scrollToForm"></form-button>
    </section>
</template>

<script>
import TextAnimation from '../modules/text-animation'
import gsap from 'gsap'
import FormButton from '../components/FormButton'
import { localize } from '@/utils/i18n'

export default {
    name: 'section-hero',
    components: { FormButton },
    data () {
        return {
            textAnimation: null,
            videoRef: null,
            lang: this.$root.lang,
            text: {
                lead: localize({
                    ru: 'Инновации &laquo;Норникеля&raquo; направлены на&nbsp;<span class="section-hero__text--blue">повышение эффективности</span> производства и&nbsp;<span class="section-hero__text--green">улучшение ESG&nbsp;показателей,</span> а&nbsp;также<span class="section-hero__text--blue"> создание новых продуктов</span> для высокотехнологичных отраслей.',
                    en: 'Nornickel&rsquo;s innovations aim to&nbsp;<span class="section-hero__text--blue">improve production efficiency,</span> <span class="section-hero__text--green">enhance ESG performance</span> and <span class="section-hero__text--blue">develop new products</span> for high-tech industries.'
                })
            }
        }
    },
    methods: {
        checkVideoReady () {
            const intervalId = setInterval(() => {
                if (this.videoRef.readyState === 4) {
                    this.videoRef.play()
                    clearInterval(intervalId)
                }
            }, 500)
        },
        scrollToForm () {
            gsap.to(window, {
                scrollTo: '#section-contact-anchor',
                duration: 1,
                ease: 'power3'
            })
        }
    },
    mounted () {
        if (this.$refs.video) {
            this.videoRef = this.$refs.video
            this.videoRef.load()
            this.checkVideoReady()
        }

        this.textAnimation = new TextAnimation(this.$refs.hero)
    }
}
</script>

<style lang="scss">
.section-hero {
    position: relative;
    display: flex;
    height: clamp(700px, 92vh, 916px);

    @include breakpoint(laptop) {
        height: clamp(700px, 72vh, 916px);
    }

    @include breakpoint(v-tablet) {
        height: clamp(380px, 72vh, 440px);
    }

    @include breakpoint(mobile) {
        width: 100%;
        height: auto;
        min-height: 270px;
        aspect-ratio: 1.7;
    }

    &__banner {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        user-select: none;
        position: absolute;

        &::-webkit-media-controls {
            display: none !important;
            opacity: 0;
        }

        &::-webkit-media-controls-start-playback-button {
            display: none !important;
        }
    }

    &__text-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    &__text-container {
        position: sticky;
        bottom: -50px;

        @include breakpoint(v-tablet) {
            bottom: -24px;
        }

        @include breakpoint(mobile) {
            bottom: -16px;
        }
    }

    &__text {
        max-width: 950px;
        width: 100%;
        position: relative;
        z-index: 1;
        padding-bottom: 74px;
        color: $token-colors-gray-60;
        font-weight: 600 !important;
        font-size: 40px;
        line-height: 130%;
        letter-spacing: -0.4px;

        &--blue {
            color: $token-colors-blue;
        }

        &--green {
            color: $token-colors-kola;
        }

        @include breakpoint(laptop) {
            font-size: 36px;
        }
        @include breakpoint(tablet) {
            font-size: 28px;
        }
        @include breakpoint(v-tablet) {
            width: 70%;
            max-width: none;
            font-size: 15px;
            padding-bottom: 32px;
        }

        @include breakpoint(mobile) {
            width: 100%;
            padding-bottom: 16px;
        }
    }
}
</style>
