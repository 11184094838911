<template>
    <section class="section-contact">
        <div class="section-contact__container container">
            <div class="section-contact__anchor" id="section-contact-anchor"></div>
            <section-title
                :text="text.title"
                :hasPadding="false"
            />

            <div class="section-contact__form-block">
                <div class="row">
                    <div class="section-contact__form-envelope col-default-3 col-v-tablet-12">
                        <envelope></envelope>
                    </div>

                    <div class="section-contact__form col-default-9 col-v-tablet-12">
                        <innovations-form></innovations-form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
import SectionTitle from '../components/SectionTitle.vue'
import Envelope from '../components/Envelope.vue'

export default {
    name: 'section-contact',
    components: {
        SectionTitle,
        Envelope
    },
    data () {
        return {
            text: {
                title: localize({
                    ru: 'Давайте работать вместе',
                    en: 'Let\'s work together'
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.section-contact {
    background: linear-gradient(111deg, #f4f6f9 23.84%, #e6e9ee 100%), #f5f5f5;
    @include padding-level(top, XL);
    @include padding-level(bottom, 2XL);

    @include breakpoint(v-tablet) {
          padding-top: 0;
    }

    @include breakpoint(mobile) {
          padding-top: 24px;
          padding-bottom: 24px;
    }

    .innovation-title {
        @include padding-level(top, 2XL);
        @include padding-level(bottom, XL);

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &__container {
        position: relative;
    }

    &__anchor {
        position: absolute;
        top: 48px;
        left: 0px;
        visibility: hidden;

        @include breakpoint(tablet) {
            top: 40px;
        }

        @include breakpoint(v-tablet) {
            top: 32px;
        }

        @include breakpoint(mobile) {
            top: 0px;
        }
    }

    &__form-block {
        background-color: $token-colors-white;
        background-image: url('/images/innovation/innovations-form-bg.jpg');
        background-size: 80% 100%;
        background-repeat: no-repeat;
        padding: 60px 64px 77px 32px;
        border-top-right-radius: 40px;

        @include breakpoint(tablet) {
            background-size: 150% 100%;
            padding: 60px;
        }

        @include breakpoint(v-tablet) {
            background-image: url('/images/innovation/innovations-form-bg-mobile.png');
            background-position: right bottom;
            background-size: 100% 30%;
            padding: 60px 32px;

            .row {
                flex-direction: column-reverse;
            }
        }

        @include breakpoint(mobile) {
            background-size: 100% 24%;
            padding: 32px 24px;
        }
    }

    &__form-envelope {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(tablet) {
            padding-right: 60px;
        }

        @include breakpoint(v-tablet) {
            padding-right: 12px;
            padding-top: 40px;
        }
    }
}
</style>
