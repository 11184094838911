<template>
    <div>
        <z-button size="l" kind="secondary"
            @click="$root.$bus.$emit('open-modal', {id: 'formModal'})">
            {{ text.button }}
            <template v-slot:right>
                <z-icon name="arrow-long" dir="right" width="24" height="24"></z-icon>
            </template>
        </z-button>

        <z-modal ref="modal" id="formModal">
            <z-caption tag="h3" size="2xl">
                {{ text.caption }}
            </z-caption>
            <form @submit.prevent="sendFormData" class="contacts-page-form">
                <div class="contacts-page-form__container col-tablet-12 col-default-5">
                    <z-select
                        class="contacts-page-form__input"
                        v-model="formData.category"
                        :data="categories"
                        ref="category"
                        required
                        :placeholder="text.select"
                        :value="defaultValue"
                        :deleteSelected="deleteSelected"
                    ></z-select>

                    <z-input
                        v-model="formData.fio"
                        type="text"
                        name="fio"
                        :title="text.name"
                        class="contacts-page-form__input"
                    ></z-input>

                    <z-input
                        v-model="formData.email"
                        type="email"
                        name="email"
                        title="E-mail"
                        class="contacts-page-form__input"
                    ></z-input>

                    <z-input
                        v-model="formData.phone"
                        ref="tel"
                        type="tel"
                        name="phone"
                        :title="text.tel"
                        class="contacts-page-form__input"
                    ></z-input>

                    <p class="contacts-page-form__text">{{ text.captcha }}</p>
                    <div class="contacts-page-form__captcha-container col-default-12">
                        <img
                            class="contacts-page-form__captcha-img"
                            v-if="isUpdatedCapcha"
                            :src="'/api/captcha/?' + capchaSalt"
                            width="100"
                            height="50"
                            alt=""
                        />
                        <div v-else class="contacts-page-form__captcha-img"></div>

                        <z-button
                            class="contacts-page-form__captcha-button"
                            type="button"
                            rounded
                            kind="secondary"
                            @click="updateCapcha"
                        >
                            <z-icon name="refresh" width="24" height="24"></z-icon>
                        </z-button>

                        <z-input
                            v-model="captcha"
                            type="text"
                            required
                            name="captcha"
                            ref="captcha"
                            class="col-default-6 contacts-page-form__captcha-input"
                        ></z-input>
                    </div>
                </div>
                <div class="contacts-page-form__container col-tablet-12 col-default-5">
                    <z-textarea
                        v-model="formData.msg"
                        required
                        name="msg"
                        ref="msg"
                        :title="text.question"
                        class="contacts-page-form__input contacts-page-form__input--textarea"
                    ></z-textarea>

                    <z-checkbox
                        class="contacts-page-form__checkbox"
                        v-model="isChecked"
                        required
                        name="policy"
                        ref="policy"
                        :data="[{ value: '1' }]"
                    >
                        <template #text>
                            <z-caption tag="p" weight="400" size="xs">{{ text.checkbox }}</z-caption>
                        </template>
                    </z-checkbox>

                    <z-button size="l">
                        {{ text.submit }}
                    </z-button>
                </div>
            </form>
        </z-modal>
    </div>
</template>

<script>
import { sendCaptcha } from '@/api/captcha.js'
import { showNotyfications } from '@/utils/notifications'
import { corporateHotlineForm } from '@/api/corporate-hotline-form.js'

export default {
    name: 'contacts-page-form',
    data () {
        return {
            isLoading: false,
            formData: {
                category: null,
                fio: '',
                email: '',
                phone: '',
                phrase: this.captcha,
                msg: '',
                form: 'all-contacts',
                ajax_call: 'Y'
            },
            text: {
                select: this.$root.lang === 'ru' ? 'Категория вопроса*' : 'Category*',
                button: this.$root.lang === 'ru' ? 'Связаться с нами' : 'Connect with us',
                caption: this.$root.lang === 'ru' ? 'Отправьте ваш вопрос' : 'Send your question',
                checkbox: this.$root.lang === 'ru' ? 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие ПАО «ГМК Норильский никель» на обработку моих персональных данных, указанных при заполнении формы обратной связи на сайте www.nornickel.ru' : 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC MMC Norilsk Nickel',
                name: this.$root.lang === 'ru' ? 'Фамилия Имя Отчество' : 'Full Name',
                tel: this.$root.lang === 'ru' ? 'Телефон' : 'Phone',
                captcha: this.$root.lang === 'ru' ? 'Введите код с картинки*' : 'Enter the code from the image*',
                question: this.$root.lang === 'ru' ? 'Ваш вопрос*' : 'Question*',
                submit: this.$root.lang === 'ru' ? 'Отправить' : 'Send',
                notificationRequiredTextError: this.$root.lang === 'ru' ? 'Заполните все обязательные поля' : 'Fill in all required fields',
                notificationError: this.$root.lang === 'ru' ? 'Ошибка отправки' : 'Send error',
                notificationValidateError: this.$root.lang === 'ru' ? 'Неправильный формат полей' : 'Wrong field format',
                notificationSuccess: this.$root.lang === 'ru' ? 'Отправка успешна' : 'Sending successful',
                notificationCaptchaError: this.$root.lang === 'ru' ? 'Неверный код капчи. Попробуйте снова.' : 'Invalid captcha code. Try again.',
                notificationCaptchaEmpty: this.$root.lang === 'ru' ? 'Введите код с картинки*' : 'Enter the code from the image*'
            },
            isChecked: [],
            defaultValue: '',
            deleteSelected: false,
            captcha: '',
            captchaImg: null,
            capchaSalt: Date.now(),
            isUpdatedCapcha: true,
            categories: [
                {
                    id: 'Обращение граждан',
                    selected: false,
                    disabled: false,
                    text: this.$root.lang === 'ru' ? 'Обращение граждан' : 'Сitizens\' appeal'
                },
                {
                    id: 'Коммерческие предложения',
                    selected: false,
                    disabled: false,
                    text: this.$root.lang === 'ru' ? 'Коммерческие предложения' : 'Commercial proposal'
                },
                {
                    id: 'Резюме',
                    selected: false,
                    disabled: false,
                    text: this.$root.lang === 'ru' ? 'Резюме' : 'CV'
                },
                {
                    id: 'Запрос на покупку металла',
                    selected: false,
                    disabled: false,
                    text: this.$root.lang === 'ru' ? 'Запрос на покупку металла' : 'The request for the purchase of metal'
                },
                {
                    id: 'Официальные письма из федеральных и региональных органов власти',
                    selected: false,
                    disabled: false,
                    text: this.$root.lang === 'ru' ? 'Официальные письма из федеральных и региональных органов власти' : 'Official letters from Federal and regional authorities'
                },
                {
                    id: 'Прочее',
                    selected: false,
                    disabled: false,
                    text: this.$root.lang === 'ru' ? 'Прочее' : 'Etc'
                }
            ]
        }
    },
    methods: {
        validateInputFormat () {
            if (this.formData.phone || this.formData.email) {
                const requiredFields = ['email', 'tel']
                let isValid = true
                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }
            return true
        },
        validateRequiredInputs () {
            const requiredFields = ['category', 'policy', 'msg', 'captcha']
            let isValid = true
            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        async sendFormData () {
            if (this.isLoading) {
                return
            }
            const isValidRequiredInputs = this.validateRequiredInputs()
            if (isValidRequiredInputs) {
                const isValidInputFormat = this.validateInputFormat()
                if (!isValidInputFormat) {
                    showNotyfications(this.text.notificationValidateError, { type: 'error' })
                    return
                }
                try {
                    this.isLoading = true
                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }
                    const data = new FormData()
                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }
                    const response = await sendCaptcha(captcha)
                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await corporateHotlineForm(data)
                        if (formResponse.success === true) {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })
                            this.defaultValue = ''
                            this.formData.category = null
                            this.formData.fio = ''
                            this.formData.email = ''
                            this.formData.phone = ''
                            this.captcha = ''
                            this.formData.phrase = ''
                            this.formData.msg = ''
                            this.deleteSelected = true
                            this.isChecked = []
                        } else {
                            showNotyfications(response.public.message, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        },
        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },
        changeTextarea (data) {
            this.formData[data.name] = data.value
        },
        onChange () {
            this.isChecked = !this.isChecked
        }
    }
}
</script>

<style lang="scss">
.contacts-page-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 20px;
    &__input {
        margin-bottom: $token-spacers-s;
        &--textarea {
            margin-bottom: 60px;
        }
    }
    &__container {
        padding: 0;
    }

    @include breakpoint (tablet) {
        &__container:last-of-type {
            padding-top: 60px;
        }
    }

    &__captcha-container {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        padding-left: 0;
    }
    &__captcha-img {
        margin: 0 16px 16px 0;
        width: 100px;
        height: 50px;
    }
    &__captcha-button {
        margin-right: 8px;
    }
    &__captcha-input {
        margin-bottom: 16px;

        @media screen and (max-width: 393px) {
            padding: 0 !important;
        }
    }
    &__checkbox {
        margin-bottom: $token-spacers-s;
        .z-checkbox__text {
            font-size: 13px !important;
        }
    }
}
</style>
