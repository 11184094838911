/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map_delivery/airplane': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<g clip-path="url(#clip0_4943_38623)" _stroke="#004C97"><path pid="0" d="M18 35.535c9.684 0 17.534-7.85 17.534-17.534S27.684.467 18 .467.466 8.317.466 18 8.316 35.535 18 35.535z" _fill="#D1D3D4" stroke-width="1.2" stroke-miterlimit="10"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.867 22.951v-3.179l-8.133.311v-2.422l8.133-2.887V9.69c0-.865.708-1.572 1.572-1.572.864 0 1.572.707 1.572 1.572v5.085l8.133 2.887v2.422l-8.133-.31v3.178l4.118 2.635v1.416l-4.836-1.562h-1.708l-4.837 1.562v-1.416l4.119-2.634h0z" _fill="#fff" stroke-width="1.34"/></g><defs><clipPath id="clip0_4943_38623"><path pid="2" _fill="#fff" d="M0 0h36v36H0z"/></clipPath></defs>'
  }
})
