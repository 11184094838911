<template>
    <section class="section-news">
        <section-title :text="text.title" />
        <div class="section-news__container row u-collapse--top">
            <div
                class="section-news__announce-wrapper col-default-4 col-v-tablet-12"
                v-if="getFirstNews">
                <announce
                    :image="getFirstNews.previewPicture"
                    :date="getFirstNews.activeFrom"
                    class="section-news__announce" />
                <z-link
                    :href="getFirstNews.detailPageUrl"
                    theme="dark"
                    :underlined="false"
                    class="section-news__announce-link">
                    <span>
                        {{ getFirstNews.name }}
                    </span>
                </z-link>
            </div>
            <div
                class="section-news__list-wrapper col-default-5 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                <news-list
                    class="section-news__list"
                    :items="getModifiedNews"
                    :device-with-inline-date="['mobile', 'v-tablet', 'tablet']">
                    <template v-if="lang === 'ru'">&nbsp;г.</template>
                </news-list>

                <div>
                    <z-button tag="a" kind="text" size="l" class="u-top-margin--l" href="/news-and-media/press-releases-and-news/?subjects[]=116">
                        <template v-slot:left>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="16"
                                height="16"
                            ></z-icon>
                        </template>

                        <span v-html="text.link"></span>
                    </z-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
import SectionTitle from '../components/SectionTitle.vue'

export default {
    name: 'section-news',
    components: {
        SectionTitle
    },
    data() {
        return {
            lang: this.$root.lang,
            news: this.$root.app.components.innovations.news,
            text: {
                title: localize({
                    ru: 'Новости',
                    en: 'News'
                }),
                link: localize({
                    ru: 'Все новости',
                    en: 'All news'
                })
            }
        }
    },
    computed: {
        getModifiedNews() {
            const news = this.news.map((el) => {
                el.date = el.activeFrom
                el.link = el.detailPageUrl
                return el
            })

            news.shift()

            return news
        },
        getFirstNews() {
            if (this.news && this.news.length) {
                return this.news[0]
            }
        }
    }
}
</script>

<style lang="scss">
.section-news {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(tablet) {
            .news-list__item {
                flex-direction: column;
            }
            .news-list__item-date {
                margin-bottom: 8px;
            }
        }
    }

    &__announce {
        @include margin-level(bottom, M);

        .date-block {
            margin-bottom: -36px;

            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }
    }

    &__announce-wrapper {
        margin-top: 0 !important;
    }

    &__announce-link {
        font-weight: 600;
        line-height: 120%;
        @include typo-level(3XL);
        color: $token-colors-gray-60;

        &:hover {
            color: $token-colors-blue;
        }
    }

    &__list-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0 !important;

        @include breakpoint(v-tablet) {
            @include margin-level(top, L, false, true);
            align-items: flex-start;
        }
    }

    &__list {
        .news-list__item {
            align-items: center;

            &:not(:first-child) {
                @include margin-level(top, 2XL);

                @include breakpoint(laptop) {
                    @include margin-level(top, L);
                }
                @include breakpoint(v-tablet) {
                    @include margin-level(top, M);
                }
            }

            @include breakpoint(tablet) {
                align-items: flex-start;
            }
        }

        .news-list__item-title {
            font-weight: 600;
            line-height: 140%;
            color: $token-colors-gray-60;
            @include typo-level(L);

            @include breakpoint(v-tablet) {
                font-weight: 400;
                font-size: 16px;
            }
        }
    }

    &__list-link {
        @include margin-level(top, 2XL);

        @include breakpoint(v-tablet) {
            @include margin-level(top, L);
        }
    }
}
</style>
