<template>
    <div class="page sustainability-page">
        <section class="sustainability-page__ecology">
            <ecology />
        </section>
        <section class="u-top-margin--2xl">
            <div class="container">
                <banner
                    :url="lang === 'ru' ? 'https://life.nornickel.ru/' : 'https://life.nornickel.com/'"
                    :link-text="text.banner.link"
                    img-src="/images/biodiversity/banner-fox.jpg"
                    img-src-tab="/images/biodiversity/banner-fox-tab.jpg"
                    img-src-mob="/images/biodiversity/banner-fox-mob.jpg"
                    >
                    <template #title>
                        <z-caption
                            size="3xl"
                            tag="h1"
                            decor-left
                            decor-right
                            class="gray-58-text">
                            <span v-html="text.banner.title"></span>
                        </z-caption>
                    </template>
                    <template #content>
                        <p class="text-size-2xl u-normal u-line-height--one-three" v-html="text.banner.text"></p>
                    </template>
                </banner>
            </div>
        </section>
        <section class="sustainability-page__president-cite">
            <div class="container">
                <z-blockquote
                    :author='{
                        name: text.blockquote.name,
                        info: text.blockquote.position
                    }'
                    :text="text.blockquote.text"
                    horizontal
                >
                    <template v-slot:avatar>
                        <z-avatar
                            photo="/images/company/governance/board-of-management/potanin_obr.jpg"
                            :name="text.blockquote.name"
                        />
                    </template>
                </z-blockquote>
            </div>
        </section>
        <section class="sustainability-page__results-and-goals">
            <results-and-goals />
        </section>
        <section class="sustainability-page__documents-and-news">
            <documents-and-news
                reports-link="/investors/reports-and-results/annual-reports/"
                presentations-link="/investors/presentations-and-briefings/#tabs-esg-presentation-2022"
                policies-link="/sustainability/governance/esg-policies/"
                news-link="/investors/news/?sources[]=115"
            />
        </section>
        <section class="sustainability-page__ratings-and-prizes">
            <ratings-and-prizes />
        </section>
        <section class="sustainability-page__projects">
            <slot name="projects-slider" />
        </section>

        <section class="sustainability-page__contacts-and-events">
            <div class="container">
                <div class="row">
                    <div class="col-default-7 col-tablet-12">
                        <div class="sustainability-page__contacts">
                            <slot name="contacts" />
                        </div>
                    </div>
                    <div class="col-default-5 col-tablet-12" v-if="events && events.length">
                        <div class="sustainability-page__events">
                            <z-caption
                                tag="h2"
                                size="3xl"
                                decor-right
                                class="u-bottom-margin--m"
                                href="/investors/events/"
                            >
                                {{ text.eventsTitle }}
                            </z-caption>

                            <events-list
                                class="u-bottom-margin--m"
                                theme="aside"
                                :items="events"
                            ></events-list>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import Ecology from './components/Ecology.vue'
import ResultsAndGoals from './components/ResultsAndGoals.vue'
import RatingsAndPrizes from './components/RatingsAndPrizes.vue'
import DocumentsAndNews from './components/DocumentsAndNews.vue'

export default {
    name: 'sustainability-page',
    components: {
        Ecology,
        ResultsAndGoals,
        RatingsAndPrizes,
        DocumentsAndNews
    },
    data () {
        return {
            lang: this.$root.lang,
            events: [],
            text: {
                blockquote: {
                    name: localize({
                        'ru': 'Владимир Потанин',
                        'en': 'Vladimir Potanin'
                    }),
                    position: localize({
                        'ru': 'Президент, председатель правления ПАО &laquo;ГМК &laquo;Норильский никель&raquo;',
                        'en': 'President, Chairman of&nbsp;the Management Board of&nbsp;PJSC MMC Norilsk Nickel'
                    }),
                    text: localize({
                        'ru': '&laquo;Устойчивое развитие для нас &minus; это философия бизнеса, мировоззрение управленческих команд, корпоративная культура и&nbsp;долгосрочная ценностная платформа&raquo;',
                        'en': '"For&nbsp;us, sustainable development is&nbsp;a&nbsp;business philosophy, a&nbsp;management mindset, a&nbsp;corporate culture and a&nbsp;platform to&nbsp;create long-term value for our stakeholders"'
                    })
                },
                eventsTitle: localize({
                    ru: 'Календарь',
                    en: 'Calendar'
                }),
                banner: {
                    link: localize({
                        ru: 'Смотреть на сайте',
                        en: 'Go to website'
                    }),
                    title: localize({
                        ru: 'Как &laquo;Норникель&raquo; сохраняет экосистему?',
                        en: 'How does “Nornickel” preserve the ecosystem?'
                    }),
                    text: localize({
                        ru: '&laquo;Норникель&raquo; представляет уникальный веб-проект, посвящённый биоразнообразию Кольского полуострова, Таймыра и&nbsp;Забайкальского края.',
                        en: '“Nornickel” presents a&nbsp;unique web project dedicated to&nbsp;the biodiversity of&nbsp;the Kola Peninsula, Taymyr and Trans-Baikal Territory.'
                    })
                }

            }
        }
    },
    created () {
        let events = this.$root.app.components['sustainability-page'].events
        if (events && events.length) {
            this.events = events.map((obj, i) => {
                return {
                    date: {
                        start: obj.dateFrom.timestamp,
                        end: obj.dateTo.timestamp
                    },
                    link: obj.detailLink.value,
                    name: obj.name
                }
            })
        }
    }
}
</script>

<style lang="scss">
.sustainability-page {
    $parent: &;

    &__ecology {
        @include padding-level(top, 2XL);
        @include padding-level(bottom, 2XL);

        background-image: url('/images/sustainability/bg_1.jpg');
        background-size: cover;
        background-position: center;
    }

    &__president-cite {
        @include margin-level(top, 4XL);
        @include margin-level(bottom, 3XL);
    }

    &__results-and-goals {
        .z-tabs-content {
            padding-left: 0;
            @include margin-level(bottom, XL);
        }
    }

    &__ratings-and-prizes {
        @include margin-level(top, XL);
        @include margin-level(bottom, XL);
    }

    &__documents-and-news {
        @include padding-level(bottom, 2XL);
        background: linear-gradient(112.26deg, #F4F6F9 8.89%, #E6E7EE 62.24%), #F5F5F5;
    }

    &__projects {
        @include breakpoint(mobile) {
            .slider {
                margin: 0 !important;
            }
            .slide-content .z-button {
                width: auto;
            }
        }
    }

    &__contacts-and-events {
        @include padding-level(top, 2XL);
    }

    &__events {
        @include padding-level(left, 2XL);

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

    .slide-content {
        min-height: 880px;
    }
}
</style>
