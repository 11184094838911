<template>
    <a
        :href="element.link"
        :target="element.target"
        class="bio-item"
        @click="handlerTrackGoal(goal)">
        <div class="bio-item__img-container">
            <img
                class="bio-item__img"
                :src="element.img"
                alt=""/>
            <div class="bio-item__icon-container">
                <z-icon
                    name="arrow-long"
                    width="24"
                    height="24"
                    dir="right"/>
            </div>
        </div>
        <p
            class="bio-item__title"
            v-html="element.title"></p>
        <div class="bio-item__content">
            <p
                class="bio-item__title-small"
                v-html="element.title"></p>
            <p
                class="bio-item__text"
                v-html="element.text"></p>
        </div>
    </a>
</template>
<script >
import { trackGoal } from '@/utils/analytics'
export default {
    name: 'bio-item',
    props: {
        element: Object,
        goal: String
    },
    methods: {
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss">
$bio-transition: 0.4s ease-in-out;
.bio-item {
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    color: $token-colors-gray-60;
    position: relative;
    transition: margin $bio-transition 0.3s;
    $parent: &;

    @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }

    @include breakpoint(v-tablet) {
        flex-direction: row;
        gap: 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        gap: 24px;
    }

    &:hover {
        // margin-bottom: -56px;
        transition: margin $bio-transition;

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }

        #{$parent}__title {
            opacity: 0;
            // transition: opacity $bio-transition;
        }

        #{$parent}__content {
            opacity: 1;
            // transition: opacity $bio-transition 0.6s;
        }

        #{$parent}__img-container {
            transform: scale(1.1);
        }

        #{$parent}__icon-container {
            background-color: $token-colors-navy-blue;
            width: 54px;
            height: 54px;
            top: 13px;
            transform: translate(0);

            @include breakpoint(laptop) {
                width: 34px;
                height: 34px;
                top: 13px;
            }
        }
    }

    &__img-container {
        width: 160px;
        height: 160px;
        @include margin-level(right, M);
        position: relative;
        flex-shrink: 0;
        transition: transform $bio-transition;

        @include breakpoint(tablet) {
            width: 146px;
            height: 146px;
        }

        @include breakpoint(mobile) {
            margin-right: 0;
            align-self: center;
        }
    }

    &__img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }

    &__icon-container {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        width: 36px;
        height: 36px;
        border: 1px solid $token-colors-white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $token-colors-white;
        background-color: $token-colors-blue;
        transition: all $bio-transition;
        will-change: transform, top;

        .z-icon path {
            fill: $token-colors-white;
        }

        @include breakpoint(tablet) {
            width: 34px;
            height: 34px;
            top: 13px;
            transform: translate(0);
        }
    }

    &__title {
        opacity: 1;
        font-size: 85px;
        font-weight: 250;
        line-height: 120%;
        transition: opacity 0.4s ease-in-out;
        margin-bottom: 0;
        align-self: center;

        @include breakpoint(laptop) {
            font-size: 60px;
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &__title-small {
        color: $token-colors-blue;
        @include typo-level(3XL);
        font-weight: 400;
        line-height: 100%;
        @include margin-level(bottom, XS);
        background-repeat: no-repeat;
        background-image: linear-gradient(currentColor, currentColor);
        background-size: 0% 1px;
        background-position: 0% 100%;
        width: fit-content;
        transition: background-size $bio-transition;

        &:hover {
            background-size: 100% 1px;
            transition: background-size $bio-transition;
        }

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    &__content {
        position: absolute;
        left: 230px;
        opacity: 0;
        transition: opacity $bio-transition;

        @include breakpoint(laptop) {
            left: 190px;
        }

        @include breakpoint(tablet) {
            opacity: 1;
            position: static;
            width: 100%;
        }
    }

    &__text {
        @include typo-level(S);
        font-weight: 400;
        line-height: 130%;
    }
}
</style>
