<template>
    <button
        v-on="$listeners"
        :class="[
            'custom-buttom',
            {'active': isActive}
    ]">
    <span class="custom-buttom__text">
        <slot></slot>
    </span>
    </button>
</template>

<script>
export default {
    name: 'custom-buttom',
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    }

}
</script>

<style lang="scss">
.custom-buttom {
    font-size: 22px;
    line-height: 130%;
    letter-spacing: -0.12px;
    color: $token-colors-blue;
    background: #FFF;
    border: 1px solid #0290F0;
    border-radius: 40px 40px 40px 0px;
    text-align: center;
    text-decoration: underline;
    padding: 16px 40px;
    position: relative;
    transition: all 0.6s ease-in;
    overflow: hidden;

    @include breakpoint(mobile) {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        text-decoration: none;
    }

    &:before {
        content: '';
        width: 0;
        height: 0;
        background-color: $token-colors-blue;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        transition: all 0.3s ease-in;
        opacity: 0;
        z-index: 1;
    }

    &:not(.active) {
        &:hover,
        &:focus {
            color: $token-colors-white;

            &:before {
                width: 120%;
                padding-bottom: 100%;
                opacity: 1;
            }
        }
    }

    &.active {
        color: $token-colors-white;
        background: $token-colors-navy-blue;
    }

    &__text {
        position: relative;
        z-index: 2;
    }
}
</style>
