<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            ref="modal"
            tabindex="0"
            v-show="active"
            :class="['z-modal', classObject]"
        >
            <div
                class="z-modal__overlay"
                :style="{ backgroundColor: overlay }"
                @click="closeModal({ id: id })"
            ></div>
            <div
                v-if="active"
                ref="container"
                class="z-modal__container"
            >
                <button
                    v-if="!hasSlotData"
                    role="button"
                    @click="closeModal({id: id})"
                    :title="text.close"
                    class="z-modal__close"
                    ref="close-btn"
                >
                    <z-icon
                        name="close"
                        width="32"
                        height="32"
                    />
                </button>
                <component
                    v-if="!hasSlotData"
                    class="z-modal__component"
                    :is="currentComponent"
                    :data="currentSlide"
                    :offset-top="offsetTop"
                    :offset-bottom="offsetBottom"
                    :download="download"
                    :theme="theme"
                    ref="content"
                />
                <modal-html v-if="hasSlotData" :has-slot-data="hasSlotData">
                    <button
                        role="button"
                        @click="closeModal({id: id})"
                        :title="text.close"
                        class="z-modal__close z-modal__close--html"
                        ref="close-btn"
                    >
                        <z-icon
                            name="close"
                            width="32"
                            height="32"
                        />
                    </button>
                    <slot/>
                </modal-html>

                <div class="z-modal__pagination" v-if="pagination && data && data.length > 1 ">
                    <span
                        :class="[
                            'z-modal__pagination-item',
                            { 'z-modal__pagination-item--active' : item === currentSlide }
                        ]"
                        v-for="(item, index) in data"
                        :key="index"
                        @click="changeSlide(item)"
                    >
                    </span>
                </div>
                <div class="z-modal__navigation" v-if="!hasSlotData && data && data.length > 1">
                    <button
                        class="z-modal__arrow z-modal__arrow--prev"
                        :class="{ 'is-disabled' : !isPrev('prev') }"
                        :title="text.prev"
                        @click="changeSlide('prev')"
                        role="button"
                        ref="nav-prev"
                    >
                        <z-icon
                            name="round-arrow-folder/arrow"
                            width="24"
                            height="24"
                            dir="left"
                        ></z-icon>
                    </button>
                    <button
                        class="z-modal__arrow z-modal__arrow--next"
                        :class="{ 'is-disabled' : !isNext('next') }"
                        :title="text.next"
                        @click="changeSlide('next')"
                        role="button"
                        ref="nav-next"
                    >
                        <z-icon
                            name="round-arrow-folder/arrow"
                            dir="right"
                            width="24"
                            height="24"
                        ></z-icon>
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import { mixinDevice } from '@/utils/mixin'
import Image from './components/Image.vue'
import Video from './components/Video.vue'
import Html from './components/Html.vue'
require('hammerjs')

export default {
    name: 'z-modal',
    mixins: [mixinDevice],
    props: {
        id: {
            type: String,
            required: true
        },
        data: Array,
        overlay: {
            type: String,
            default: 'rgba(0, 0, 0, 0.5)'
        },
        theme: String,
        download: {
            type: Boolean,
            default: false
        },
        pagination: {
            type: Boolean,
            default: false
        },
        bodyLock: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            scrollY: 0,
            active: false,
            lang: this.$root.lang,
            currentSlide: undefined,
            offsetTop: 0,
            offsetBottom: 0,
            text: {
                next: this.$root.lang === 'ru' ? 'Следующий' : 'Next',
                close: this.$root.lang === 'ru' ? 'Закрыть' : 'Close',
                prev: this.$root.lang === 'ru' ? 'Предыдущий' : 'Previous'
            }
        }
    },
    components: {
        'modal-image': Image,
        'modal-video': Video,
        'modal-html': Html
    },
    mounted () {
        this.$root.$bus.$on('open-modal', data => this.openModal(data))
        this.$root.$bus.$on('close-modal', data => this.closeModal(data))

        if (this.data && this.data.length) {
            this.currentSlide = this.data[0]
        }

        document.addEventListener('keydown', event => {
            if (event.keyCode === 27 && this.active) {
                this.closeModal({id: this.id})
            }

            if (event.keyCode === 37 && this.active) {
                this.changeSlide('prev')
            }

            if (event.keyCode === 39 && this.active) {
                this.changeSlide('next')
            }
        }, false)

        this.$nextTick(() => {
            if (this.isMobile() || this.isTablet()) {
                if (!this.$refs.modal) return
                // eslint-disable-next-line
                var mc = new Hammer(this.$refs.modal)

                mc.on('swipe', function (ev) {
                    if (!self.changeSlide) return

                    if (ev.direction === 4) {
                        self.changeSlide('prev')
                    } else if (ev.direction === 2) {
                        self.changeSlide('next')
                    }
                })
            }
        })

        this.$nextTick(() => this.setOffsetValue())
        window.addEventListener('resize', this.setOffsetValue)
    },
    beforeDestroy () {
        this.$root.$bus.$off('open-modal', data => this.openModal(data))
        this.$root.$bus.$off('close-modal', data => this.openModal(data))

        window.removeEventListener('resize', this.setOffsetValue)
    },
    computed: {
        classObject () {
            let arrClass = []

            if (this.theme) {
                arrClass.push(`z-modal--theme-${this.theme}`)
            }

            if (this.data && this.data.length > 1) {
                arrClass.push(`z-modal--slider`)
            }

            return arrClass
        },
        currentComponent () {
            if (this.currentSlide && this.currentSlide.type) {
                if (this.currentSlide.type === 'photo') {
                    return 'modal-image'
                }
                return `modal-${this.currentSlide.type}`
            }

            return false
        },
        hasSlotData () {
            return this.$slots.default !== undefined
        }
    },
    updated () {
        this.$nextTick(() => this.setOffsetValue())
    },
    methods: {
        openModal (data) {
            if (data.id !== this.id) return

            this.active = true

            if (this.bodyLock) {
                this.disableScroll()
            }

            this.$nextTick(() => {
                if (this.$refs.modal) this.$refs.modal.focus()
            })

            if (this.data && data.index !== undefined) {
                this.currentSlide = this.data[data.index]
            }

            const modalContent = this.$refs.content

            if (modalContent && modalContent.update && typeof modalContent.update === 'function') {
                modalContent.update()
            }

            this.$emit('open')
        },
        closeModal (data) {
            if (data.id !== this.id) return
            this.active = false

            if (this.bodyLock) {
                this.enableScroll()
            }

            this.$emit('close')
        },
        disableScroll () {
            if (document.body.style.position !== 'fixed') {
                this.scrollY = window.pageYOffset
                document.body.style.position = 'fixed'
                document.body.style.top = `-${this.scrollY}px`
                document.body.style.width = '100%'
                document.querySelector('.header').style.zIndex = 2
                document.querySelector('main.main').style.zIndex = 'inherit'
            }
        },
        enableScroll () {
            document.body.style.position = ''
            document.body.style.top = ''
            document.body.style.width = ''
            document.querySelector('.header').style.zIndex = 999
            document.querySelector('main.main').style.zIndex = 2
            window.scroll(0, this.scrollY)
        },
        changeSlide (type) {
            if (!this.data) return

            const currentIndex = this.data.indexOf(this.currentSlide)

            if (this.isPrev(type)) {
                this.currentSlide = this.data[currentIndex - 1]
                this.$emit('slide-change', { type: 'prev', slide: this.currentSlide })
            }

            if (this.isNext(type)) {
                this.currentSlide = this.data[currentIndex + 1]
                this.$emit('slide-change', { type: 'next', slide: this.currentSlide })
            }

            if (typeof type === 'object') {
                this.currentSlide = type
            }
        },
        isPrev (type) {
            const currentIndex = this.data.indexOf(this.currentSlide)
            return type === 'prev' && currentIndex !== 0
        },
        isNext (type) {
            const currentIndex = this.data.indexOf(this.currentSlide)
            return type === 'next' && currentIndex < this.data.length - 1
        },
        setOffsetValue () {
            const modal = this.$refs.modal
            const container = this.$refs.container
            let offsetTop = 0
            let offsetBottom = 0

            modal ? offsetTop += parseFloat(getComputedStyle(modal).paddingTop) : offsetTop += 0
            modal ? offsetBottom += parseFloat(getComputedStyle(modal).paddingBottom) : offsetBottom += 0
            container ? offsetTop += parseFloat(getComputedStyle(container).paddingTop) : offsetTop += 0
            container ? offsetBottom += parseFloat(getComputedStyle(container).paddingBottom) : offsetBottom += 0

            this.offsetTop = offsetTop
            this.offsetBottom = offsetBottom
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
