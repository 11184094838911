<template>
    <div class="biodiversity-form">
        <z-button
            type="button"
            kind="secondary"
            @click="onClick"
        >
            {{ text.buttonTrigger }}
        </z-button>

        <z-modal class="biodiversity-form__modal" id="biodiversityForm">
            <z-caption tag="h3" size="2xl" icon="message">
                {{ text.title }}
            </z-caption>
            <form @submit.prevent="submitForm">
                <div class="row">
                    <div class="col-default-12 col-tablet-12">
                        <z-input
                            v-model="formData.name"
                            type="text"
                            ref="name"
                            name="name"
                            :title="text.name"
                            class="contacts-form__input"
                        ></z-input>

                        <z-caption class="contacts-form__select-title" tag="p" size="s">
                            {{ text.message }}
                        </z-caption>
                        <z-textarea
                            v-model="formData.message"
                            required
                            name="message"
                            ref="message"
                            class="contacts-form__input"
                        ></z-textarea>

                        <p class="contacts-form__text">{{ text.notificationCaptchaEmpty }}</p>
                        <div class="form-captcha">
                            <div class="form-captcha__wrapper">
                                <div class="form-captcha__captcha">
                                    <img
                                        class="form-captcha__img"
                                        v-if="isUpdatedCapcha"
                                        :src="'/api/captcha/?' + capchaSalt"
                                        width="100"
                                        height="50"
                                        alt=""
                                    />
                                    <div v-else class="form-captcha__img"></div>

                                    <z-button
                                        class="form-captcha__reset-btn"
                                        type="button"
                                        rounded
                                        kind="secondary"
                                        @click="updateCapcha"
                                    >
                                        <z-icon name="refresh" width="24" height="24"></z-icon>
                                    </z-button>
                                </div>

                                <z-input
                                    v-model="captcha"
                                    type="text"
                                    name="captcha"
                                    ref="captcha"
                                    required
                                    class="form-captcha__input"
                                ></z-input>
                            </div>
                        </div>
                    </div>

                    <div class="col-default-12 col-tablet-12">
                        <div>
                            <z-checkbox
                                name="policy"
                                ref="policy"
                                v-model="isChecked"
                                class="contacts-form__checkbox"
                                required
                                :data="[{ value: '1' }]"
                            >
                                <template #text>
                                    <z-caption tag="p" weight="400" size="xs">
                                        {{ text.policy }}
                                    </z-caption>
                                </template>
                            </z-checkbox>
                        </div>

                        <z-button>
                            {{ text.button }}
                        </z-button>
                    </div>
                </div>
            </form>
        </z-modal>
    </div>
</template>

<script>
import { sendBiodiversityForm } from '@/api/biodiversity-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { localize } from '@/utils/i18n'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'biodiversity-form',
    data () {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                name: '',
                message: '',
                policy: null
            },
            text: {
                title: localize({
                    ru: 'Отправьте ваш вопрос',
                    en: 'Send your question'
                }),
                name: localize({
                    ru: 'Имя',
                    en: 'Name'
                }),
                buttonTrigger: localize({
                    ru: 'Задать вопрос',
                    en: 'Ask a question'
                }),
                button: localize({
                    ru: 'Отправить',
                    en: 'Send'
                }),
                notificationSuccess: localize({
                    ru: 'Отправка успешна',
                    en: 'Sending successful'
                }),
                notificationCaptchaError: localize({
                    ru: 'Неверный код капчи. Попробуйте снова.',
                    en: 'Invalid captcha code. Try again.'
                }),
                notificationCaptchaEmpty: localize({
                    ru: 'Введите код с картинки*',
                    en: 'Enter the code from the image*'
                }),
                message: localize({
                    ru: 'Ваш вопрос*',
                    en: 'Question*'
                }),
                policy: localize({
                    ru: 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие ПАО «ГМК Норильский никель» на обработку моих персональных данных, указанных при заполнении формы обратной связи на сайте www.nornickel.ru',
                    en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC MMC Norilsk Nickel'
                }),
                notificationError: localize({
                    ru: 'Ошибка отправки',
                    en: 'Send error'
                }),
                notificationRequiredTextError: localize({
                    ru: 'Заполните все обязательные поля',
                    en: 'Fill in all required fields'
                })
            },
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true
        }
    },
    methods: {
        onClick () {
            this.$root.$bus.$emit('open-modal', {id: 'biodiversityForm'})
        },
        validateRequiredInputs () {
            const requiredFields = ['message', 'policy']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }

                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendBiodiversityForm(data)
                        if (formResponse.status === 'success') {
                            this.$root.$bus.$emit('close-modal', {id: 'biodiversityForm'})
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })
                            this.formData.name = ''
                            this.formData.text = ''
                            this.isChecked = []
                            this.captcha = ''
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.biodiversity-form {
    &__form-button {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: transparent;
        background-image: url('/images/contacts/round_mail.svg');
        background-repeat: no-repeat;
        border: none;
        outline: none;
        position: fixed;
        bottom: 5%;
        right: 5%;
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);
        z-index: 1;

        &:hover {
            background-image: url('/images/contacts/round_mail_hover.svg');
        }
    }

    .z-textarea {
        height: 180px;
    }

    &__form-title {
        margin-left: 12px;
        margin-right: 36px;

        @media screen and(min-width: 1679px) {
            margin-left: 50px;
        }

        @media screen and(max-width: 1023px) {
            margin-left: 2px;
        }

        @media screen and(max-width: 598px) {
            margin-left: 7px;
            font-size: 18px;
        }
    }
}

.contacts-form-wrapper {
    &__form-button {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: transparent;
        background-image: url('/images/contacts/round_mail.svg');
        background-repeat: no-repeat;
        border: none;
        outline: none;
        position: fixed;
        bottom: 5%;
        right: 5%;
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);

        &:hover {
            background-image: url('/images/contacts/round_mail_hover.svg');
        }
    }

    &__form-title {
        margin-left: 12px;
        margin-right: 36px;

        @media screen and(min-width: 1679px) {
            margin-left: 50px;
        }

        @media screen and(max-width: 1023px) {
            margin-left: 2px;
        }

        @media screen and(max-width: 598px) {
            margin-left: 7px;
            font-size: 18px;
        }
    }

    &__form {
        max-width: 880px;
    }
}
</style>
