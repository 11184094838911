/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Rectangle 9634': {
    width: 63,
    height: 51,
    viewBox: '0 0 63 51',
    data: '<path pid="0" _fill="#D9D9D9" d="M0 0h63v50.45H0z"/>'
  }
})
