/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map_delivery/truck': {
    width: 25,
    height: 19,
    viewBox: '0 0 25 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 .593C0 .266.266 0 .593 0H13.23a2.277 2.277 0 012.275 2.275v2.45a.594.594 0 01.165-.023h5.322c.756 0 1.43.398 1.793 1.064l.003.004 2.049 3.84.07.13V16.09h-2.219a3.44 3.44 0 01-6.798-.002h-.929a.622.622 0 01-.099 0H9.383a3.44 3.44 0 01-6.798 0H.593a.593.593 0 010-1.186h2.014a3.44 3.44 0 016.753 0h4.959V2.275c0-.6-.49-1.089-1.09-1.089H.594A.593.593 0 010 .593zm15.505 14.309V5.864a.593.593 0 00.165.024h2.767v4.617h5.283v4.398h-1.054a3.44 3.44 0 00-6.754-.001h-.407zm7.832-5.583l-1.594-2.988a.834.834 0 00-.75-.443h-1.37v3.431h3.714zM3.75 15.266a2.254 2.254 0 11-.012.483.591.591 0 00.012-.483zm15.539-1.96a2.254 2.254 0 100 4.508 2.254 2.254 0 000-4.508zM0 4.584c0-.328.266-.593.593-.593h9.143a.593.593 0 010 1.186H.593A.593.593 0 010 4.584zM.593 6.9a.593.593 0 100 1.186h9.143a.593.593 0 000-1.186H.593z" _fill="#004C97"/>'
  }
})
