/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_education': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><g clip-path="url(#clip0_10000_63309)"><path pid="1" d="M31.102 15.338l-11.25-6a.75.75 0 00-.704 0l-11.25 6a.75.75 0 000 1.324L10.5 18.05v4.54c0 .368.135.724.38.998 1.229 1.368 3.98 3.662 8.62 3.662a12.188 12.188 0 004.5-.821v3.07a.75.75 0 101.5 0v-3.795a10.84 10.84 0 002.62-2.116c.245-.274.38-.63.38-.998v-4.54l2.602-1.388a.75.75 0 000-1.324zM19.5 25.75c-4.057 0-6.443-1.982-7.5-3.16v-3.74l7.148 3.812a.751.751 0 00.704 0L24 20.45v4.345c-1.181.55-2.67.955-4.5.955zm7.5-3.164c-.45.499-.953.947-1.5 1.336V19.65l1.5-.8v3.736zm-1.875-4.435l-.02-.013-5.25-2.8a.75.75 0 00-.706 1.324L23.531 19 19.5 21.15 9.844 16l9.656-5.15L29.156 16l-4.031 2.15z" _fill="#fff"/></g><defs><clipPath id="clip0_10000_63309"><path pid="2" _fill="#fff" transform="translate(7.5 7)" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
