<template>
    <div class="ir-highlights-page container">
        <layout>
            <template v-slot:top>
                <stocks></stocks>
            </template>
            <template v-slot:content>
                <fin-results
                    class="u-top-padding--xl u-right-top-round--m u-right-bottom-round--m"
                    :financial="financial"
                    :operation="operation"
                />

                <presentations
                    class="container u-right-padding--no u-top-padding--xl u-bottom-padding--s"
                    v-if="presentations && presentations.length"
                    :data="presentations"
                />

                <section class="ir-highlights-page__analytics container">
                    <div class="u-right-padding--xl">
                        <z-caption
                            href="/investors/reports-and-results/commodity-research/"
                            size="3xl"
                            decor-right
                        >
                            <vue-raw :raw="text.analitycsReports"/>
                        </z-caption>
                    </div>
                    <div class="u-top-padding--xl u-bottom-padding--m" v-if="commodityResearch">
                        <div class="row hide-me--mobile">
                            <div class="col-default-4 col-desktop-5 col-tablet-12" v-for="(item, index) in commodityResearch" :key="index">
                                <result-book
                                    cover="/images/book-cover-commodity.png"
                                    :title="item.name"
                                    :date="item.activeFrom"
                                    :size="item.fileReport.size"
                                    is-emit-event
                                    @linkClick.prevent="openModalWithForm(item.fileReport.link, item.fileId)"
                                ></result-book>
                            </div>
                        </div>
                        <div class="show-me--mobile hide-me--default">
                            <result-book
                                v-for="(item, index) in commodityResearch" :key="index"
                                cover="/images/book-cover-commodity.png"
                                :title="item.name"
                                :date="item.activeFrom"
                                :size="item.fileReport.size"
                                is-emit-event
                                @linkClick.prevent="openModalWithForm(item.fileReport.link, item.fileId)"
                            ></result-book>
                        </div>
                    </div>
                </section>

                <section
                    class="container u-right-padding--no u-top-padding--xl u-bottom-padding--s"
                >
                    <div class="u-top-padding--xs">
                        <annual-report-block
                            col-class="col-default-3 col-v-tablet-6 col-mobile-12"
                            :reports-list="reportsList"
                        >
                            <template #header>
                                <z-caption
                                    href="/investors/reports-and-results/annual-reports/"
                                    tag="a"
                                    size="3xl"
                                    decor-right
                                >
                                    <vue-raw :raw="text.annualReports"/>
                                </z-caption>
                            </template>
                        </annual-report-block>
                    </div>
                </section>

                <slot name="bottom" />
            </template>
            <template #aside>
                <div class="ir-highlights-page__aside">
                    <div class="u-bottom-margin--xl" v-if="strategyDay && strategyDay[0]">
                        <announce
                            :link="strategyDay[0].detailPageUrl"
                            :image="strategyDay[0].imageInvestors.link"
                            :caption="`${text.cmd} ${strategyDay[0].date.timestamp ? new Date(strategyDay[0].date.timestamp * 1000).getFullYear() : ''}`"
                            :date="strategyDay[0].pastEvent.value ? null : strategyDay[0].date.timestamp"
                        />
                    </div>

                    <div class="u-bottom-margin--xl" v-if="news && news.length">
                        <z-caption tag="h2" decor-left decor-right size="m" weight="700" uppercase>
                            {{ text.newsTitle }}
                        </z-caption>

                        <news-list
                            class="u-bottom-margin--m"
                            theme="aside"
                            :items="news"
                        ></news-list>

                        <z-button tag="a" kind="text" href="/investors/news/?sources[]=21">
                            <template #left>
                                <z-icon
                                    name="arrow-long"
                                    dir="right"
                                    width="16"
                                    height="16"></z-icon>
                            </template>

                            <span v-html="text.allNews"></span>
                        </z-button>
                    </div>

                    <div class="u-bottom-margin--xl" v-if="events && events.length">
                        <z-caption tag="h2" decor-left decor-right size="m" weight="700" uppercase>
                            {{ text.eventsTitle }}
                        </z-caption>

                        <events-list
                            class="u-bottom-margin--m"
                            theme="aside"
                            :items="events"
                        ></events-list>

                        <z-button tag="a" kind="text" class="u-normal" href="/investors/events/">
                            <template #left>
                                <z-icon
                                    name="arrow-long"
                                    dir="right"
                                    width="16"
                                    height="16"></z-icon>
                            </template>

                            <span v-html="text.allEvents"></span>
                        </z-button>
                    </div>

                    <slot name="aside" />
                </div>
            </template>
        </layout>
        <z-modal class="comodity-research-page__modal" id="slotModal">
            <z-caption
                class="comodity-research-page__form-title"
                tag="h3"
                size="2xl"
                icon="message"
            >
                {{ text.formTitle }}
            </z-caption>
            <commodity-research-form
                class="container comodity-research-page__form"
                @openLink="openLink"
                :fileId="currentFileId"
            ></commodity-research-form>
        </z-modal>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import Reports from './components/Reports.vue'
import Presentations from './components/Presentations.vue'
import FinResults from './components/FinResults.vue'
import Stocks from './components/Stocks.vue'

export default {
    name: 'ir-highlights-page',
    components: {
        Reports,
        Presentations,
        FinResults,
        Stocks
    },
    data () {
        return {
            currentLink: '',
            currentFileId: '',
            presentations: [],
            commodityResearch: [],
            financial: null,
            operation: null,
            strategyDay: [],
            news: [],
            events: [],
            text: {
                formTitle: localize({
                    ru: 'Для ознакомления с аналитическим отчетом просьба ответить на вопросы',
                    en: 'To download our research please complete the form below'
                }),
                analitycsReports: localize({
                    ru: 'Аналитические отчеты по сырьевому рынку',
                    en: 'Commodity research'
                }),
                annualReports: localize({
                    ru: 'Годовые отчеты',
                    en: 'Annual reports'
                }),
                newsTitle: localize({
                    ru: 'Новости',
                    en: 'News'
                }),
                eventsTitle: localize({
                    ru: 'Предстоящие события',
                    en: 'Upcoming events'
                }),
                allNews: localize({
                    ru: 'Все новости',
                    en: 'All news'
                }),
                allEvents: localize({
                    ru: 'Все события',
                    en: 'All events'
                }),
                cmd: localize({
                    ru: 'День стратегии',
                    en: 'Capital Markets Day'
                })
            }
        }
    },
    computed: {
        reportsList () {
            let result = ['ar', 'csr', 'brochure']
            const additionalReports = this.$root?.app?.components?.['annual-reports-block']?.additionalReports
            let climateReport = additionalReports ? additionalReports.find(item => item.type.xmlId === 'climate-report') : null

            if (climateReport) {
                result.push('climate-report')
            } else {
                result.push('white-book')
            }

            return result
        }
    },
    methods: {
        openModalWithForm (reportLink, fileId) {
            this.$root.$bus.$emit('open-modal', { id: 'slotModal', index: 0 })
            this.currentLink = reportLink
            this.currentFileId = fileId
        },
        openLink () {
            window.location.href = this.currentLink
        }
    },
    created () {
        if (this.$root.app.components['ir-highlights-page']) {
            this.commodityResearch =
                this.$root.app.components['ir-highlights-page']['commodity-research'] || []
            this.presentations =
                this.$root.app.components['ir-highlights-page']['presentation-and-briefings'] || []
            this.financial =
                this.$root.app.components['ir-highlights-page']['results-and-reports']?.[
                    'financial-results'
                ]
            this.operation =
                this.$root.app.components['ir-highlights-page']['results-and-reports']?.[
                    'operation-results'
                ]
            // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Operators/Optional_chaining

            this.news = this.$root.app.components['ir-highlights-page']['news'].map((obj, i) => {
                return {
                    date: obj.activeFrom,
                    link: obj.detailPageUrl,
                    name: obj.name
                }
            })

            let events = this.$root.app.components['ir-highlights-page'].events
            if (events && events.length) {
                this.events = events.map((obj, i) => {
                    return {
                        date: {
                            start: obj.dateFrom.timestamp,
                            end: obj.dateTo.timestamp
                        },
                        link: obj.detailLink.value,
                        name: obj.name
                    }
                })
            }

            this.strategyDay = this.$root.app.components['ir-highlights-page']['strategy-day'] || []
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
