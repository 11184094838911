/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_housing_and_urban_environment': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 9.198a1.55 1.55 0 00-1.55 1.55v15.7H9a.8.8 0 000 1.6h21a.8.8 0 100-1.6h-.7v-9.7a1.55 1.55 0 00-1.55-1.55H21.8v-4.45a1.55 1.55 0 00-1.55-1.55h-9zm16.45 7.6v9.65h-5.9v-9.65h5.9zm-7.5-6V26.448h-8.9v-15.65h8.9zm-6.7 2.15a.8.8 0 100 1.6h3a.8.8 0 100-1.6h-3zm.7 6.8a.8.8 0 01.8-.8h3a.8.8 0 010 1.6h-3a.8.8 0 01-.8-.8zm-.7 2.95a.8.8 0 100 1.6h3a.8.8 0 100-1.6h-3zm9.7.8a.8.8 0 01.8-.8h1.5a.8.8 0 010 1.6H24a.8.8 0 01-.8-.8zm.8-4.55a.8.8 0 100 1.6h1.5a.8.8 0 100-1.6H24z" _fill="#fff"/>'
  }
})
