<template>
    <a
        :href="href"
        class="z-link"
        :class="classObject"
        @click="$emit('click', $event)"
        :download="downloadable ? download : false"
        :target="blank && !downloadable ? '_blank' : false">
        <slot
            v-if="hasLeftSlot"
            name="left" />
        <span class="z-link__text">
            <span class="z-link__text-content">
                <slot />
            </span>

            <z-icon
                v-if="external"
                name="external"
                dir="top"
                width="24"
                height="24" />
        </span>
        <slot
            v-if="hasRightSlot"
            name="right" />
    </a>
</template>

<script>
// важно: не форматировать разметку в шаблоне, иначе добавляются лишние пробелы
export default {
    name: 'z-link',
    props: {
        href: {
            type: String,
            required: true,
            default: ''
        },
        size: {
            type: String,
            validator: (prop) => ['xs', 's', 'm', 'l', 'xl'].includes(prop)
        },
        weight: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'blue'
        },
        download: {
            type: String,
            default: ''
        },
        external: Boolean,
        underline: Boolean,
        isIconRounded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasLeftSlot() {
            return !!this.$slots['left']
        },
        hasRightSlot() {
            return !!this.$slots['right']
        },
        classObject() {
            return {
                'z-link--external': this.external,
                'z-link--underline': this.underline,
                [`z-link--size-${this.size}`]: this.size,
                [`z-link--theme-${this.theme}`]: this.theme,
                [`z-link--weight-${this.weight}`]: this.weight,
                [`z-link--has-icon`]: this.hasLeftSlot || this.hasRightSlot || this.external,
                'z-link--icon-rounded': this.isIconRounded
            }
        },
        downloadable() {
            if (!this.download.length) return false

            return Modernizr.adownload
        },
        blank() {
            return this.http || this.external
        },
        http() {
            let http = this.href.match(/^http/g)

            if (http !== null) {
                return http.length
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
