/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'm4': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<g clip-path="url(#clip0_7812_67522)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.057 1.777l-.214-.537-.545.195-7 2.5.061.17H2.055c-.699 0-1.263.562-1.263 1.25v18.102c0 .688.565 1.25 1.263 1.25h1.67l1.26 2.101.335.558.529-.378 3.193-2.28h16.637c.699 0 1.263-.564 1.263-1.251v-6.071a.12.12 0 00.058-.108v-5.757a.12.12 0 00-.058-.107V5.355c0-.687-.564-1.25-1.263-1.25h-1.69l-.932-2.328zm-.361 2.328l-.539-1.346-3.768 1.346h4.307zM6.977 24.707H5.124l.556.927 1.297-.927zm18.765-6.705V23.457c0 .006-.002.01-.003.014a.065.065 0 01-.06.036H2.055a.07.07 0 01-.027-.005.067.067 0 01-.021-.014.053.053 0 01-.012-.016.035.035 0 01-.003-.015V5.355c0-.006.001-.01.003-.014a.054.054 0 01.012-.017.066.066 0 01.048-.019H25.68a.066.066 0 01.06.036.035.035 0 01.003.015v5.441h-8.693c-.351 0-.736.274-.736.724v5.757c0 .44.37.724.736.724h8.693zm.058-1.353l-.058.033v.12h-8.229v-4.805h8.229v.108l.058.034v4.51zm-5.746-2.442a.186.186 0 00-.194.191c0 .11.083.194.194.194.109 0 .191-.08.191-.194a.189.189 0 00-.053-.137.189.189 0 00-.138-.054zm-1.394.191c0-.776.621-1.39 1.394-1.39.775 0 1.391.615 1.391 1.39 0 .773-.615 1.394-1.391 1.394a1.39 1.39 0 01-1.394-1.394z" _fill="#1A1B25"/></g><defs><clipPath id="clip0_7812_67522"><path pid="1" _fill="#fff" d="M0 0h29v29H0z"/></clipPath></defs>'
  }
})
