<template>
    <div :class="classObject" v-if="dataLength">
        <a
            :href="data.prev.link"
            class="page-nav__item page-nav__item--prev"
            v-if="data.prev"
        >
            <span class="page-nav__icon">
                <z-icon
                    :name="['v-tablet', 'mobile'].includes(device) ? 'arrow-long-4' : 'round-arrow-folder/line-m'"
                    width="20"
                    height="20"
                    dir="left"
                />
            </span>
            <span class="page-nav__text" v-html="data.prev.text"></span>
        </a>
        <z-icon
            width="28"
            height="28"
            name="minilogo"
            class="page-nav__logo"
        />
        <a
            :href="data.next.link"
            class="page-nav__item page-nav__item--next"
            v-if="data.next"
        >
            <span class="page-nav__text" v-html="data.next.text"></span>
            <span class="page-nav__icon">
                <z-icon
                    :name="['v-tablet', 'mobile'].includes(device) ? 'arrow-long-4' : 'round-arrow-folder/line-m'"
                    width="20"
                    height="20"
                    dir="right"
                />
            </span>
        </a>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
export default {
    name: 'page-nav',
    mixins: [mixinDevice],
    props: {
        theme: {
            type: String,
            default: 'default',
            validator: prop => ['default', 'white'].includes(prop)
        }
    },
    data () {
        return {
            data: this.$root.app.components['page-nav']
        }
    },
    computed: {
        dataLength () {
            if (
                this.data.next.link.length > 0 ||
                this.data.next.text.length > 0 ||
                this.data.prev.link.length > 0 ||
                this.data.prev.text.length > 0
            ) {
                return true
            } else {
                return false
            }
        },
        classObject () {
            return [
                'page-nav',
                `page-nav--theme-${this.theme}`
            ]
        }
    }
}
</script>

<style lang="scss">
.page-nav {
    $parent: &;

    display: flex;
    gap: 16px;
    align-items: center;

    @include breakpoint (v-tablet) {
        max-width: 290px;
        justify-content: space-between;
        width: 100%;
    }

    &__icon {
        display: flex;
        margin-top: 3px;

        & .z-icon {
            & path {
                fill: $token-colors-gray-30;
            }
        }
    }

    &__text {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.2px;
        @include breakpoint (v-tablet) {
            display: none;
        }
    }

    &__item {
        text-decoration: none;
        color: $token-colors-gray-60;
        transition: color $transition;
        display: flex;
        align-items: center;

        &:hover {
            color: $token-colors-navy-blue;
        }

        &--next {
            #{$parent}__text {
                margin-right: $token-spacers-2-xs;
            }
        }

        &--prev {
            #{$parent}__text {
                margin-left: $token-spacers-2-xs;
            }
        }
    }

    &__logo {
        margin: -2px -3px;
        & path {
            fill: $token-colors-navy-blue;
        }
    }

    &--theme-white {
        #{$parent}__icon {
            path {
                fill: $token-colors-gray-20;
            }
        }

        #{$parent}__text {
            color: $token-colors-white;
        }

        #{$parent}__logo path{
            fill: $token-colors-white;
        }

    }
}
</style>
