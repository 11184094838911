/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-xlsx': {
    width: 46,
    height: 22,
    viewBox: '0 0 46 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.888 16.713h1.8L7.026 10.54l4.392-5.832H9.6L6.054 9.513 2.49 4.707H.672l4.41 5.85-4.68 6.156H2.22l3.834-5.112 3.834 5.112zm3.444 0h7.074v-1.332h-5.58V4.707h-1.494v12.006zm8.456-1.692c.99 1.116 2.52 1.908 4.608 1.908 3.24 0 4.428-1.782 4.428-3.528 0-2.523-2.254-3.135-4.224-3.67-1.529-.415-2.886-.784-2.886-1.964 0-1.134 1.026-1.908 2.466-1.908 1.314 0 2.574.45 3.474 1.44l.9-1.098c-1.008-1.062-2.43-1.674-4.266-1.674-2.358 0-4.122 1.35-4.122 3.33 0 2.316 2.077 2.88 3.986 3.399 1.622.44 3.124.849 3.124 2.271 0 .918-.684 2.07-2.826 2.07-1.692 0-2.988-.828-3.78-1.71l-.882 1.134zm19.565 1.692h1.8L38.49 10.54l4.392-5.832h-1.818l-3.546 4.806-3.564-4.806h-1.818l4.41 5.85-4.68 6.156h1.818l3.834-5.112 3.834 5.112z" _fill="#0077C8"/>'
  }
})
