<template>
    <div class="container mp-news">
        <div class="row u-collapse--top">
            <div class="mp-news__news-wrapper col-default-8 col-tablet-12 u-collapse--top">
                <z-caption
                    tag="h2"
                    size="3xl"
                    decor-left
                    decor-right
                    class="main-page__title mp-news__title"
                >
                    <span data-scroll-heading v-html="text.news.title"></span>
                </z-caption>

                <div
                    class="mp-news__news-container u-flex--row u-align-items--start u-bottom-margin--xl">
                    <div class="mp-news__announce-left">
                        <a
                            v-if="getFirstNews()"
                            :href="getFirstNews().detailPageUrl"
                            class="mp-news__announce-wrapper"
                            data-scroll-translate
                            @click="handlerTrackGoal('main-2screen-announce_news')"
                        >
                            <announce
                                set-image-at-inner
                                :image="getFirstNews().previewPicture"
                                :date="getFirstNews().activeFrom"
                                class="mp-news__announce"

                            />
                            <div class="mp-news__announce-link-wrapper glass-effect">
                                <span
                                    v-if="getFirstNews().newsShortTitle.value"
                                    class="mp-news__announce-link"
                                >
                                    {{ getFirstNews().newsShortTitle.value ? getFirstNews().newsShortTitle.value : getFirstNews().name }}
                                </span>
                            </div>
                        </a>
                        <z-button
                            href="/news-and-media/press-releases-and-news/"
                            tag="a"
                            kind="text"
                            size="l"
                            class="mp-news__all-news--desktop u-top-margin--l"
                            @click="handlerTrackGoal('main-2screen-all_news')">
                            <template v-slot:left>
                                <z-icon
                                    name="arrow-long"
                                    dir="right"
                                    width="16"
                                    height="16"></z-icon>
                            </template>
                            <span v-html="text.news.link"></span>
                        </z-button>
                    </div>

                    <news-list
                        data-scroll-translate
                        class="mp-news__list"
                        :items="preparedNews()"
                        :device-with-inline-date="['mobile', 'v-tablet', 'tablet', 'laptop']"
                        goal-name-list-news="main-2screen-list_news"
                    >
                        <template v-if="lang === 'ru'">&nbsp;г.</template>
                    </news-list>
                    <z-button
                        href="/news-and-media/press-releases-and-news/"
                        tag="a"
                        kind="text"
                        size="l"
                        class="mp-news__all-news--mobile u-top-margin--l"
                        @click="handlerTrackGoal('main-2screen-all_news')">
                        <template v-slot:left>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="16"
                                height="16"></z-icon>
                        </template>
                        <span v-html="text.news.link"></span>
                    </z-button>
                </div>
            </div>

            <div class="col-default-4 col-tablet-12 u-collapse--top">
                <div class="mp-news__rez">
                    <div class="mp-news__rez-indicators rez-indicators">
                        <z-caption
                            size="m"
                            class="rez-indicators__title"
                            decor-left
                            decor-right
                            uppercase
                            tag="a"
                            :href="
                                device === 'mobile'
                                    ? false
                                    : '/investors/disclosure/financials/'"
                            @click="handlerTrackGoal('main-2screen-financial_results')"
                        >
                            <span data-scroll-heading v-html="text.finresults.title" />
                        </z-caption>
                        <z-caption
                            tag="p"
                            class="rez-indicators__subtitle">
                            <span data-scroll-heading v-html="text.finresults.subtitle" />
                        </z-caption>
                        <div class="row" data-scroll-translate>
                            <div class="col-default-6 col-mobile-12">
                                <z-key-indicator
                                    size="xs"
                                    :value="text.finresults.revenue.val"
                                    :value-text="text.finresults.revenue.currency" />
                            </div>
                            <div class="col-default-6 col-mobile-12">
                                <z-key-indicator
                                    size="xs"
                                    :value="text.finresults.income.val"
                                    :value-text="text.finresults.income.currency" />
                            </div>
                            <div class="col-default-6 col-mobile-12 u-top-margin--m-important">
                                <z-key-indicator
                                    size="xs"
                                    :value="text.finresults.profitability.val"
                                    :value-text="text.finresults.profitability.currency" />
                            </div>
                            <div class="col-default-6 col-mobile-12 u-top-margin--m-important">
                                <z-key-indicator
                                    size="xs"
                                    :value="text.finresults.debt.val"
                                    :value-text="text.finresults.debt.currency" />
                            </div>
                        </div>
                    </div>
                    <stocks-card
                        class="mp-news__stocks"
                        goal="main-2screen-quotes"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'section-news',
    mixins: [mixinDevice],
    props: {
        news: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            text: {

                news: {
                    title: localize({
                        ru: 'Новости',
                        en: 'News'
                    }),
                    link: localize({
                        ru: 'Все новости',
                        en: 'All news'
                    })
                },

                finresults: {
                    title: localize({
                        ru: 'Финансовые результаты',
                        en: 'Financial Results'
                    }),
                    subtitle: localize({
                        ru: 'за 2024 г.',
                        en: 'of 2024'
                    }),
                    revenue: {
                        val: localize({
                            ru: '12,5',
                            en: '12.5'
                        }),
                        currency: localize({
                            ru: 'млрд долл. США<br>Выручка',
                            en: 'US$ billion<br>revenue'
                        })
                    },
                    income: {
                        val: localize({
                            ru: '5,2',
                            en: '5.2'
                        }),
                        currency: localize({
                            ru: 'млрд долл. США<br>EBITDA',
                            en: 'US$ billion<br>EBITDA'
                        })
                    },
                    profitability: {
                        val: localize({
                            ru: '41%',
                            en: '41%'
                        }),
                        currency: localize({
                            ru: 'рентабельность<br>EBITDA',
                            en: 'EBITDA<br>profitability'
                        })
                    },
                    debt: {
                        val: localize({
                            ru: '1,7x',
                            en: '1.7x'
                        }),
                        currency: localize({
                            ru: 'Чистый долг/<br>EBITDA',
                            en: 'Net Debt/<br>EBITDA'
                        })
                    }
                }
            },
        }
    },
    methods: {
        getFirstNews () {
            if (this.news && this.news.length) {
                return this.news[0]
            }
        },
        preparedNews () {
            const news = this.news.map((el) => {
                el.date = el.activeFrom
                el.link = el.detailPageUrl
                return el
            })
            news.shift()
            return news
        },
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss">
$smooth-transition: 0.4s ease-in-out;

.mp-news {

    &__title {
        margin-top: 40px;
        @include margin-level(bottom, L, false, true);

        @include breakpoint(tablet) {
            @include margin-level(bottom, M, false, true);
            margin-top: 0;
        }

        @include breakpoint(mobile) {
            @include margin-level(bottom, S, false, true);
        }
    }

    &__news-wrapper {
        @include padding-level(right, XL);

        @include breakpoint(tablet) {
            @include padding-level(right, 3XS);
        }
        @include breakpoint(v-tablet) {
            @include padding-level(right, 2XS);
        }
    }

    &__news-container {
        justify-content: space-between;
        flex-wrap: nowrap;

        @include breakpoint(laptop) {
            .news-list__item {
                flex-direction: column;
            }

            .news-list__item-date {
                margin-bottom: 8px;
            }
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }

        .z-button.mp-news__all-news--desktop {
            display: inline-flex;

            @include breakpoint(v-tablet) {
                display: none;
            }
        }

        .z-button.mp-news__all-news--mobile {
            display: none;

            @include breakpoint(v-tablet) {
                display: inline-flex;
            }

            @include breakpoint(mobile) {
                margin-top: $token-spacers-xs;
            }
        }
    }

    &__announce-left {
        @include margin-level(right, XL);
        width: 100%;
        max-width: 565px;
        flex: 1 1 55%;

        @include breakpoint(v-tablet) {
            flex: 1 1 100%;
            max-width: 100%;
            margin: 0;
        }
    }

    &__announce-wrapper {
        display: block;
        text-decoration: none;
        border-top-right-radius: 60px;
        overflow: hidden;

        &:hover {
            .announce__inner--image {
                transform: scale(1.2);
            }
        }

        @include breakpoint(v-tablet) {
            margin-right: 0;
        }
    }

    &__announce {
        margin-bottom: -1px;
        box-shadow: none;
        background-position: left bottom;
        border-top-right-radius: 60px;
        overflow: hidden;
        background-repeat: no-repeat;
    }

    &__announce-link-wrapper {
        @include padding-level(right, M);
        @include padding-level(top, M);
        @include padding-level(left, M);
        @include padding-level(bottom, 2XL);
        border-bottom-left-radius: 40px;
    }

    &__announce-link {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        overflow: hidden;
        font-weight: 600;
        font-size: 26px;
        line-height: 120%;
        color: $token-colors-white;
        text-decoration: none;

        @include breakpoint(laptop) {
            font-size: 24px;
        }

        @include breakpoint(v-tablet) {
            @include padding-level(bottom, L);
        }

        @include breakpoint(mobile) {
            font-size: 20px;
            @include padding-level(right, 2XS);
            @include padding-level(left, 2XS);
            @include padding-level(bottom, S);
        }
    }

    &__list {
        flex: 1 1 45%;

        @include breakpoint(v-tablet) {
            flex: 1 1 100%;
        }

        .news-list__item + .news-list__item {
            @include margin-level(top, XL);

            @include breakpoint(tablet) {
                @include margin-level(top, M);
            }
        }

        .news-list__item-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: $token-colors-gray-60;
        }

        @include breakpoint(tablet) {
            .news-list__item-title {
                font-size: 18px;
            }
        }

        @include breakpoint(v-tablet) {
            margin-top: $token-spacers-m;

            .news-list__item-title {
                font-size: 16px;
            }
        }
    }

    &__rez {
        @include margin-level(bottom, XL);
        margin-right: -$token-grids-desktop-offset;

        @media screen and (min-width: 1920px) {
            overflow: hidden;
        }

        @include breakpoint(laptop) {
            margin-right: -$token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            display: flex;
            margin-right: 0;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            flex-wrap: nowrap;
            margin-bottom: 0;
        }

        .financial-results {
            width: 100%;

            @include breakpoint(tablet) {
                flex: 1 1 66%;
            }

            @include breakpoint(mobile) {
                flex: 1 0 100%;
            }
        }

        .rez-indicators {
            @include breakpoint(tablet) {
                flex-grow: 1;
                background-image: url('/images/main-page/book-cover-2.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 0 50%;
                box-shadow: -17px 0px 8px 2px rgba(34, 60, 80, 0.1);
            }

            @include breakpoint(v-tablet) {
                background-image: none;
                box-shadow: none;
            }

            &__title {
                margin-bottom: 2px;
                font-weight: 700;

                &:after {
                    @include breakpoint(laptop) {
                        display: none;
                    }
                    @include breakpoint(tablet) {
                        display: block;
                    }

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }

                @include breakpoint(mobile) {
                    width: 100%;

                    &.z-caption--decor-left:before {
                        display: block;
                    }

                    .z-caption__text {
                        padding-left: 16px !important;
                    }
                }
            }

            &__subtitle {
                font-weight: 600;
                font-size: 14px;
                color: $token-colors-navy-blue;
                margin-left: 50px;
                @include margin-level(bottom, XL);
                transition: transform $smooth-transition;

                @include breakpoint(v-tablet) {
                    @include margin-level(bottom, M);
                }

                @include breakpoint(mobile) {
                    margin-left: 42px;
                }
            }
        }

        a.rez-indicators__title:hover + .rez-indicators__subtitle {
            transform: translateX(16px);
        }
    }

    &__rez-indicators {
        background-color: $token-colors-gray-10;
        @include padding-level(top, M);
        @include padding-level(left, M);
        @include padding-level(right, M);
        border-top-left-radius: $boder-radius-m;
        padding-bottom: calc(#{$token-spacers-xl} + #{$boder-radius-xl});

        @include breakpoint(laptop) {
            @include padding-level(left, S);
            @include padding-level(right, S);
        }

        @include breakpoint(tablet) {
            background: linear-gradient(110.75deg, #f4f6f9 23.84%, #e6e9ee 100%), #f5f5f5;
            border-radius: 0;
            padding-left: 52px;
            padding-top: 40px;
            padding-bottom: $token-spacers-l;
            padding-right: calc(#{$token-spacers-s} + #{$boder-radius-l});
        }

        @include breakpoint(v-tablet) {
            border-top-left-radius: $boder-radius-m;
            margin-left: -$token-grids-v-tablet-offset;
            margin-right: -$token-grids-v-tablet-offset;
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
            padding-bottom: calc(40px + #{$boder-radius-l});
        }

        @include breakpoint(mobile) {
            margin-left: -$token-grids-mobile-offset;
            margin-right: -$token-grids-mobile-offset;
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
            padding-top: $token-spacers-s;
        }
    }

    &__stocks {
        @include margin-level(top, L, true, false);

        @include breakpoint(tablet) {
            margin-top: 0;
            margin-left: -$boder-radius-l;
        }
        @include breakpoint(v-tablet) {
            margin-top: -$boder-radius-l;
            margin-left: -$token-grids-v-tablet-offset;
            margin-right: -$token-grids-v-tablet-offset;
        }

        @include breakpoint(mobile) {
            margin-top: -$token-spacers-s;
            margin-left: -$token-grids-mobile-offset;
            margin-right: -$token-grids-mobile-offset;
        }
    }
}
</style>
