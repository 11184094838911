<template>
    <div class="z-table" :class="buildClass" ref="container">
        <div class="z-table__container">
            <table>
                <caption v-if="captionText">
                    <vue-raw :raw="captionText"/>
                </caption>
                <colgroup v-if="colgroup && colgroup.length">
                    <col
                        v-for="(item, index) in colgroup"
                        :key="index"
                        span="1"
                        :style="`width: ${item};`"
                    >
                </colgroup>
                <thead>
                    <tr class="z-table-row z-table-row--head">
                        <th
                            class="z-table-col z-table-col--head"
                            v-for="(item, index) in columns"
                            :key="index"
                        >
                            <vue-raw :raw="item" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <slot />
                </tbody>
            </table>
        </div>
        <slot name="before-button" :hiddenRowsLength="hiddenRowsLength" :isRowsHidden="isRowsHidden"></slot>
        <div class="z-table__button-container" v-if="hiddenRowsLength && showCollapseButton">
            <z-button kind="secondary" :key="buttonText" @click="toggleHiddenRows">
                <vue-raw :raw="buttonText"></vue-raw>

                <template #right>
                    <z-icon
                        name="arrow-long"
                        :dir="isRowsHidden ? 'down' : 'up'"
                        width="18"
                        height="18"
                    />
                </template>
            </z-button>
        </div>
        <div class="z-table__footer" v-if="hasAddSlotData">
            <slot name="add" />
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'z-table',
    mixins: [mixinDevice],
    props: {
        columns: {
            type: Array,
            required: true,
            default: () => []
        },
        colgroup: {
            type: Array,
            default: () => []
        },
        captionText: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => []
        },
        fixed: Boolean,
        financial: Boolean,
        responsive: Boolean,
        showCollapseButton: {
            type: Boolean,
            default: true
        },
        scrollable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isRowsHidden: false,
            hiddenRowsLength: 0,
            text: {
                showRows: localize({
                    ru: 'Показать всю таблицу',
                    en: 'Show the entire table'
                }),
                hideRows: localize({
                    ru: 'Свернуть',
                    en: 'Collaps table'
                })
            }
        }
    },
    computed: {
        hasAddSlotData () {
            return this.$slots.add
        },
        buildClass () {
            return {
                'z-table--fixed': this.fixed,
                'z-table--financial': this.financial,
                'z-table--scrollable': this.scrollable,
                'z-table--responsive': this.responsive
            }
        },
        buttonText () {
            return this.isRowsHidden ? this.text.showRows : this.text.hideRows
        }
    },
    methods: {
        updateHiddenRows () {
            this.hiddenRowsLength = 0

            this.$children.forEach(item => {
                if (item.isHidden) this.hiddenRowsLength++

                item.hidden = this.isRowsHidden && item.isHidden
            })
        },

        toggleHiddenRows () {
            this.isRowsHidden = !this.isRowsHidden

            this.updateHiddenRows()

            if (this.isRowsHidden && this.$refs.container) {
                this.$refs.container.scrollIntoView({ block: 'start' })
            }
        },

        mounted () {
            this.updateHiddenRows()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
