<template>
    <div
        v-on="$listeners"
        data-form-btn
        class="form-button"
    >
        <z-button size="l">
            <z-icon
                :name="currentIcon"
                width="32"
                height="32"
            ></z-icon>
            <span
                class="form-button__text"
                data-form-btn-text
            >
                {{ text }}
            </span>
        </z-button>
    </div>
</template>

<script>
import FormButtonAnimation from '../modules/form-button-animation'
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'form-button',
    mixins: [mixinDevice],
    props: {
        startOffset: [Number, String]
    },
    data () {
        return {
            text: localize({
                ru: 'Напишите нам',
                en: 'Contact Us'
            }),
            btnAnimation: null
        }
    },
    computed: {
        currentIcon () {
            if (['mobile', 'v-tablet'].includes(this.device)) {
                return 'message'
            } else return 'RocketLaunch'
        }
    },
    mounted () {
        this.btnAnimation = new FormButtonAnimation(this.startOffset)
    },
    beforeDestroy () {
        if (this.btnAnimation) {
            this.btnAnimation.removeEvents()
        }
    }
}
</script>

<style lang="scss">
.form-button {
    position: fixed;
    overflow: hidden;
    bottom: clamp(16px, 7.5%, 58px);
    z-index: 3;
    right: 0;
    text-align: right;
    @include margin-level(right, 2XL);

    @include breakpoint(v-tablet) {
        @include margin-level(right, M);
    }

    .z-button {
        width: auto !important;
        padding: 16px;
    }

    .z-button__text {
        display: flex;
        align-items: center;
    }

    &__text {
        display: inline-block;
        white-space: nowrap;
        margin-right: 9px;
        margin-left: 16px;

        @include breakpoint(v-tablet) {
            width: 0 !important;
            opacity: 0 !important;
            margin: 0 !important;
        }
    }
}
</style>