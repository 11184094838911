// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import store from './store/'
import VueTippy, { TippyComponent } from 'vue-tippy'
import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { initTooltips } from '@/utils/tooltip'
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
import { mapActions } from 'vuex'
import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationTop from './components/NavigationTop'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import Social from './components/Social'
import NavigationLeft from './components/NavigationLeft'
import PageTools from './components/PageTools'
import CookiePopup from './components/CookiePopup'
import Remind from './components/Remind'
import {
    Slider,
    Slide,
    SliderThumb,
    SimpleSlider,
    SlideContent,
    DefaultSlider,
    ModernSlider
} from './components/Slider'
import ContactCard from './components/ContactCard'
import PageNav from './components/PageNav'
import Listing from './components/Listing'
import DateBlock from './components/DateBlock'
import CardLink from './components/CardLink'
import IconBlock from './components/IconBlock'
import EventsList from './components/EventsList'
import NewsList from './components/NewsList'
import Euroland from './components/Euroland'
import CreditRating from './components/CreditRating'
import Layout from './components/Layout'
import PresentationBlock from './components/PresentationBlock'
import ReportBlock from './components/ReportBlock'
import Tag from './components/Tag'
import Announce from './components/Announce'
import RowFilter from './components/RowFilter'
import FilterContainer from './components/FilterContainer'
import DatepickerRange from './components/DatepickerRange'
import TagsFilter from './components/TagsFilter'
import MetalCard from './components/MetalCard'
import PageTool from './components/PageTool'
import SharePopup from './components/SharePopup'
import CorporateHotlineForm from './components/CorporateHotlineForm'
import Dash from './components/Dash'
import YandexMap from './components/YandexMap'
import CommodityResearchForm from './components/CommodityResearchForm'
import MediaCard from './components/MediaCard'
import {
    MediaAlbumController,
    MediaAlbumExpedition,
    MediaAlbumIdentity,
    MediaAlbumPresentations
} from './components/MediaAlbumController'
import ContactsForm from './components/ContactsForm'
import ContactsPageForm from './components/ContactsPageForm'
import MainContactsForm from './components/MainContactsForm'
import QuestionForm from './components/QuestionForm'
import ContactCardHoc from './components/ContactCardHoc'
import QuoteBlock from './components/QuoteBlock'
import Spoiler from './components/Spoiler'
import Notification from './components/Notification'
import PageChanger from './components/PageChanger'
import CleanFooter from './views/CleanNorilsk/components/CleanFooter.vue'
import CleanHeader from './views/CleanNorilsk/components/CleanHeader.vue'
import BeforeAfter from './views/CleanNorilsk/views/Main/components/BeforeAfter.vue'
import QuotesSlider from './views/CleanNorilsk/views/Main/components/QuotesSlider.vue'
import TlImgBottom from './views/CleanNorilsk/views/Timeline/components/TlImgBottom.vue'
import ResultBook from './components/ResultBook'
import YaMap from '@/components/YaMap'
import Timeline from './views/CleanNorilsk/views/Timeline/Timeline.vue'
import TlYear from './views/CleanNorilsk/views/Timeline/components/TlYear.vue'
import TlKey from './views/CleanNorilsk/views/Timeline/components/TlKey.vue'
import TlMore from './views/CleanNorilsk/views/Timeline/components/TlMore.vue'
import TlLogo from './views/CleanNorilsk/views/Timeline/components/TlLogo.vue'
import TlLine from './views/CleanNorilsk/views/Timeline/components/TlLine.vue'
import BecomeSupplierForm from './components/BecomeSupplierForm'
import ElectronicDocumentsForm from './components/ElectronicDocumentsForm'
import HeaderNavigation from '@/components/HeaderNavigation'
import DownloadExcelBtn from './components/DownloadExcelBtn'
import CustomModal from './components/CustomModal'
import ContactsDefaultModal from './components/ContactsDefaultModal'
import ContactsNcaModal from './components/ContactsNcaModal'
import SuppliersContactsModal from './components/SuppliersContactsModal'
import SuppliersContactsForm from './components/SuppliersContactsForm'
import Timer from './components/Timer/Timer.vue'
import Banner from './components/Banner/Banner.vue'
import FixedAnchor from '@/components/FixedAnchor'
import InnovationsForm from '@/components/InnovationsForm'
import CleanMediaCard from './views/CleanNorilsk/components/CleanMediaCard.vue'

/* Components Views */
import ContentContainer from './views/ContentContainer'
import Page404 from './views/Page404'
import ControlPage from './views/ControlPage'
import IrHighlightsPage from './views/IrHighlightsPage'
import { BodsList, BodsDetail, BodsRewards } from './views/BoardOfDirectors'
import GeneralMeetings from './views/GeneralMeetings/GeneralMeetings'
import SustainabilityPage from './views/SustainabilityPage/SustainabilityPage'
import Cmd from './views/Cmd'
import ReportsAndResults from './views/ReportsAndResults'
import AnnualReports from './views/AnnualReports'
import KeyFactsPage from './views/KeyFactsPage'
import AnnualReportBlock from './views/AnnualReportBlock'
import SignificantFacts from './views/SignificantFacts'
import SignificantFactsDetail from './views/SignificantFactsDetail'
import PresentationsAndBriefings from './views/PresentationsAndBriefings'
import Calendar from './views/Calendar'
import CommodityResearchPage from './views/CommodityResearchPage'
import GlossaryPage from './views/GlossaryPage'
import BusinessModel from './views/BusinessModel'
import News from './views/News'
import BusinessMap from './views/BusinessMap'
import NewsDetail from './views/NewsDetail'
import Media from './views/Media'
import MediaAlbum from './views/MediaAlbum'
import MediaAlbumDetail from './views/MediaAlbumDetail'
import MainPageView from './views/MainPageView'
import {
    Products,
    ProductsApplications
} from './views/Products'
import PhysChars from './views/PhysChars'
import SearchPage from './views/SearchPage'
import ContactsPage from './views/ContactsPage'
import RegisterDishonest from './views/RegisterDishonest'
import { CleanNorilsk } from './views/CleanNorilsk'
import SubscribePage from './views/SubscribePage'
import SiteMap from './views/SiteMap'
import NewsDetailCleanNorilsk from './views/NewsDetailCleanNorilsk'
import NewsCleanNorilsk from './views/NewsCleanNorilsk'
import Beginning from './views/Beginning'
import { UpdatesPage, UpdatesDetail } from './views/UpdatesPage'
import { EquipmentPage, EquipmentDetail } from '@/views/EquipmentPage'
import { RealtyPage, RealtyDetail } from './views/RealtyPage'
import { SpecializedMachineryPage, SpecializedMachineryDetail } from '@/views/SpecializedMachinery'
import { PropertyComplexesPage, PropertyComplexesDetail } from '@/views/PropertyComplexes'
import { NonIndustrialAssetsPage, NonIndustrialAssetsDetail } from './views/NonIndustrialAssets'
import { SalePage } from './views/SalePage'
import CentralTenders from './views/CentralTenders'
import LocalTenders from './views/LocalTenders'
import BondsPage from './views/BondsPage'
import ProcurementPlanPage from './views/ProcurementPlanPage'
import SulphurSection from '@/views/SulphurSection'
import { InnovationPage, InnovationDetailPage } from '@/views/InnovationPage'
import OfferProject from '@/views/OfferProject'
import BiodiversityForm from '@/views/BiodiversityForm'
import Presentation from '@/views/Presentation'
import FilesPage from '@/views/Files'
import { Icons } from '@/views/Styleguide'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-top', NavigationTop)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('social', Social)
Vue.component('navigation-left', NavigationLeft)
Vue.component('page-tools', PageTools)
Vue.component('cookie-popup', CookiePopup)
Vue.component('remind', Remind)
Vue.component('slider', Slider)
Vue.component('slide', Slide)
Vue.component('slider-thumb', SliderThumb)
Vue.component('simple-slider', SimpleSlider)
Vue.component('slide-content', SlideContent)
Vue.component('contact-card', ContactCard)
Vue.component('page-nav', PageNav)
Vue.component('listing', Listing)
Vue.component('date-block', DateBlock)
Vue.component('icon-block', IconBlock)
Vue.component('card-link', CardLink)
Vue.component('events-list', EventsList)
Vue.component('news-list', NewsList)
Vue.component('euroland', Euroland)
Vue.component('credit-rating', CreditRating)
Vue.component('layout', Layout)
Vue.component('presentation-block', PresentationBlock)
Vue.component('report-block', ReportBlock)
Vue.component('tag', Tag)
Vue.component('announce', Announce)
Vue.component('row-filter', RowFilter)
Vue.component('filter-container', FilterContainer)
Vue.component('datepicker-range', DatepickerRange)
Vue.component('tags-filter', TagsFilter)
Vue.component('metal-card', MetalCard)
Vue.component('page-tool', PageTool)
Vue.component('share-popup', SharePopup)
Vue.component('media', Media)
Vue.component('media-card', MediaCard)
Vue.component('media-album-controller', MediaAlbumController)
Vue.component('media-album-expedition', MediaAlbumExpedition)
Vue.component('media-album-identity', MediaAlbumIdentity)
Vue.component('media-album-presentations', MediaAlbumPresentations)
Vue.component('corporate-hotline-form', CorporateHotlineForm)
Vue.component('yandex-map', YandexMap)
Vue.component('dash', Dash)
Vue.component('commodity-research-form', CommodityResearchForm)
Vue.component('contacts-page-form', ContactsPageForm)
Vue.component('contacts-form', ContactsForm)
Vue.component('main-contacts-form', MainContactsForm)
Vue.component('question-form', QuestionForm)
Vue.component('contact-card-hoc', ContactCardHoc)
Vue.component('default-slider', DefaultSlider)
Vue.component('quote-block', QuoteBlock)
Vue.component('spoiler', Spoiler)
Vue.component('page-changer', PageChanger)
Vue.component('notification', Notification)
Vue.component('clean-footer', CleanFooter)
Vue.component('clean-header', CleanHeader)
Vue.component('before-after', BeforeAfter)
Vue.component('quotes-slider', QuotesSlider)
Vue.component('tl-img-bottom', TlImgBottom)
Vue.component('result-book', ResultBook)
Vue.component('ya-map', YaMap)
Vue.component('timeline', Timeline)
Vue.component('tl-year', TlYear)
Vue.component('tl-key', TlKey)
Vue.component('tl-more', TlMore)
Vue.component('tl-logo', TlLogo)
Vue.component('tl-line', TlLine)
Vue.component('become-supplier-form', BecomeSupplierForm)
Vue.component('electronic-documents-form', ElectronicDocumentsForm)
Vue.component('header-navigation', HeaderNavigation)
Vue.component('download-excel-btn', DownloadExcelBtn)
Vue.component('custom-modal', CustomModal)
Vue.component('contacts-default-modal', ContactsDefaultModal)
Vue.component('contacts-nca-modal', ContactsNcaModal)
Vue.component('suppliers-contacts-modal', SuppliersContactsModal)
Vue.component('suppliers-contacts-form', SuppliersContactsForm)
Vue.component('timer', Timer)
Vue.component('banner', Banner)
Vue.component('fixed-anchor', FixedAnchor)
Vue.component('innovations-form', InnovationsForm)
Vue.component('modern-slider', ModernSlider)
Vue.component('clean-media-card', CleanMediaCard)

/* Views */
Vue.component('content-container', ContentContainer)
Vue.component('page-404', Page404)
Vue.component('control-page', ControlPage)
Vue.component('ir-highlights-page', IrHighlightsPage)
Vue.component('bods-list', BodsList)
Vue.component('bods-detail', BodsDetail)
Vue.component('bods-rewards', BodsRewards)
Vue.component('general-meetings', GeneralMeetings)
Vue.component('sustainability-page', SustainabilityPage)
Vue.component('cmd', Cmd)
Vue.component('reports-and-results', ReportsAndResults)
Vue.component('annual-reports', AnnualReports)
Vue.component('key-facts-page', KeyFactsPage)
Vue.component('annual-report-block', AnnualReportBlock)
Vue.component('significant-facts', SignificantFacts)
Vue.component('significant-facts-detail', SignificantFactsDetail)
Vue.component('presentations-and-briefings', PresentationsAndBriefings)
Vue.component('calendar', Calendar)
Vue.component('commodity-research', CommodityResearchPage)
Vue.component('glossary-page', GlossaryPage)
Vue.component('business-model', BusinessModel)
Vue.component('news', News)
Vue.component('buisness-map', BusinessMap)
Vue.component('news-detail', NewsDetail)
Vue.component('media-album', MediaAlbum)
Vue.component('media-album-detail', MediaAlbumDetail)
Vue.component('main-page-view', MainPageView)
Vue.component('products', Products)
Vue.component('products-applications', ProductsApplications)
Vue.component('phys-chars', PhysChars)
Vue.component('search-page', SearchPage)
Vue.component('contacts-page', ContactsPage)
Vue.component('register-dishonest', RegisterDishonest)
Vue.component('clean-norilsk', CleanNorilsk)
Vue.component('subscribe-page', SubscribePage)
Vue.component('site-map', SiteMap)
Vue.component('news-detail-clean-norilsk', NewsDetailCleanNorilsk)
Vue.component('news-clean-norilsk', NewsCleanNorilsk)
Vue.component('beginning', Beginning)
Vue.component('updates-page', UpdatesPage)
Vue.component('updates-detail', UpdatesDetail)
Vue.component('equipment-page', EquipmentPage)
Vue.component('equipment-detail', EquipmentDetail)
Vue.component('realty-page', RealtyPage)
Vue.component('realty-detail', RealtyDetail)
Vue.component('property-complexes-page', PropertyComplexesPage)
Vue.component('property-complexes-detail', PropertyComplexesDetail)
Vue.component('specialized-machinery-page', SpecializedMachineryPage)
Vue.component('specialized-machinery-detail', SpecializedMachineryDetail)
Vue.component('non-industrial-assets-page', NonIndustrialAssetsPage)
Vue.component('non-industrial-assets-detail', NonIndustrialAssetsDetail)
Vue.component('sale-page', SalePage)
Vue.component('central-tenders', CentralTenders)
Vue.component('local-tenders', LocalTenders)
Vue.component('bonds-page', BondsPage)
Vue.component('procurement-plan-page', ProcurementPlanPage)
Vue.component('sulphur-section', SulphurSection)
Vue.component('innovation-page', InnovationPage)
Vue.component('innovation-detail-page', InnovationDetailPage)
Vue.component('offer-project', OfferProject)
Vue.component('biodiversity-form', BiodiversityForm)
Vue.component('presentation', Presentation)
Vue.component('files-page', FilesPage)
Vue.component('icons', Icons)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context('./components', true, /Z[\w-]+\/index\.js$/)

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName.split('/')[ 1 ]
    Vue.component(componentName, componentConfig.default || componentConfig)
})

if (process.env.NODE_ENV !== 'production') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        showNotyfications(`${msg}<br>${url}#${lineNo}`, {
            type: 'error'
        })
    }

    Vue.config.errorHandler = function (err, vm, info) {
        console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'error'
        })
    }

    Vue.config.warnHandler = function (msg, vm, trace) {
        console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'alert'
        })
    }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)
Vue.use(VueTippy, {
    directive: 'tippy' // => v-tippy
})
/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [ AppDeviceEnquire ],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.SetContactsAction()
        this.$el.classList.add('is-loaded')
        initTooltips()

        const doc = document.documentElement
        const w = window

        let prevScroll = w.scrollY || doc.scrollTop
        let curScroll
        let direction = 0
        let prevDirection = 0

        const header = document.querySelector('header')

        const checkScroll = function () {
            // Find the direction of scroll
            // 0 - initial, 1 - up, 2 - down
            curScroll = w.scrollY || doc.scrollTop

            if (curScroll > prevScroll) {
                // scrolled up
                direction = 2
            } else if (curScroll < prevScroll) {
                // scrolled down
                direction = 1
            }

            if (direction !== prevDirection) {
                toggleHeader(direction, curScroll)
            }

            prevScroll = curScroll
        }

        const toggleHeader = function (direction, curScroll) {
            if (direction === 2 && curScroll > 144) {
                header.classList.remove('scroll-up')
                header.classList.add('scroll-down')
                prevDirection = direction
            } else if (direction === 1) {
                header.classList.remove('scroll-down')
                header.classList.add('scroll-up')
                prevDirection = direction
            }
        }

        if (header) {
            window.addEventListener('scroll', checkScroll)
        }
    },
    methods: {
        ...mapActions([ 'SetContactsAction' ])
    }
})
