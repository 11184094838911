/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 31C7.716 31 1 24.284 1 16 1 7.716 7.716 1 16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zM0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.459 9.182a1.775 1.775 0 100 3.55 1.775 1.775 0 000-3.55zm-2.216 3.761a2.975 2.975 0 10-.605-1.036l-4.465 2.606a2.975 2.975 0 10.002 3.972l4.465 2.602a2.975 2.975 0 10.607-1.035l-4.468-2.604a2.972 2.972 0 000-1.899l4.464-2.606zm-5.768 2.634a.589.589 0 00.034.058c.143.256.224.551.224.865 0 .307-.077.596-.214.848a.568.568 0 00-.053.09 1.774 1.774 0 01-3.283-.938 1.775 1.775 0 013.292-.923zm6.208 6.464c0-.297.073-.577.203-.823a.607.607 0 00.085-.145 1.775 1.775 0 11-.287.969z" _fill="#0077C8"/>'
  }
})
