import gsap from 'gsap'

class CardHover {
    constructor (card) {
        if (!card) {
            return
        }
        this.card = card
        this.content = this.card.querySelector('.custom-card__content')
        this.gradient = this.card.querySelector('.custom-card__gradient')
        this.bgImg = this.card.querySelector('.custom-card__img')

        this.tl = null
        this.play = this.play.bind(this)
        this.reverse = this.reverse.bind(this)
    }

    init () {
        this.setTimelineAnimation()
        this.addEvents()
    }

    setTimelineAnimation () {
        const allCards = Array.from(document.querySelectorAll('.custom-card'))

        this.tl = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.2,
                ease: 'linear'
            },
            onStart: () => {
                this.card.classList.add('active')
                allCards.forEach(card => gsap.to(card.querySelector('.custom-card__title'), { autoAlpha: 0, duration: 0.1 }))
            },
            onReverseComplete: () => {
                this.card.classList.remove('active')

                let existActive = false
                allCards.some(card => {
                    if (card.classList.contains('active')) {
                        existActive = true
                        return true
                    }
                })

                if (!existActive) {
                    allCards.forEach(card => gsap.to(card.querySelector('.custom-card__title'), { autoAlpha: 1, duration: 0.1 }))
                }
            }
        })
        this.tl
            .to(this.card, {
                flex: '2',
                duration: 0.1
            }, '>+=0.1')
            .fromTo(this.content, {
                autoAlpha: 0,
                yPercent: 100,
                duration: 0.2
            },
            {
                autoAlpha: 1,
                yPercent: 0,
                duration: 0.2
            }, '>+=0.2')
            .fromTo(this.gradient, { opacity: 0, duration: 0.2 }, { opacity: 1, duration: 0.2 }, '<')
    }

    addEvents () {
        this.card.addEventListener('mouseenter', this.play)
        this.card.addEventListener('mouseleave', this.reverse)
    }

    removeEvents () {
        this.card.removeEventListener('mouseenter', this.play)
        this.card.removeEventListener('mouseleave', this.reverse)
    }

    revert () {
        this.tl.revert()
    }

    play () {
        this.tl.play()
    }

    reverse () {
        this.tl.reverse()
    }
}

export default CardHover
