/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_small_and_medium_enterprises': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M18.727 27.432a.75.75 0 01-.915.545l-3-.75a.748.748 0 01-.234-.104l-2.25-1.5a.75.75 0 01.833-1.248l2.14 1.428 2.875.72a.751.751 0 01.552.909zm12.44-9.043a1.49 1.49 0 01-.75.867l-2.22 1.11-5.163 5.164a.75.75 0 01-.713.197l-6-1.5a.754.754 0 01-.254-.117l-5.205-3.716-2.276-1.138a1.5 1.5 0 01-.672-2.012l2.33-4.659a1.5 1.5 0 012.012-.67l2.068 1.03 4.969-1.419a.75.75 0 01.412 0l4.97 1.42 2.067-1.032a1.5 1.5 0 012.012.671l2.33 4.659a1.493 1.493 0 01.083 1.145zm-4.33 1.213l-2.55-5.102h-2.983l-4.054 3.937c1.187.759 3.048.968 4.718-.715a.75.75 0 011-.057l3.227 2.584.643-.647zM9.257 17.914l1.658.83 2.33-4.659-1.658-.83-2.33 4.66zm15.869 3.398l-2.598-2.08c-1.832 1.5-4.158 1.697-6.085.468a1.5 1.5 0 01-.248-2.334l4.206-4.08-.9-.256-4.727 1.35-2.566 5.132 4.613 3.295 5.45 1.362 2.855-2.857zm4.616-3.398l-2.327-4.658-1.658.83 2.33 4.658 1.655-.83z" _fill="#fff"/>'
  }
})
