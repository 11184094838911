import { localize } from '@/utils/i18n'

export const directionOptions = [
    {
        'id': 'digitalization-of-the-production-chain',
        'selected': false,
        'disabled': false,
        'text': localize({
            ru: 'Цифровизация производственной цепочки',
            en: 'Digitalization of the production chain'
        })
    },
    {
        'id': 'improving-technology',
        'selected': false,
        'disabled': false,
        'text': localize({
            ru: 'Совершенствование технологий',
            en: 'Improving technology'
        })
    },
    {
        'id': 'hightech-products',
        'selected': false,
        'disabled': false,
        'text': localize({
            ru: 'Высокотехнологичные продукты',
            en: 'High tech products'
        })
    },
    {
        'id': 'ecology-industrial-safety',
        'selected': false,
        'disabled': false,
        'text': localize({
            ru: 'Экология и&nbsp;промышленная безопасность',
            en: 'Ecology and industrial safety'
        })
    }
]

export const text = {
    formTitle: localize({
        ru: 'Заполните форму, приложите документ и&nbsp;отправьте заявку',
        en: 'Fill out the form, attach a&nbsp;document and submit your application'
    }),
    name: localize({
        ru: 'Имя*',
        en: 'Full name*'
    }),
    email: localize({
        ru: 'E-mail*',
        en: 'E-mail*'
    }),
    direction: localize({
        ru: 'Направление*',
        en: 'Direction*'
    }),
    detailText: localize({
        ru: 'Описание*',
        en: 'Description*'
    }),
    files: localize({
        ru: 'Загрузить файл',
        en: 'Upload file'
    }),
    filesDescription: localize({
        ru: 'До 10 фото/видео/pdf/tiff-файлов общим размером не более 9 МБайт',
        en: 'Accepted file types: ppt/xsl/xlsx/pdf/pptx/doc/docx/zip/rar/tar'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки*',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие ПАО «ГМК Норильский никель» на обработку моих персональных данных, указанных при заполнении формы обратной связи на сайте www.nornickel.ru',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com , by PJSC “MMC “Norilsk Nickel”'
    })
}
