/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/video': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31980)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M60.85 69.75c0-1.049.85-1.9 1.9-1.9H76.5c1.05 0 1.9.851 1.9 1.9v12.5a1.9 1.9 0 01-1.9 1.9H62.75a1.9 1.9 0 01-1.9-1.9v-12.5zm18.75 5.085V69.75a3.1 3.1 0 00-3.1-3.1H62.75a3.1 3.1 0 00-3.1 3.1v12.5a3.1 3.1 0 003.1 3.1H76.5a3.1 3.1 0 003.1-3.1v-5.084l7.801 5.573a.6.6 0 00.949-.489v-12.5a.6.6 0 00-.949-.488L79.6 74.835zm7.55 6.25L80.032 76l7.118-5.084v10.169z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31980" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31980"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31980" result="effect2_dropShadow_9226_31980"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31980" result="shape"/></filter></defs>'
  }
})
