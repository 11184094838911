/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'danger': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_9226_31807)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.661 3.91C16.801 3.91 3.91 16.801 3.91 32.662c0 15.861 12.891 28.752 28.752 28.752 15.861 0 28.752-12.89 28.752-28.752 0-15.86-12.89-28.752-28.752-28.752zM1.91 32.662C1.91 15.697 15.696 1.91 32.661 1.91c16.966 0 30.752 13.787 30.752 30.752 0 16.966-13.786 30.752-30.752 30.752-16.965 0-30.752-13.786-30.752-30.752zm18.004-21.891a1 1 0 011.366.365l7.91 13.687a1 1 0 01-1.733 1l-7.398-12.802C13.357 17.273 9.505 24.417 9.208 31.86h14.8a1 1 0 110 2H8.19a1 1 0 01-1-.997c-.026-8.8 4.542-17.37 12.723-22.092zm24.08.39a1 1 0 011.365-.366c7.637 4.386 12.774 12.619 12.774 22.066a1 1 0 01-1 1H41.314a1 1 0 010-2h14.797c-.332-7.928-4.592-14.83-10.895-18.817l-7.4 12.804a1 1 0 01-1.731-1l7.909-13.686zM19.552 53.545a1 1 0 00.367 1.365c8.18 4.724 17.886 4.395 25.494-.027a1 1 0 00.363-1.365l-7.909-13.699a1 1 0 00-1.732 1l7.4 12.817c-6.594 3.465-14.707 3.702-21.74.024l7.388-12.809a1 1 0 10-1.733-.999l-7.898 13.693zm8.373-19.061a5.074 5.074 0 119.475-3.638 5.074 5.074 0 01-9.475 3.638zm2.202-8.424a7.075 7.075 0 105.071 13.21 7.075 7.075 0 00-5.07-13.21z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31807"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
