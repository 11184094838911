var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-key-indicator",class:_vm.classObject},[_c('div',{staticClass:"z-key-indicator__body"},[(_vm.name)?_c('span',{staticClass:"z-key-indicator__name"},[(_vm.theme === 'stock')?[_c('z-tooltip',{attrs:{"icon-hidden":"","placement":"bottom","content":_vm.lang === 'ru' ? 'Изменение к цене закрытия предыдущего дня торгов' : ''}},[_c('vue-raw',{attrs:{"raw":_vm.buildPercent(_vm.name)}})],1)]:_c('vue-raw',{attrs:{"raw":_vm.name}})],2):_vm._e(),_vm._v(" "),(_vm.value || _vm.valueText)?_c('div',{staticClass:"z-key-indicator__container"},[(_vm.circleColor || _vm.circleTheme)?_c('div',{class:[
                    'z-key-indicator__circle',
                    ( _obj = {}, _obj[("z-key-indicator__circle--theme-" + (this.circleTheme))] = this.circleTheme, _obj )
                ],style:({
                    backgroundColor: _vm.circleColor
                })}):_vm._e(),_vm._v(" "),_c('div',{class:[
                    'z-key-indicator__value-wrapper',
                    { 'z-key-indicator__value-wrapper--column': _vm.column }
                ]},[(_vm.textLeft || _vm.value)?_c('div',{staticClass:"z-key-indicator__value-container"},[(_vm.textLeft)?_c('span',{staticClass:"z-key-indicator__value-text z-key-indicator__value-text--left"},[_c('vue-raw',{attrs:{"raw":_vm.textLeft}})],1):_vm._e(),_vm._v(" "),(_vm.value)?_c('span',{staticClass:"z-key-indicator__value",domProps:{"innerHTML":_vm._s(_vm.value)}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.valueText)?_c('vue-raw',{staticClass:"z-key-indicator__value-text",attrs:{"raw":_vm.valueText}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.caption)?_c('span',{staticClass:"z-key-indicator__caption"},[_c('vue-raw',{attrs:{"raw":_vm.caption}})],1):_vm._e()]),_vm._v(" "),(!!this.$slots['footer'])?_c('div',{staticClass:"z-key-indicator__footer"},[_vm._t("footer")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }