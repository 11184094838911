/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microscope': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<g clip-path="url(#clip0_3962_22708)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M62.907 13.325l-.53-.53-.53.53-5.022 5.02-.39-.389-.53-.53-.53.53-22.227 22.228-.53.53.53.53 7.409 7.41.53.53.53-.53L57.39 32.881c3.824 3.644 6.227 8.84 6.227 14.613 0 11.111-9.095 20.206-20.206 20.206-5.87 0-11.1-2.407-14.749-6.357h15.534v-1.5H18v1.5h8.677A21.146 21.146 0 0035.38 67.7H23.8v6.74h40.793V67.7H51.319c8.058-3.178 13.797-11.053 13.797-20.206 0-6.183-2.57-11.758-6.666-15.674l5.395-5.395.53-.53-.53-.53-.402-.403 5.02-5.02.53-.53-.53-.53-5.556-5.558zM55.9 19.553l.39.39 5.556 5.557.402.401L45.64 42.51l-6.348-6.349L55.9 19.553zM38.23 37.22l-3.492 3.493 6.348 6.348 3.493-3.492-6.349-6.349zm24.151-13.318l4.49-4.49-4.495-4.497-4.49 4.49 4.495 4.497zM25.3 69.2v3.74h37.793V69.2H25.299zm14.967-13.097H21.929v-1.5h18.337v1.5z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3962_22708"><path pid="1" _fill="#fff" transform="translate(18 12)" d="M0 0h51.867v63H0z"/></clipPath></defs>'
  }
})
