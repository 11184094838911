<template>
    <div class="z-breadcrumbs" :class="`z-breadcrumbs--theme-${theme}`">
        <nav class="z-breadcrumbs__wrapper">
            <a
                class="z-button z-button--kind-text"
                :href="backLink"
                v-if="detail"
            >
                <div class="z-button__icon z-button__icon--align-left">
                    <z-icon name="round-arrow-folder/arrow" dir="top"></z-icon>
                </div>
                {{ backText }}
            </a>
            <ul class="z-breadcrumbs__list" v-else>
                <li
                    class="z-breadcrumbs__item"
                    v-for="(item, index) in data"
                    :class="{'is-current': isCurrent(item.url)}"
                    :key="index"
                >
                    <template v-if="!isCurrent(item.url)">
                        <a
                            class="z-breadcrumbs__link"
                            :href="item.url"
                            :title="item.name"
                        >
                            <span v-html="formatText(item.name)"></span>
                        </a>
                    </template>
                    <template v-else>
                    <span class="z-breadcrumbs__link">
                        <span v-html="formatText(item.name)"></span>
                    </span>
                    </template>
                </li>
            </ul>
        </nav>
        <div v-if="showPageNav && pageNav.next.link" class="z-breadcrumbs__nav hide-me--mobile">
            <a
                v-if="pageNav.next"
                :href="pageNav.next.link"
                :title="pageNav.next.text"
                class="z-breadcrumbs__nav-item"
            >
                <span class="z-breadcrumbs__nav-text hide-me--v-tablet" v-html="formatText(pageNav.next.text)"></span>
                <span class="z-breadcrumbs__nav-icon">
                    <z-icon
                        name="round-arrow-folder/line-m"
                        width="20"
                        height="20"
                        dir="right"
                    />
                </span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-breadcrumbs',
    props: {
        detail: {
            type: Boolean,
            default: false
        },
        theme: {
            type: String,
            default: 'default',
            validator: (prop) => ['default', 'white'].includes(prop)
        },
        showPageNav: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            data: this.$root.app.components.breadcrumbs,
            pageNav: this.$root.app.components['page-nav'] || {},
            pathname: window.location.pathname
        }
    },
    computed: {
        backLink () {
            return this.$store.getters.backLink
        },
        backText () {
            return this.$store.getters.backText
        }
    },
    methods: {
        isCurrent (link) {
            return this.pathname === link ? this.pathname === link : this.data.findIndex(el => el.url === link) === this.data.length - 1
        },
        formatText (name) {
            if (name.length <= 40) return name

            const trimmed = name.slice(0, 40).trim()
            return trimmed.lastIndexOf(' ') !== -1
                ? `${trimmed.slice(0, trimmed.lastIndexOf(' '))} ...`
                : `${trimmed} ...`
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
