/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_9226_31771)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.152 12l-5.576 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.85 12l5.575-5.575z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31771"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
