import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class ProjectCardAnimation {
    constructor () {
        this.projectCards = document.querySelectorAll('[data-project-card]')
        this.timeline = null
        this.animateCards()
    }

    animateCards () {
        this.projectCards.forEach((card) => {
            const title = card.querySelector('[data-project-card-title]')
            const buttonsBlock = card.querySelector('[data-project-card-buttons]')
            const buttons = card.querySelectorAll('[data-project-card-button]')
            const bgImage = card.querySelectorAll('[data-project-card-bg]')

            buttons.forEach((button) => {
                gsap.set(button, {
                    opacity: 0
                })
            })

            gsap.set(card, {
                overflow: 'hidden'
            })

            gsap.set(title, {
                fontSize: '30px'
            })

            gsap.set(buttonsBlock, {
                height: 0,
                marginTop: 0
            })

            gsap.set(bgImage, {
                height: 'auto'
            })

            this.timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: card,
                    start: 'bottom 90%',
                    ease: 'power3',
                    toggleActions: 'play none none none',
                    pinSpacing: false
                }
            })

            this.timeline.add('cardExpand')
                .to(title, { fontSize: '36px' }, 'cardExpand')
                .to(bgImage, { height: '100%', transform: 'scale(1)' }, 'cardExpand')
                .to(buttonsBlock, { height: 'auto', marginTop: 32 }, 'cardExpand')
                .to(buttons, { opacity: 1 }, 'cardExpand')
        })
    }

    pause () {
        this.timeline.scrollTrigger.disable()
    }

    play () {
        this.timeline.scrollTrigger.enable()
    }
}

export default ProjectCardAnimation
