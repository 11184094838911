/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.25 6.1C12.883 6.1 6.1 12.883 6.1 21.25c0 4.174 1.688 7.954 4.419 10.694.765-1.653 2.085-3.088 3.798-4.146 2-1.235 4.437-1.898 6.933-1.898 2.496 0 4.933.663 6.933 1.898 1.712 1.058 3.033 2.493 3.798 4.146A15.102 15.102 0 0036.4 21.25c0-8.367-6.783-15.15-15.15-15.15zm9.797 26.707c-.62-1.56-1.829-2.959-3.495-3.988-1.797-1.11-4.012-1.719-6.302-1.719s-4.505.61-6.302 1.719c-1.667 1.03-2.874 2.427-3.495 3.988A15.09 15.09 0 0021.25 36.4a15.09 15.09 0 009.797-3.593zM4.9 21.25c0-9.03 7.32-16.35 16.35-16.35 9.03 0 16.35 7.32 16.35 16.35 0 9.03-7.32 16.35-16.35 16.35-9.03 0-16.35-7.32-16.35-16.35zm16.35-8.15a4.65 4.65 0 100 9.3 4.65 4.65 0 000-9.3zm-5.85 4.65a5.85 5.85 0 1111.7 0 5.85 5.85 0 01-11.7 0z" _fill="#0077C8"/>'
  }
})
