/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round-arrow-folder/line-l': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M79 40c0 21.54-17.46 39-39 39S1 61.54 1 40 18.46 1 40 1s39 17.46 39 39zm1 0c0 22.091-17.909 40-40 40S0 62.091 0 40 17.909 0 40 0s40 17.909 40 40zm-29.007-.818a.497.497 0 01-.007.713l-11.09 11.09a.5.5 0 01-.706-.707l10.242-10.243H28.447a.5.5 0 110-1h20.986L39.19 28.792a.5.5 0 11.707-.707l11.096 11.097z" _fill="#0077C8"/>'
  }
})
