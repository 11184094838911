<template>
<div class="calendar-block">
    <div ref="datepicker" class="calendar-block__datepicker"></div>
</div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import moment from 'moment'
import { calendarFormatDate } from '@/utils/mixin'
import tippy from 'tippy.js'

export default {
    name: 'calendar-block',
    mixins: [calendarFormatDate],
    props: {
        data: {
            type: Array,
            default: () => []
        },
        nextMonth: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            datepicker: null,
            items: []
        }
    },
    methods: {
        init () {
            const datepicker = this.$refs.datepicker
            let options = {
                inline: true,
                showOtherMonths: false,
                onRenderCell: this.generateCellContent
            }

            if (this.$root.lang === 'en') options.locale = localeEn

            this.datepicker = new AirDatepicker(datepicker, options)

            if (this.nextMonth) {
                this.datepicker.next()
                this.initTooltips()
            } else {
                this.initTooltips()
            }
        },
        initTooltips () {
            tippy('[data-tippy-content]', {
                theme: 'light-blue',
                arrow: true,
                placement: 'bottom-start',
                trigger: 'click',
                interactive: true,
                allowHTML: true,
                appendTo: document.body
            })
        },
        generateCellContent ({date, cellType}) {
            // const cellDate = moment(date).format('YYYY-MM-DD HH:mm:ss')

            const findedDays = this.items.filter(item => {
                const start = moment.unix(item.start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
                const end = moment.unix(item.end).set('hour', 23).set('minute', 59).set('second', 0).set('millisecond', 0)
                const current = moment(date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)

                return (
                    moment(current).isSame(moment(start)) || moment(current).isSame(moment(end)) ||
                    (moment(date).isAfter(moment(start)) && moment(date).isBefore(moment(end)))
                )
            })

            let dotsHtml = ''
            let tooltipHtml = ''

            if (findedDays.length > 0) {
                tooltipHtml += `<span class="tooltip-date">${moment(date).format('DD MMMM YYYY')}</span>`
                findedDays.forEach(item => {
                    dotsHtml += `<span class="dot dot--${item.type.id}" data-name="${item.name}"></span>`
                    tooltipHtml += `<span class="tooltip-text tooltip-text--${item.type.id}">${item.name}</span>`
                })
            }

            let template = `
                <div class="cell-content">
                    <span class="cell-content__date">${moment(date).format('DD')}</span>
                    <div class="cell-content__footer">${dotsHtml}</div>
                    <div class="cell-content__tooltip" ${tooltipHtml ? "data-tippy-content='" + tooltipHtml + "'" : ''}></div>
                </div>
            `

            return {
                html: template
            }
        }
    },
    mounted () {
        this.items = this.data.filter(item => {
            const currentMonth = moment().month() + 1
            const month = moment.unix(item.start).utc().add(3, 'hours').format('M')
            return (+month === currentMonth || +month === currentMonth + 1) && !item.withoutDate
        })

        this.init()
    }
}
</script>

<style lang="scss">
$ir: #4DA7B2;
$bussiness: #9D81EC;
$production:#0077C8;
$corp: #D25C5C;
$fin: #E18836;
$esg: #7BC18E;

.calendar-block {

    .air-datepicker {
        width: 100%;
        max-width: 380px;
        border: none;

        @include breakpoint (mobile) {
            max-width: 100%;
        }
    }

    .air-datepicker-cell {
        position: relative;
        width: 48px;
        height: 48px;
        font-size: 20px;
        color: $token-colors-gray-60;
        width: calc(100% / 7);
    }

    .air-datepicker-body--cells {
        display: flex;
        flex-wrap: wrap;
    }

    .air-datepicker-cell {
        &.-current- {
            .cell-content__date {
                background: $token-colors-blue-50;
                color: $token-colors-white;
            }
        }

        &.-other-month- {
            visibility: hidden;
            position: none;
        }

        &.-selected-,
        &.-focus- {
            background: $token-colors-white;
        }

        &.-focus-:not(.-current-) {
            background: $token-colors-white;

            .cell-content__date {
                background: $token-colors-blue-10;
            }
        }
    }

    .air-datepicker-nav--title {
        @include typo-level(XL);
        font-weight: 400;
        color: $token-colors-gray-60;
        pointer-events: none;
    }

    .air-datepicker-body--day-name {
        color: $token-colors-gray-30;
        font-size: 14px;
        text-transform: none;
    }

    .air-datepicker-nav--action {
        display: none;
    }

    .air-datepicker-nav {
        border: none;
        padding-bottom: 32px;
        justify-content: center;
    }

    .air-datepicker--content {
        padding: 0;
    }

}

.tooltip-date {
    color: $token-colors-gray-35;
    font-weight: 600;
    font-size: 13px;
    @include margin-level(bottom, 2XS);
    display: block;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.tooltip-text {
    position: relative;
    color: $token-colors-gray-60;
    @include typo-level(M);
    padding-left: $token-spacers-2-xs;
    text-align: left;
    display: block;

    @include breakpoint (mobile) {
        margin-left: 0;
    }

    & + & {
        margin-top: $token-spacers-2-xs;
    }

    &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: $token-colors-gray-30;
        left: 0;
        top: 0.5em;
        border-radius: 50%;
    }

    &--147 {
        &:before {
            background: $esg;
        }
    }

    &--73 {
        &:before {
            background: $ir;
        }
    }

    &--77 {
        &:before {
            background: $corp;
        }
    }

    &--75 {
        &:before {
            background: $production;
        }
    }

    &--74 {
        &:before {
            background: $bussiness;
        }
    }

    &--76 {
        &:before {
            background: $fin;
        }
    }
}

.cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;

    &__tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__date {
        width: 32px;
        height: 32px;
        border-radius: 4px;
    }

    &__footer {
        position: absolute;
        bottom: -4px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

    .dot {
        width: 100%;
        height: 4px;
        background: $token-colors-gray-35;
        display: block;
        margin-top: 2px;
        border-radius: 8px;

        &--147 {
            background: $esg;
        }

        &--73 {
            background: $ir;
        }

        &--77 {
            background: $corp;
        }

        &--75 {
            background: $production;
        }

        &--74 {
            background: $bussiness;
        }

        &--76 {
            background: $fin;
        }
    }
}
</style>
