import * as THREE from 'three'
import gsap from 'gsap'

export default function SphereBackground () {
    let canvas = document.querySelector('canvas')
    if (!canvas) return

    let width = canvas.offsetWidth
    let height = canvas.offsetHeight

    let colors = [
        new THREE.Color(0x4396BF),
        new THREE.Color(0x4396BF),
        new THREE.Color(0x4396BF)
    ]

    let renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2 : 1)
    renderer.setSize(width, height)
    renderer.setClearColor(0x000000, 0)

    let scene = new THREE.Scene()

    let raycaster = new THREE.Raycaster()
    raycaster.params.Points.threshold = 6

    let camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 2000)
    camera.position.set(0, 0, 350)

    let galaxy = new THREE.Group()
    scene.add(galaxy)

    // Create dots
    let loader = new THREE.TextureLoader()
    loader.crossOrigin = ''
    let dotTexture = loader.load('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAz1BMVEX////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////LRjS1AAAARHRSTlMAAXQGVQ0Qf+1UxKXMkNth67fqI6StG/IzjPCE8e7XYFbVJI0dfcvjgMPY7Pnlpjsi4DrW2hweiQyPEoqLgdmI5PX63dUi/vUAAAFvSURBVHhepddnU/JAAATgvUgSUkFBqhQFewd7e9v+/9/0jiPjiIaQ3D7f72aSa7tYpRXsRweHbbJ9eBDtBy2U0pkM+c1w0kFBrrPLTLuOi/U8v8qVqr6HfGZUZ676yCDHXsi1wgZWquywgJ0KspkuC+oaZNiIWFi0kTF+kyVs/pjBNFlK02DZL5Z0jyUBS1tai6seS+td4ZM5poVjgwWMaKWGBW+LVrY8fPBpycc7PFRpqerinUNrDgCYG1pLDYAZBTMAjxQkAIYUnAC3lEwRUDJGn5I+tinZRkxJjHNKrtGm5BTUnMkTyJ8g/0R5GeWNpG/lsXqYppTcAScUvAFI1CvtkoJLACbVrnX9YYE7oKWBKz6uR+rz/oSFmhgwYEJaCA0+NWxCVgNfVMSYBzyzpBc56qphW437auEQK49cunKY2rraVzPI5x3lFc9Xr1D1nTPT3HFRUCdJ+U2adFBKK/Av4t9/+HfwL77wV9f//7ErSpBKzt70AAAAAElFTkSuQmCC')
    let dotsAmount = 3000
    let dotsGeometry = new THREE.Geometry()
    let positions = new Float32Array(dotsAmount * 3)

    let sizes = new Float32Array(dotsAmount)
    let colorsAttribute = new Float32Array(dotsAmount * 3)
    for (let i = 0; i < dotsAmount; i++) {
        let vector = new THREE.Vector3()

        vector.color = Math.floor(Math.random() * colors.length)
        vector.theta = Math.random() * Math.PI * 2
        vector.phi =
            (1 - Math.sqrt(Math.random())) *
            Math.PI /
            2 *
            (Math.random() > 0.5 ? 1 : -1)

        vector.x = Math.cos(vector.theta) * Math.cos(vector.phi)
        vector.y = Math.sin(vector.phi)
        vector.z = Math.sin(vector.theta) * Math.cos(vector.phi)
        vector.multiplyScalar(120 + (Math.random() - 0.5) * 5)
        vector.scaleX = 5

        if (Math.random() > 0.5) {
            moveDot(vector, i)
        }
        dotsGeometry.vertices.push(vector)
        vector.toArray(positions, i * 3)
        colors[vector.color].toArray(colorsAttribute, i * 3)
        sizes[i] = 5
    }

    function moveDot (vector, index) {
        let tempVector = vector.clone()
        tempVector.multiplyScalar((Math.random() - 0.5) * 0.2 + 1)
        gsap.to(vector, Math.random() * 3 + 3, {
            x: tempVector.x,
            y: tempVector.y,
            z: tempVector.z,
            yoyo: true,
            repeat: -1,
            delay: -Math.random() * 3,
            ease: 'power0.easeNone',
            onUpdate: function () {
                attributePositions.array[index * 3] = vector.x
                attributePositions.array[index * 3 + 1] = vector.y
                attributePositions.array[index * 3 + 2] = vector.z
            }
        })
    }

    let bufferWrapGeom = new THREE.BufferGeometry()
    let attributePositions = new THREE.BufferAttribute(positions, 3)
    bufferWrapGeom.addAttribute('position', attributePositions)
    let attributeSizes = new THREE.BufferAttribute(sizes, 1)
    bufferWrapGeom.addAttribute('size', attributeSizes)
    let attributeColors = new THREE.BufferAttribute(colorsAttribute, 3)
    bufferWrapGeom.addAttribute('color', attributeColors)
    let shaderMaterial = new THREE.ShaderMaterial({
        uniforms: {
            texture: {
                value: dotTexture
            }
        },
        vertexShader: document.getElementById('wrapVertexShader').textContent,
        fragmentShader: document.getElementById('wrapFragmentShader').textContent,
        transparent: true
    })
    let wrap = new THREE.Points(bufferWrapGeom, shaderMaterial)
    scene.add(wrap)

    // Create white segments
    let segmentsGeom = new THREE.Geometry()
    let segmentsMat = new THREE.LineBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.3,
        vertexColors: THREE.VertexColors
    })
    for (let i = dotsGeometry.vertices.length - 1; i >= 0; i--) {
        let vector = dotsGeometry.vertices[i]
        for (let j = dotsGeometry.vertices.length - 1; j >= 0; j--) {
            if (i !== j && vector.distanceTo(dotsGeometry.vertices[j]) < 12) {
                segmentsGeom.vertices.push(vector)
                segmentsGeom.vertices.push(dotsGeometry.vertices[j])
                segmentsGeom.colors.push(colors[vector.color])
                segmentsGeom.colors.push(colors[vector.color])
            }
        }
    }
    let segments = new THREE.LineSegments(segmentsGeom, segmentsMat)
    galaxy.add(segments)

    let hovered = []
    let prevHovered = []
    function render (a) {
        let i
        dotsGeometry.verticesNeedUpdate = true
        segmentsGeom.verticesNeedUpdate = true

        raycaster.setFromCamera(mouse, camera)
        let intersections = raycaster.intersectObjects([wrap])
        hovered = []
        if (intersections.length) {
            for (i = 0; i < intersections.length; i++) {
                let index = intersections[i].index
                hovered.push(index)
                if (prevHovered.indexOf(index) === -1) {
                    onDotHover(index)
                }
            }
        }
        for (i = 0; i < prevHovered.length; i++) {
            if (hovered.indexOf(prevHovered[i]) === -1) {
                mouseOut(prevHovered[i])
            }
        }
        prevHovered = hovered.slice(0)
        attributeSizes.needsUpdate = true
        attributePositions.needsUpdate = true
        renderer.render(scene, camera)
    }

    function onDotHover (index) {
        dotsGeometry.vertices[index].tl = gsap.timeline()
        dotsGeometry.vertices[index].tl.to(dotsGeometry.vertices[index], 1, {
            scaleX: 10,
            ease: 'elastic(2, 0.2)',
            onUpdate: function () {
                attributeSizes.array[index] = dotsGeometry.vertices[index].scaleX
            }
        })
    }

    function mouseOut (index) {
        dotsGeometry.vertices[index].tl.to(dotsGeometry.vertices[index], 0.4, {
            scaleX: 5,
            ease: 'power2.easeOut',
            onUpdate: function () {
                attributeSizes.array[index] = dotsGeometry.vertices[index].scaleX
            }
        })
    }

    function onResize () {
        canvas.style.width = ''
        canvas.style.height = ''
        width = canvas.offsetWidth
        height = canvas.offsetHeight
        camera.aspect = width / height
        camera.updateProjectionMatrix()
        renderer.setSize(width, height)
    }

    let mouse = new THREE.Vector2(-100, -100)
    function onMouseMove (e) {
        let canvasBounding = canvas.getBoundingClientRect()
        mouse.x = ((e.clientX - canvasBounding.left) / width) * 2 - 1
        mouse.y = -((e.clientY - canvasBounding.top) / height) * 2 + 1
    }

    gsap.ticker.add(render)
    window.addEventListener('mousemove', onMouseMove)
    let resizeTm
    window.addEventListener('resize', function () {
        resizeTm = clearTimeout(resizeTm)
        resizeTm = setTimeout(onResize, 200)
    })
}
