/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ship': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M30.406 11.305H17.531V6.188a.5.5 0 01.5-.5h11.875a.5.5 0 01.5.5v5.117zm1.5-5.117v5.117h5.219a2 2 0 012 2v9.976h-1.5v-9.976a.5.5 0 00-.5-.5H10.812a.5.5 0 00-.5.5v9.976h-1.5v-9.976a2 2 0 012-2h5.22V6.188a2 2 0 012-2h11.874a2 2 0 012 2zm-7.301 33.08l-.625-.893-.61.901a9.18 9.18 0 01-.452.61C21.9 41.14 20.288 42.45 18 42.45c-2.29 0-3.906-1.312-4.923-2.566l-.003-.004a8.396 8.396 0 01-.366-.478l-.65-.918-.598.953a7.263 7.263 0 01-.302.436c-.962 1.282-2.585 2.577-5.158 2.577v1.5c2.958 0 4.924-1.419 6.12-2.873 1.186 1.373 3.123 2.873 5.88 2.873 2.85 0 4.82-1.604 5.993-3.012 1.18 1.408 3.155 3.012 6.007 3.012 2.747 0 4.68-1.49 5.868-2.859 1.199 1.468 3.174 2.894 6.155 2.86l-.017-1.5c-2.585.03-4.21-1.268-5.173-2.56a7.667 7.667 0 01-.305-.444l-.596-.943-.648.907a8.217 8.217 0 01-.356.467l-.005.006C33.906 41.139 32.289 42.45 30 42.45c-2.288 0-3.906-1.309-4.93-2.564a11.485 11.485 0 01-.465-.62z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.635 21.95a1.25 1.25 0 01.717.006l17.38 5.343-3.593 7.32 1.347.66 4.485-9.138a1.75 1.75 0 00-.95-2.407l-4.864-1.845-.532 1.402 4.863 1.845a.25.25 0 01.136.344l-.223.455-17.608-5.413a2.75 2.75 0 00-1.577-.012L5.423 25.692l-.11-.224a.25.25 0 01.136-.343l4.864-1.845-.532-1.403-4.864 1.845a1.75 1.75 0 00-.95 2.407l4.485 9.14 1.346-.662-3.704-7.548 17.541-5.109z" _fill="#0077C8"/>'
  }
})
