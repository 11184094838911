<template>
    <div
        :style="cssVars"
        class="custom-card"
        ref="custom-card"
        data-custom-card>
        <img
            class="custom-card__img"
            :src="`/images/innovation/branches/${data.imgSrc}`"
            alt="" />
        <div class="custom-card__title-wrapper">
            <div
                class="custom-card__title-container"
                @click="toggleHiddenText">
                <p
                    class="custom-card__title"
                    v-html="data.title"></p>
                <div
                    class="custom-card__icon"
                    ref="icon">
                    <z-icon
                        name="round-arrow"
                        dir="down"
                        width="24"
                        height="24"></z-icon>
                </div>
            </div>
        </div>
        <div
            class="custom-card__content"
            ref="content">
            <p
                class="custom-card__content-title"
                v-html="data.title"></p>
            <div
                class="custom-card__list-wrapper"
                ref="list">
                <z-list class="custom-card__list">
                    <z-list-item
                        v-for="(item, index) in data.list"
                        :key="index">
                        <span
                            class="custom-card__list-item"
                            v-html="item"></span>
                    </z-list-item>
                </z-list>
                <z-button
                    kind="white"
                    class="custom-card__content-link"
                    :href="data.link"
                    tag="a">
                    <span
                        class="u-bold"
                        v-html="text.link"></span>
                    <template v-slot:right>
                        <z-icon
                            name="arrow-long"
                            dir="right"
                            width="24"
                            height="24"></z-icon>
                    </template>
                </z-button>
            </div>
        <div class="custom-card__gradient"></div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import CardHover from '../modules/card-hover'

export default {
    name: 'custom-card',
    mixins: [mixinDevice],
    props: {
        data: {
            type: Object,
            required: true
        },
        gradient: {
            type: String,
            default: '#2D4784'
        },
        gradientMobile: {
            type: String,
            default:
                'linear-gradient(180deg, #38588C00 14%, #38588C08 20%, #1371b9 38%, #2d4784 100%)'
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            cardAnimation: null,
            desktopScreens: ['desktop', 'laptop'],
            isContentVisible: false,
            tl: null,
            text: {
                link: localize({
                    ru: 'Посмотреть направление',
                    en: 'Explore this area'
                })
            }
        }
    },
    computed: {
        cssVars () {
            return {
                '--gradient': this.gradient,
                '--gradient-mobile': this.gradientMobile
            }
        }
        // getComponentTag () {
        //     return this.desktopScreens.includes(this.device) ? 'a' : 'div'
        // }
    },
    watch: {
        device () {
            if (this.desktopScreens.includes(this.device) && !this.cardAnimation) {
                this.tl && this.tl.revert()
                this.cardAnimation = new CardHover(this.$refs['custom-card'])
                this.cardAnimation.init()
            } else if (!this.desktopScreens.includes(this.device) && this.cardAnimation) {
                this.cardAnimation.revert()
                this.cardAnimation.removeEvents()
                this.cardAnimation = null
            }
        }
    },
    mounted () {
        if (this.desktopScreens.includes(this.device)) {
            this.cardAnimation = new CardHover(this.$refs['custom-card'])
            this.cardAnimation.init()
        }
    },
    beforeDestroy () {
        if (this.cardAnimation) {
            this.cardAnimation.removeEvents()
        }
    },
    methods: {
        toggleHiddenText () {
            if (this.desktopScreens.includes(this.device)) {
                return
            }

            this.tl = gsap.timeline({ paused: true, defaults: { ease: 'power3' } })
            this.tl
                .fromTo(
                    this.$refs.content,
                    {
                        height: 0
                    },
                    {
                        yPercent: 0,
                        height: gsap.getProperty(this.$refs.list, 'height'),
                        duration: 0.3
                    }
                )
                .fromTo(
                    this.$refs.icon,
                    {
                        rotation: '0'
                    },
                    {
                        rotation: '180deg',
                        transformOrigin: 'center'
                    },
                    '<'
                )

            if (!this.isContentVisible) {
                this.isContentVisible = true
                this.tl.play()
            } else {
                this.isContentVisible = false
                this.tl.reverse()
            }
        }
    }
}
</script>

<style lang="scss">
.custom-card {
    $parent: &;
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 700px;
    height: 90vh;
    max-height: 600px;
    display: flex;
    // cursor: pointer;
    transition: flex 0.2s ease-out;
    border-top-right-radius: 80px;
    display: flex;
    flex-direction: column;
    background: var(--gradient);
    isolation: isolate; // handling safari bug

    @include breakpoint(tablet) {
        height: unset;
        min-height: 200px;
        border-top-right-radius: 40px;

        // v2
        cursor: default !important;
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;

        // v2
        @include breakpoint(tablet) {
            max-height: 360px;
            z-index: 0;
        }

        @include breakpoint(v-tablet) {
            max-height: 200px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        // v2
        @include breakpoint(tablet) {
            position: relative;
            z-index: 0;

            height: 0;
            opacity: 1 !important;
            visibility: visible !important;
            transform: none !important;
            transition: height 0.5s ease-out 0s;
        }
    }

    &__content-link {
        width: fit-content;
        margin-top: 24px;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &__title-wrapper {
        margin-top: auto;
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, M);
        @include padding-level(right, M);

        @include breakpoint(laptop) {
            @include padding-level(left, XS);
            @include padding-level(right, XS);
        }

        @include breakpoint(tablet) {
            height: 360px;
            display: flex;
            align-items: flex-end;
            @include padding-level(bottom, XS);
        }

        @include breakpoint(v-tablet) {
            height: 200px;
        }
    }

    &__title-container {
        @include breakpoint(tablet) {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
            cursor: pointer;
        }
    }

    &__title,
    &__content-title {
        color: $token-colors-white;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.01em;
        margin-bottom: 0 !important;
        font-size: 28px;
        position: relative;
        z-index: 1;

        @include breakpoint(laptop) {
            @include typo-level(2XL);
        }

        @include breakpoint(v-tablet) {
            @include typo-level(L);
        }
    }

    &__title {
        flex-grow: 1;
        transition: opacity 0.15s ease-out;

        @include breakpoint(tablet) {
            padding-right: 16px;
        }
    }

    // v2
    &__content-title {
        @include padding-level(top, S);
        @include padding-level(left, M);
        @include padding-level(right, M);

        @include breakpoint(laptop) {
            @include padding-level(left, XS);
            @include padding-level(right, XS);
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &__icon {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            position: relative;
            z-index: 1;
            transition: transform 0.25s ease-out;
        }

        .z-icon path {
            fill: $token-colors-white;
        }
    }

    &__list-wrapper {
        position: relative;
        z-index: 1;
        @include padding-level(bottom, S);
        @include padding-level(left, M);
        @include padding-level(right, M);

        @include breakpoint(laptop) {
            @include padding-level(left, XS);
            @include padding-level(right, XS);
        }

        &::before {
            content: '';
            position: absolute;
            top: -100%;
            bottom: 0;
            left: 0;
            right: 0;
        }

        @include breakpoint(tablet) {
            &:before {
                background: var(--gradient-mobile);
            }
        }
    }

    .z-list-item {
        @include margin-level(top, XS, false, true);

        &::before {
            background-color: $token-colors-white !important;
        }
    }

    &__list-item {
        color: $token-colors-white;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.01em;
        @include typo-level(XL);

        @include breakpoint(tablet) {
            @include typo-level(M);
        }
    }

    &__gradient {
        position: absolute;
        top: -50%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        background: var(--gradient-mobile);
        // v2
        @include breakpoint(tablet) {
            display: none;
        }
    }
}
</style>
