/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_tourism': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M17.25 15.25v9a.75.75 0 11-1.5 0v-9a.75.75 0 111.5 0zm2.25-.75a.75.75 0 00-.75.75v9a.75.75 0 101.5 0v-9a.75.75 0 00-.75-.75zm3 0a.75.75 0 00-.75.75v9a.75.75 0 101.5 0v-9a.75.75 0 00-.75-.75zM27 13v13.5a1.5 1.5 0 01-1.5 1.5H24v1.5a.75.75 0 11-1.5 0V28h-6v1.5a.75.75 0 11-1.5 0V28h-1.5a1.5 1.5 0 01-1.5-1.5V13a1.5 1.5 0 011.5-1.5h2.25V9.25A2.25 2.25 0 0118 7h3a2.25 2.25 0 012.25 2.25v2.25h2.25A1.5 1.5 0 0127 13zm-9.75-1.5h4.5V9.25A.75.75 0 0021 8.5h-3a.75.75 0 00-.75.75v2.25zm8.25 15V13h-12v13.5h12z" _fill="#fff"/>'
  }
})
