/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'industrial': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<g clip-path="url(#clip0_9226_32289)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M42.535 23.671h-5.74L25.309 10.813a.985.985 0 00-.734-.328h-6.843V8.82l2.551-2.526a.984.984 0 00.292-.699V.984A.984.984 0 0019.591 0H.984A.984.984 0 000 .984v4.611a.98.98 0 00.292.699L2.844 8.82v20.572H.985a.984.984 0 00-.984.984v12.158c0 .543.441.983.984.983h41.551c.543 0 .984-.44.984-.983V24.655a.984.984 0 00-.985-.984zm-18.4-11.218l10.021 11.218H26.07a.983.983 0 00-.736.331l-4.777 5.39h-2.825V12.454h6.403v-.001zM4.811 25.574h2.207a.984.984 0 100-1.968H4.811v-2.405h2.207a.984.984 0 100-1.967H4.811v-2.406h2.207a.984.984 0 100-1.967H4.811v-2.405h2.207a.985.985 0 000-1.968H4.811V8.413a.98.98 0 00-.292-.699L1.968 5.188V1.971h16.639v3.217l-2.552 2.526a.984.984 0 00-.292.699v20.982H4.81l.001-3.821zm36.74 15.976H1.968v-3.753h19.151a.984.984 0 100-1.967H1.968v-4.47H21c.282 0 .55-.12.737-.331l4.776-5.39h15.039l-.001 15.911zM28.81 37.797h7.486a.984.984 0 000-1.967H28.81a.983.983 0 100 1.967zm7.486-5.247H28.81a.985.985 0 010-1.968h7.486a.983.983 0 110 1.968z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_32289"><path pid="1" _fill="#fff" d="M0 0h44v44H0z"/></clipPath></defs>'
  }
})
