/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mic': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.4 6a3.6 3.6 0 017.2 0v5a3.6 3.6 0 01-7.2 0V6zM12 3.6A2.4 2.4 0 009.6 6v5a2.4 2.4 0 104.8 0V6A2.4 2.4 0 0012 3.6zm6.6 6.8a.6.6 0 01.6.6 7.2 7.2 0 01-14.4 0A.6.6 0 116 11a6 6 0 1012 0 .6.6 0 01.6-.6zm-6 8.6a.6.6 0 10-1.2 0v2a.6.6 0 101.2 0v-2z" _fill="#0077C8"/>'
  }
})
