/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo-for-contactcard': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.288 32.422v-7.445l11.424 7.442v.003h.005l.007.005.003-.005a11.426 11.426 0 008.064-19.503 11.425 11.425 0 00-8.079-3.347v7.444l-11.42-7.439-.004.008v-.013a11.43 11.43 0 00-8.079 3.347 11.425 11.425 0 008.079 19.503zm11.424-1.435V18.448l-11.424-7.442v12.539l11.424 7.442zM8.058 13.767a10.225 10.225 0 016.03-2.924V31.15a10.225 10.225 0 01-6.03-17.384zm25.884 0a10.225 10.225 0 00-6.03-2.924V31.15a10.225 10.225 0 006.03-17.384z" _fill="#0077C8"/>'
  }
})
