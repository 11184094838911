<template>
    <div class="mp-calendar">
        <div
            v-for="(item, idx) in sortedEvents"
            class="mp-calendar__item"
            :key="idx">
            <component
                v-if="isFuture(item.date.start, item.date.end)"
                :is="isLinkTag(item)"
                :href="item.link"
                class="mp-calendar__item-card">
                <date-block
                    :date="item.date.start"
                    :date-end="item.date.end"
                    :without-date="item.withoutDate.value"
                    theme="white-alt" />
                <span
                    class="u-black-60-text u-normal text-size-m"
                    v-html="item.name"></span>
            </component>
            <div
                v-else
                class="mp-calendar__item-block">
                <tag
                    class="u-bottom-margin--3xs"
                    theme="event-light-blue"
                    size="s"
                    :name="end"
                    selected
                    disabled
                    :interactive="interactive"></tag>
                <component
                    :is="isLinkTag(item)"
                    v-if="item.name"
                    :href="item.link"
                    class="mp-calendar__item-title">
                    {{ item.name }}
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
import moment from 'moment'

export default {
    name: 'events-list',
    data () {
        return {
            end: localize({
                ru: 'Завершено',
                en: 'Finished'
            }),
            dateNow: moment().startOf('minute').unix()
        }
    },
    mixins: [mixinDevice],
    props: {
        items: {
            type: Array,
            default: () => []
        },
        deviceWithoutIcon: {
            type: Array,
            default: () => []
        },
        interactive: {
            type: Boolean,
            default: true
        },
        canBeClosed: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        isLinkTag (item) {
            return item.link ? 'a' : 'p'
        },
        isFuture (start, end) {
            if (!this.canBeClosed) return true

            return start >= this.dateNow || end >= this.dateNow
        },
        isArchive (end) {
            return end < this.dateNow
        }
    },
    computed: {
        dividedItemsOnArchiveAndFuture () {
            const future = []
            const archive = []
            this.items.forEach((el) => {
                if (this.isArchive(el.date.end)) {
                    archive.push(el)
                } else if (this.isFuture(el.date.start, el.date.end)) {
                    future.push(el)
                } else {
                    console.error(
                        'Дата конца не может быть до "Сейчас", когда дата начала после "Сейчас"'
                    )
                }
            })

            return {
                future,
                archive
            }
        },

        sortedEvents () {
            const future = this.dividedItemsOnArchiveAndFuture.future
            const archive = this.dividedItemsOnArchiveAndFuture.archive

            future.sort((cur, prev) => {
                return cur.date.start - prev.date.start
            })

            archive.sort((cur, prev) => {
                return prev.date.start - cur.date.start
            })

            const resultArray = [...future, ...archive]

            return resultArray.slice(0, 2)
        }
    }
}
</script>
<style lang="scss">
.mp-calendar {
    $parent: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 412px;

    @include breakpoint(v-tablet) {
        max-width: 100%;
    }

    &__item-card {
        text-decoration: none;
        border-radius: 0px 20px;
        background-color: $token-colors-white;
        padding: 8px 8px 8px 0;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: box-shadow 0.25s ease-out, color 0.25s ease-out;
        overflow: hidden;

        .date-block__container {
            width: 80px;
            padding: 11px 7px;
        }

        &:hover {
            box-shadow: 0px 8px 24px 0px rgba(0, 32, 51, 0.12),
                0px 4px 4px 0px rgba(0, 32, 51, 0.04);
            color: $token-colors-navy-blue;
        }
    }

    // a.mp-calendar__item-card {
        // &:hover {
        //     box-shadow: 0px 8px 24px 0px rgba(0, 32, 51, 0.12),
        //         0px 4px 4px 0px rgba(0, 32, 51, 0.04);
        // }
    // }

    &__item-block {
        @include padding-level(left, 2XS);
        @include padding-level(right, XS);
    }

    &__item-title {
        @include typo-level(M);
        line-height: 1.5;
        display: block;
        text-decoration: none;
        color: $token-colors-gray-60;
        font-weight: normal;
        transition: color 0.25s ease-out;

        &:hover {
            color: #004c97;
        }
    }

    &__item-date + &__item-title {
        margin-top: $token-spacers-2-xs;
    }

    // p.mp-calendar__item-title {
    //     &:hover {
    //         color: $token-colors-gray-60;
    //     }
    // }

    // a.mp-calendar__item-title {
    //     &:hover {
    //         color: #004c97;
    //     }
    // }

    &__item {
        margin-top: 40px;
        width: 100%;

        @include breakpoint(laptop) {
            @include margin-level(top, M);
        }
        @include breakpoint(v-tablet) {
            width: 100%;
        }
    }
}
</style>
