<template>
    <div v-if="project && currentSubProject" class="project-popup">
        <div class="project-popup__wrapper">
            <div class="project-popup__close" @click="closePopup">
                <z-icon width="48" height="48" name="close" />
            </div>
            <div class="project-popup__header">
                <z-caption
                    class="project-popup__title"
                    decor-right
                    weight="600"
                >
                    <span v-if="isMobileDevice" v-html="currentSubProject.title"></span>
                    <span v-else v-html="project.title"></span>
                </z-caption>
                <p
                    v-if="project.previewText"
                    class="project-popup__preview-text"
                    v-html="project.previewText"
                ></p>
                <div
                    v-if="project.subProjects.length > 1"
                    class="project-popup__tabs-label"
                >
                    <custom-button
                        v-for="subProject in project.subProjects"
                        :key="subProject.id"
                        :is-active="currentSubProject.id === subProject.id"
                        @click="toggleSubProject(subProject.id)"
                    >
                        <span v-html="subProject.title"></span>
                    </custom-button>
                </div>
            </div>

            <div class="project-popup__body">
                <div class="project-popup__tabs-content">
                    <project-detail
                        v-for="subProject in project.subProjects"
                        v-show="currentSubProject.id === subProject.id"
                        :key="subProject.id"
                        :ref="`projectDetail-${subProject.id}`"
                        :sub-project="subProject"
                    />
                </div>
            </div>

            <button class="project-popup__back" @click="closePopup">
                <div class="project-popup__back-icon">
                    <z-icon width="24" height="24" dir="left" name="arrow-long" />
                </div>
                <span v-html="text.back"></span>
            </button>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
import CustomButton from './CustomButton.vue'
import ProjectDetail from './ProjectDetail.vue'

export default {
    name: 'project-popup',
    mixins: [mixinDevice],
    components: {
        CustomButton,
        ProjectDetail
    },
    props: {
        project: Object,
        subProjectId: String
    },
    data () {
        return {
            currentSubProject: null,
            text: {
                back: localize({
                    ru: 'Назад',
                    en: 'Back'
                })
            }
        }
    },
    computed: {
        isMobileDevice () {
            return this.device === 'mobile'
        }
    },
    methods: {
        toggleSubProject (subProjectId) {
            this.currentSubProject = this.project.subProjects.find(item => item.id === subProjectId)
        },
        closePopup () {
            this.$root.$bus.$emit('close-modal', { id: 'projectPopup' })
        }
    },
    mounted () {
        this.currentSubProject = this.project.subProjects.find(item => item.id === this.subProjectId) || this.project.subProjects[0]
    }
}
</script>

<style lang="scss">
.project-popup {
    &__close {
        position: absolute;
        top: 54px;
        right: 68px;
        z-index: 2;
        cursor: pointer;

        @include breakpoint(tablet) {
            top: 32px;
            right: 32px;
        }

        @include breakpoint(mobile) {
            top: 16px;
            right: 16px;
        }
    }

    &__wrapper {
        border-radius: 0 40px 0 0;
        background: #F4F8FF;
        padding: $token-spacers-m 88px 66px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 2px 2px 0 rgba(0, 32, 51, 0.25), 0 2px 8px 0 rgba(0, 32, 51, 0.35);

        @include breakpoint(tablet) {
            padding: $token-spacers-m 60px $token-spacers-m;
        }

        @include breakpoint(v-tablet) {
            padding: $token-spacers-2-xl $token-spacers-m $token-spacers-m;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-2-xl $token-spacers-2-xs $token-spacers-2-xs;
        }
    }

    &__header {
        padding-right: $token-spacers-xl;
        padding-bottom: $token-spacers-s;
        position: relative;
        z-index: 1;

        @include breakpoint(mobile) {
            padding-right: 0;
        }
    }

    &__tabs-label {
        display: flex;
        flex-wrap: wrap;
        gap: $token-spacers-xs;
        margin-bottom: $token-spacers-s;

        @include breakpoint(tablet) {
            gap: $token-spacers-2-xs;
        }

        @include breakpoint(mobile) {
            justify-content: center;
            display: none;
        }
    }

    &__tabs-content {
        margin-bottom: $token-spacers-2-xs;
    }

    &__back {
        display: flex;
        align-items: center;
        gap: $token-spacers-2-xs;
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.2px;
        color: $token-colors-blue;
        background: transparent;
        border: none;
    }

    &__back-icon {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $token-colors-white;
        border-radius: 50%;
    }

    &__preview-text {
        font-size: 22px;
        line-height: 140%;
        letter-spacing: 0.22px;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }
}
</style>
