/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book-open': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.25 4.6c-.482 0-.795.001-1.031.027-.224.024-.306.065-.351.098a.65.65 0 00-.144.143c-.033.045-.073.127-.098.351-.025.236-.026.55-.026 1.031v8.5c0 .482 0 .796.026 1.031.025.225.065.306.098.351.04.056.089.104.144.144.045.033.127.074.35.098.237.026.55.026 1.032.026H9c.922 0 1.763.347 2.4.917V10.75c0-1.42 0-2.436-.086-3.222-.084-.775-.244-1.256-.516-1.63a3.15 3.15 0 00-.697-.696c-.373-.271-.853-.432-1.629-.516C7.686 4.601 6.67 4.6 5.25 4.6zm6.75.96a3.281 3.281 0 00-.23-.366 4.35 4.35 0 00-.963-.963c-.612-.445-1.328-.643-2.205-.738C7.743 3.4 6.662 3.4 5.286 3.4h-.067c-.442 0-.821 0-1.13.034-.326.035-.643.114-.926.32a1.85 1.85 0 00-.41.409c-.206.284-.284.6-.32.927-.033.308-.033.687-.033 1.129v8.563c0 .442 0 .82.033 1.129.036.326.114.643.32.927.114.157.253.295.41.41.283.205.6.284.927.32.308.032.687.032 1.129.032H9a2.4 2.4 0 012.4 2.4h1.2a2.4 2.4 0 012.4-2.4h3.781c.442 0 .821 0 1.13-.033.326-.035.643-.114.926-.32.157-.114.296-.252.41-.41.206-.283.284-.6.32-.926.033-.308.033-.687.033-1.13V6.22c0-.442 0-.821-.033-1.129-.036-.326-.114-.643-.32-.927a1.849 1.849 0 00-.41-.41c-.283-.205-.6-.284-.927-.32-.308-.033-.687-.033-1.129-.033h-.067c-1.376 0-2.457 0-3.316.093-.877.095-1.593.293-2.205.738a4.35 4.35 0 00-.962.963c-.086.118-.163.24-.231.366zm.6 11.757A3.586 3.586 0 0115 16.4h3.75c.482 0 .795 0 1.031-.026.224-.024.306-.065.351-.098a.654.654 0 00.144-.143c.032-.046.073-.127.098-.352.025-.235.026-.549.026-1.03v-8.5c0-.483 0-.796-.026-1.032-.025-.224-.066-.306-.098-.35a.652.652 0 00-.144-.144c-.045-.033-.127-.074-.35-.098-.237-.026-.55-.027-1.032-.027-1.42 0-2.436.001-3.222.086-.776.084-1.256.245-1.63.516a3.15 3.15 0 00-.696.697c-.272.373-.432.854-.516 1.629-.085.786-.086 1.803-.086 3.222v6.567z" _fill="#0077C8"/>'
  }
})
