<template>
    <z-caption
        class="innovation-title"
        :class="classObject"
        decor-right
        tag="h2"
        size="3xl"
        weight="600">
        <span>{{ text }}</span>
    </z-caption>
</template>

<script>
export default {
    name: 'section-title',
    props: {
        text: {
            type: String,
            required: true
        },
        hasPadding: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classObject() {
            return { 'innovation-title--has-padding': this.hasPadding }
        }
    }
}
</script>

<style lang="scss">
.innovation-title {
    margin-bottom: 0 !important;

    &--has-padding {
        padding-bottom: 100px;

        @include breakpoint(laptop) {
            @include padding-level(bottom, 2XL);
        }

        @include breakpoint(v-tablet) {
            @include padding-level(bottom, L);
        }
    }
}
</style>
