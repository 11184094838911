/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tank-storage': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.132 7.795v.001l.007 1.705h.238a.6.6 0 01.107-.009h4.845a.6.6 0 01.109.01l20.958-.01.008-1.696v-.001a.895.895 0 00-.891-.896h-24.49c-.49 0-.89.401-.891.896zm27.464 1.697l.008-1.693v-.003c0-1.15-.93-2.097-2.091-2.097h-24.49c-1.162 0-2.091.947-2.091 2.097V7.8l.007 1.696-.74-.003h-.002c-1.161 0-2.091.947-2.091 2.097v8.333h-.735a3.041 3.041 0 00-3.033 3.045v.005l.006.743h-.741A3.041 3.041 0 003.57 26.76V42.9H1.893a.6.6 0 000 1.2H47.107a.6.6 0 100-1.2h-1.678V11.59c0-1.151-.93-2.098-2.09-2.098h-.743zm-.6 1.2h-.005l-21.054.01-.008 3.085c.007.035.01.071.01.108v3.794l-.01 3.684a.61.61 0 01.01.109l-.01 3.683c.007.036.01.073.01.11l-.01 3.683c.007.036.01.073.01.11l-.01 3.683c.007.036.01.072.01.11l-.01 3.628c.007.036.01.072.01.108v6.304h23.29V11.589c0-.495-.4-.897-.89-.897h-1.343zM19.74 42.902v-2.289H16.076l.006 2.289h3.657zm-4.857 0l-.008-2.877V32.858c0-.036.003-.072.01-.107l-.01-3.684c0-.037.003-.073.01-.109l-.01-3.683c0-.037.003-.074.01-.11l-.01-3.683c0-.037.003-.074.01-.11l-.01-3.683c0-.037.003-.073.01-.11l-.01-3.683c0-.037.003-.073.01-.109l-.009-3.088-.335-.001h-.002l-1.341-.006h-.002c-.489 0-.889.402-.889.897V42.901h2.576zm-3.776 0V24.915H8.538V42.9h2.568zm-3.768 0V24.915h-.735c-1.01 0-1.833.827-1.833 1.845V42.9h2.568zm3.768-19.186v-2.593h-.735a1.841 1.841 0 00-1.833 1.843v.002l.006.748h2.562zm4.97-13.023l.006 2.593h3.648l.007-2.593h-3.662zm3.663 3.793h-3.664l.007 2.593h3.657v-2.593zm-.002 3.793h-3.662l.007 2.592h3.648l.007-2.593zm0 3.792h-3.662l.007 2.593h3.648l.007-2.593zm0 3.793h-3.662l.007 2.593h3.648l.007-2.593zm0 3.793h-3.662l.007 2.593h3.648l.007-2.593zm0 3.793h-3.663v2.538h3.656l.007-2.538zm.002 3.738h-3.665v2.226h3.665v-2.226z" _fill="#0077C8"/>'
  }
})
