import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
gsap.registerPlugin(SplitText)

class TextAnimation {
    constructor (container, paused = false) {
        if (!container) return
        this.textEl = container.querySelector('[data-animated-text]')
        this.initialPaused = paused
        this.tl = null
        this.split = null

        this.init()
    }

    init () {
        this.split = new SplitText(this.textEl, {
            type: 'words,chars',
            linesClass: 'u-overflow-hidden'
        })
        this.tl = gsap.timeline({ paused: this.initialPaused })
        this.tl.fromTo(
            this.split.chars,
            {
                autoAlpha: 0
            },
            {
                ease: 'circ.out',
                autoAlpha: 1,
                stagger: 0.05
            }
        )

        window.addEventListener('resize', () => {
            this.tl.progress(1)
            this.split.revert()
        })
    }
}

export default TextAnimation
