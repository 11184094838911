export const bondsEnData = [
    {
        title: 'Eurobond',
        bonds: [
            {
                name: 'Outstanding',
                id: 'outstanding',
                isin_footnote: true,
                items: [
                    {
                        tools: 'Eurobond 2026 (LPN)', // Инструмент
                        isin: 'XS2393505008; US55315NAE31', // ISIN
                        placement: '27 October 2021', // Дата размещения
                        redemption: '27 October 2026', // Дата погашения
                        coupon_payment: '27 October and 27 April', // Даты выплаты купона
                        nearest: '11.10.2024', // Ближайшая дата отсечки
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 500 million⁴', // Объем выпуска
                        coupon_rate: '2.80 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        showUpdate: true,
                        link: 'https://www.moex.com/ru/issue.aspx?board=TQCB&code=XS2393505008',
                        prospect: '/files/en/investors/debt/bonds/final-prospectus-2026.pdf', // Проспект
                        prospect_size: '7.1 MB',
                        update_link: '/files/en/investors/debt/bonds/update-2026.pdf',
                        update_size: '593 KB'
                    },
                    {
                        tools: 'Eurobond 2025 (LPN)', // Инструмент
                        isin: 'XS2134628069; US55315NAD57', // ISIN
                        placement: '11 September 2020', // Дата размещения
                        redemption: '11 September 2025', // Дата погашения
                        coupon_payment: '11 September and 11 March', // Даты выплаты купона
                        nearest: '27.08.2024', // Ближайшая дата отсечки
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 500 million³', // Объем выпуска
                        coupon_rate: '2.550 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        showUpdate: true,
                        link: 'https://www.moex.com/ru/issue.aspx?board=TQOD&code=XS2134628069&utm_source=www.moex.com&utm_term=XS2134628069#/bond_4', // Котировки бумаг
                        prospect: '/files/en/investors/debt/bonds/final-prospectus-2025.pdf', // Проспект
                        update_link: '/files/en/investors/debt/bonds/update-2025.pdf',
                        prospect_size: '5.8 MB',
                        update_size: '214 KB'
                    }
                ]
            },
            {
                name: 'Redeemed',
                id: 'redeemed',
                items: [
                    {
                        tools: 'Eurobond 2024 (LPN)', // Инструмент
                        isin: 'XS2069992258; US55315NAC74', // ISIN
                        placement: '28 October 2019', // Дата размещения
                        redemption: '28 October 2024', // Дата погашения
                        coupon_payment: '28 October and 28 April', // Даты выплаты купона
                        nearest: '11.10.2024', // Ближайшая дата отсечки
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 750 million', // Объем выпуска
                        coupon_rate: '3.375 %' // Ставка купона
                    },
                    {
                        tools: 'Eurobond 2023 (LPN)', // Инструмент
                        isin: 'XS1589324075; US55315NAA19', // ISIN
                        placement: '11 April 2017', // Дата размещения
                        redemption: '11 April 2023', // Дата погашения
                        coupon_payment: '11 October and 11 April', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 1.000 million', // Объем выпуска
                        coupon_rate: '4.10 %' // Ставка купона
                    },
                    {
                        tools: 'Eurobond 2022 (LPN)', // Инструмент
                        isin: 'XS1298447019; US55314WAC82', // ISIN
                        placement: '14 October 2015', // Дата размещения
                        redemption: '14 October 2022', // Дата погашения
                        coupon_payment: '14 October and 14 April', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 1.000 million', // Объем выпуска
                        coupon_rate: '6,625 %' // Ставка купона
                    },
                    {
                        tools: 'Eurobond 2022 (LPN)', // Инструмент
                        isin: 'XS1622146758', // ISIN
                        placement: '08 June 2017', // Дата размещения
                        redemption: '08 April 2022', // Дата погашения
                        coupon_payment: '8 October and 8 April', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 500 million', // Объем выпуска
                        showProspect: true,
                        showLink: true,
                        coupon_rate: '3.849 %' // Ставка купона
                    },
                    {
                        tools: 'Eurobond 2020 (LPN)', // Инструмент
                        isin: 'XS0982861287; US55314WAA27', // ISIN
                        placement: '28 October 2013', // Дата размещения
                        redemption: '28 October 2020', // Дата погашения
                        coupon_payment: '28 October and 28 April', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 1.000 million', // Объем выпуска
                        showProspect: true,
                        showLink: true,
                        coupon_rate: '5.55 %' // Ставка купона
                    },
                    {
                        tools: 'Eurobond 2018 (LPN)', // Инструмент
                        isin: 'XS0922134712', // ISIN
                        placement: '30 April 2013', // Дата размещения
                        redemption: '30 April 2018', // Дата погашения
                        coupon_payment: '30 October and 30 April', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: 'USD 750 million', // Объем выпуска
                        showProspect: true,
                        showLink: true,
                        coupon_rate: '4.375 %' // Ставка купона
                    }
                ]
            }
        ],
        contacts: [
            {
                href: 'emea.at.debt@citi.com',
                description: 'Contacts of the payment agent'
            },
            {
                href: 'nornickel@i2capmark.com',
                description: 'Trustee contacts'
            }
        ],
        footnotes: [
            '<sup>1</sup>&nbsp;International Securities Identification Number&nbsp;&mdash; a 12-digit alphanumeric code that uniquely identifies a security (assigned by national numbering agencies)',
            '<sup>2</sup>&nbsp;The date on which the list of local holders of Eurobonds (i.e. holders whose rights are taken into account by Russian depositories) holding Eurobonds on the corresponding date and entitled to receive payments on Eurobonds is fixed',
            '<sup>3</sup>&nbsp;The issue size, excluding the Substitute bonds, is&nbsp;USD 184.441 million',
            '<sup>4</sup>&nbsp;The issue size, excluding the Substitute bonds, is&nbsp;USD 166.515 million'
        ]
    },
    {
        title: 'Local bonds',
        bonds: [
            {
                name: 'Outstanding',
                id: 'outstanding',
                items: [
                    {
                        tools: 'Exchange-traded bonds, BО-001Р-08-USD', // Инструмент
                        isin: 'RU000A10B4K3', // ISIN
                        placement: '21 March 2025', // Дата размещения
                        redemption: '23 February 2030 (23 March 2026 put)', // Дата погашения
                        coupon_payment: 'Each 30 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: 'USD 800 million', // Объем выпуска
                        coupon_rate: '8.00%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО‑10', // Инструмент
                        isin: 'RU000A109TW9', // ISIN
                        placement: '22 October 2024', // Дата размещения
                        redemption: '26 September 2029 (04 October 2028 put)', // Дата погашения
                        coupon_payment: 'Each 30 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '100 billion rubles', // Объем выпуска
                        coupon_rate: 'CBR key rate + 1.1%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО‑001Р-07', // Инструмент
                        isin: 'RU000A1083A6', // ISIN
                        placement: '26 March 2024', // Дата размещения
                        redemption: '28 February 2029', // Дата погашения
                        coupon_payment: 'Each 30 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '100 billion rubles', // Объем выпуска
                        coupon_rate: 'CBR key rate + 1.3%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, ZO26-D', // Инструмент
                        isin: 'RU000A107C67', // ISIN
                        placement: '22 December 2023', // Дата размещения
                        redemption: '27 October 2026', // Дата погашения
                        coupon_payment: '27 October and 27 April', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: 'USD 333.485 million', // Объем выпуска
                        coupon_rate: '2.8%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, ZO25-D', // Инструмент
                        isin: 'RU000A107BL4', // ISIN
                        placement: '20 December 2023', // Дата размещения
                        redemption: '11 September 2025', // Дата погашения
                        coupon_payment: '11 September and 11 March', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: 'USD 315.559 million', // Объем выпуска
                        coupon_rate: '2.55%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО-09', // Инструмент
                        isin: 'RU000A1069N8', // ISIN
                        placement: '24 May 2023', // Дата размещения
                        redemption: '17 May 2028', // Дата погашения
                        coupon_payment: 'Each 91 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '60 billion rubles', // Объем выпуска
                        coupon_rate: 'RUONIA + 1.3%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО 001Р-06-CNY', // Инструмент
                        isin: 'RU000A105NL3', // ISIN
                        placement: '22 December 2022', // Дата размещения
                        redemption: '18 June 2026', // Дата погашения
                        coupon_payment: 'Each 91 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: 'CNY 5 billion', // Объем выпуска
                        coupon_rate: 'LPR 1Y + 0.1%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО 001Р-05-CNY', // Инструмент
                        isin: 'RU000A105ML5', // ISIN
                        placement: '19 December 2022', // Дата размещения
                        redemption: '15 December 2025', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: 'CNY 4 billion', // Объем выпуска
                        coupon_rate: '3.95%' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО 001Р-02', // Инструмент
                        isin: 'RU000A105A61', // ISIN
                        placement: '11 October 2022', // Дата размещения
                        redemption: '05 October 2027 (14 October 2025 put)', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '25 billion rubles', // Объем выпуска
                        coupon_rate: '9.75%' // Ставка купона
                    }
                ]
            },
            {
                name: 'Redeemed',
                id: 'Redeemed',
                items: [
                    {
                        tools: 'Exchange-traded bonds, BО‑001Р-01', // Инструмент
                        isin: 'RU000A100VQ6', // ISIN
                        placement: '01 October 2019', // Дата размещения
                        redemption: '24 September 2024', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '25 billion rubles', // Объем выпуска
                        coupon_rate: '7.20 %' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО-01', // Инструмент
                        isin: 'RU000A0JTPV9', // ISIN
                        placement: '28 February 2013', // Дата размещения
                        redemption: '25 February 2016', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '10 billion rubles', // Объем выпуска
                        coupon_rate: '7.90 %' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО-02', // Инструмент
                        isin: 'RU000A0JTPX5', // ISIN
                        placement: '28 February 2013', // Дата размещения
                        redemption: '25 February 2016', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '10 billion rubles', // Объем выпуска
                        coupon_rate: '7.90 %' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО-03', // Инструмент
                        isin: 'RU000A0JQYY1', // ISIN
                        placement: '03 August 2010', // Дата размещения
                        redemption: '30 July 2013', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '15 billion rubles', // Объем выпуска
                        coupon_rate: '7 %' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО-04', // Инструмент
                        isin: 'RU000A0JTPY3', // ISIN
                        placement: '28 February 2013', // Дата размещения
                        redemption: '25 February 2016', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '15 billion rubles', // Объем выпуска
                        coupon_rate: '7.90 %' // Ставка купона
                    },
                    {
                        tools: 'Exchange-traded bonds, BО-05', // Инструмент
                        isin: 'RU000A0JW5C7', // ISIN
                        placement: '19 February 2016', // Дата размещения
                        redemption: '12 February 2021', // Дата погашения
                        coupon_payment: 'Each 182 days starting from the offering date', // Даты выплаты купона
                        issuer: 'MMC Norilsk Nickel', // Эмитент
                        volume: '15 billion rubles', // Объем выпуска
                        coupon_rate: '11.60 %' // Ставка купона
                    }
                ]
            }
        ]
    }
]
