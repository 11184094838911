<template>
    <tippy-component
        class="z-tooltip"
        :interactive="setInteractive()"
        :theme="theme"
        :arrow="arrow"
        :trigger="trigger"
        :placement="placement"
        :class="classObject"
        ref="tippy-tooltip"
        @show="handleShow"
        @hide="handleHide"
    >
        <template v-slot:trigger>
            <div
                class="z-tooltip__trigger"
                :class="getTriggerClass"
                @mouseover="handleMouseEnter"
                @mouseleave="handleMouseLeave"
            >
                <slot />
                <z-icon
                    v-show="!iconHidden"
                    width="16"
                    height="16"
                    :name="computedIcon"
                    dir="down"
                    class="z-tooltip__icon"
                />
            </div>
        </template>
        <span>
            <div
                class="z-tooltip__close"
                @click="hideTooltip"
                v-show="trigger === 'click'"
            >
                <z-icon
                    width="16"
                    height="16"
                    name="close"
                />
            </div >
            <span>
                <span v-html="content" v-if="content"></span>
                <slot name="content" />
            </span>
        </span>
    </tippy-component>
</template>

<script>
import { TippyComponent } from 'vue-tippy'

export default {
    name: 'z-tooltip',
    components: {
        TippyComponent
    },
    props: {
        text: String,
        theme: {
            type: String,
            default: 'light'
        },
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'right'
        },
        content: {
            type: String,
            required: true
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        interactive: {
            type: Boolean,
            default: false
        },
        iconHidden: Boolean,
        isShown: false,
        icon: {
            type: String,
            default: 'tooltip'
        },
        iconHover: {
            type: String,
            default: 'tooltip-hover'
        },
        isTriggerAligned: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isInteractive: false,
            isTooltipVisible: false,
            isHovered: false
        }
    },
    mounted () {
        this.isTooltipVisible = this.isShown
    },
    computed: {
        computedIcon() {
            return this.isTooltipVisible || this.isHovered ? this.iconHover : this.icon
        },
        classObject () {
            return [
                { 'z-tooltip--click': this.trigger === 'click' },
                { 'z-tooltip--without-icon': this.iconHidden },
                { 'z-tooltip--empty': !this.$slots.default },
                { 'is-shown': this.isTooltipVisible },
                `z-tooltip--theme-${this.theme}`
            ]
        },
        getTriggerClass () {
            return this.isTriggerAligned && 'z-tooltip__trigger--aligned'
        }
    },
    methods: {
        handleShow () {
            this.isTooltipVisible = true
        },
        handleHide () {
            this.isTooltipVisible = false
        },
        hideTooltip () {
            const tippy = this.$refs['tippy-tooltip']
            tippy.tip.hide()
        },
        setInteractive () {
            this.isInteractive = this.trigger === 'click' || this.interactive
            return this.isInteractive
        },
        handleMouseEnter() {
            this.isHovered = true
        },
        handleMouseLeave() {
            this.isHovered = false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
