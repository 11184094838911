<template>
    <div
        v-if="data && data.length"
        :class="[
            'modern-thumbs',
            'modern-thumbs--theme-' + theme,
            { 'modern-thumbs--disabled': disabled }
        ]"
    >
        <div class="modern-thumbs__container container" ref="container" :style="{
            '--thumb-min-height': currentThumbMinHeight
        }">
            <p
                v-if="title && !titleTop"
                v-html="title"
                class="modern-thumbs__title"
            ></p>

            <div class="modern-thumbs__thumbs" ref="thumbsContainer">
                <div
                    v-for="(item, index) in data"
                    :key="index"
                    ref="thumbs"
                    :class="['modern-thumbs__item', {'modern-thumbs__item--active': index === activeIndex}]"
                    @click="thumbClickHandler(index)"
                >
                    <div class="modern-thumbs__item-wrapper">
                        <div
                            ref="defaultContent"
                            class="modern-thumbs__item-content modern-thumbs__item-content--default"
                        >
                            <div class="modern-thumbs__item-body">
                                <p
                                    class="modern-thumbs__item-tag"
                                    v-html="item.tab || defaultTag"
                                ></p>
                                <p
                                    class="modern-thumbs__item-title"
                                    v-html="item.name"
                                ></p>
                            </div>
                        </div>

                        <div ref="activeContent" class="modern-thumbs__item-content modern-thumbs__item-content--active">
                            <z-link
                                v-if="theme === 'link'"
                                :href="(item.newsLink && item.newsLink.value) || (item.newsConnection && item.newsConnection.detailPageUrl)"
                                @click="handlerTrackGoal(goalNameThumb)"
                            >
                                <p
                                    class="modern-thumbs__item-tag"
                                    v-html="item.tab || defaultTag"
                                ></p>

                                <div
                                    ref="activeTitle"
                                    class="modern-thumbs__item-title"
                                >
                                    <span v-html="getFormatedTitle(item.name, 'active')"></span>
                                </div>
                            </z-link>

                            <div v-else class="modern-thumbs__item-body modern-thumbs__item-body--active">
                                <div
                                    ref="activeTitle"
                                    class="modern-thumbs__item-title"
                                >
                                    <span v-html="item.name"></span>
                                </div>

                                <div
                                    v-if="item.detailText"
                                    class="modern-thumbs__item-description"
                                >
                                    <vue-raw :raw="item.detailText" />
                                </div>

                                <div>
                                    <z-link
                                        v-show="item.link && item.linkText"
                                        class="modern-thumbs__item-link"
                                        theme="white"
                                        :href="item.link"
                                    >
                                        <span v-html="item.linkText"></span>

                                        <template #right>
                                            <z-icon name="arrow-long" dir="right"/>
                                        </template>
                                    </z-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import { mixinDevice } from '@/utils/mixin'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'modern-thumbs',
    mixins: [mixinDevice],
    emits: ['changeStarted', 'changeCompleted'],
    props: {
        data: Array,
        title: String,
        titleTop: Boolean,
        theme: String,
        defaultTag: String,
        activeIndex: Number,
        goalNameThumb: String
    },
    data () {
        return {
            disabled: false,
            container: null,
            thumbContainer: null,
            thumbs: null,
            defaultContent: null,
            activeContent: null,
            activeTitle: null,
            thumbMinHeight: {
                desktop: '203px',
                laptop: '160px',
                tablet: '160px',
                'v-tablet': '120px',
                mobile: '170px'
            },
            thumbSizePercent: {
                desktop: {
                    default: 20,
                    active: 40
                },
                laptop: {
                    default: 25,
                    active: 50
                },
                tablet: {
                    default: 33,
                    active: 66
                },
                'v-tablet': {
                    default: 100,
                    active: 100
                },
                mobile: {
                    default: 100,
                    active: 100
                }
            },
            containerOffset: {
                desktop: 80,
                laptop: 40,
                tablet: 40,
                'v-tablet': 32,
                mobile: 16
            },
            titleSize: {
                desktop: {
                    default: 100,
                    active: 70
                },
                laptop: {
                    default: 100,
                    active: 75
                },
                tablet: {
                    default: 70,
                    active: 100
                },
                'v-tablet': {
                    default: 50,
                    active: 90
                },
                mobile: {
                    default: 50,
                    active: 90
                }
            }
        }
    },
    computed: {
        currentThumbSize () {
            return this.thumbSizePercent[this.device]
        },
        currentThumbMinHeight () {
            return this.thumbMinHeight[this.device]
        },
        currentOffset () {
            return this.containerOffset[this.device]
        },
        currentTitleSize () {
            return this.titleSize[this.device]
        },
        isNotTouchDevices () {
            return !this.isMobile() && !this.isVTablet()
        }
    },
    watch: {
        activeIndex: function (newVal, oldVal) {
            const direction = newVal > oldVal ? 'right' : 'left'
            const targetThumb = this.thumbs[newVal]
            const isVisibleTarget = this.isThumbVisible(targetThumb)

            if (this.isNotTouchDevices) {
                if (direction === 'right') {
                    const nextThumb = this.thumbs[newVal + 1]
                    const isVisibleNext = !nextThumb || this.isThumbVisible(nextThumb)

                    if (!isVisibleNext) {
                        this.translateContainer(nextThumb, direction)
                    } else if (!isVisibleTarget) {
                        this.translateContainer(targetThumb, direction)
                    }
                } else if (direction === 'left') {
                    const prevThumb = this.thumbs[newVal - 1]
                    const isVisiblePrev = !prevThumb || this.isThumbVisible(prevThumb)

                    if (!isVisiblePrev) {
                        this.translateContainer(prevThumb, direction)
                    } else if (!isVisibleTarget) {
                        this.translateContainer(targetThumb, direction)
                    }
                }
            } else {
                this.translateContainer(targetThumb, direction)
            }

            this.collapseThumb(oldVal)
            this.expandThumb(newVal)
        },
        device: function (newVal, oldVal) {
            this.setThumbsSize()
        }
    },
    methods: {
        expandThumb (index) {
            this.changeStarted()

            const { container, defaultContent, activeContent, activeTitle } = this.getThumbContent(index)

            container.style.opacity = '0'
            container.style.width = `${this.currentThumbSize.active}%`
            container.style.maxHeight = this.currentThumbMinHeight

            activeContent.style.position = 'relative'
            defaultContent.style.position = 'absolute'

            let scrollHeight = container.scrollHeight

            container.style.opacity = ''
            container.style.width = `${this.currentThumbSize.default}%`

            const tl = gsap.timeline({
                defaults: { ease: 'power1' },
                onStart: () => {
                    activeContent.style.opacity = '0'
                    container.style.height = this.currentThumbMinHeight
                    container.style.maxHeight = ''

                    defaultContent.style.visability = 'hidden'
                },
                onComplete: () => {
                    activeContent.style.visability = 'visible'
                    container.style.height = ''
                    this.changeCompleted()
                }
            })

            tl
                .to(container, {
                    duration: 0.6,
                    width: `${this.currentThumbSize.active}%`
                }, 0)
                .to(activeTitle, {
                    duration: 0,
                    width: `${this.currentThumbSize.active}%`
                }, 0)
                .to(defaultContent, {
                    duration: 0.6,
                    opacity: 0
                }, 0)
                .to(activeContent, {
                    duration: 0.6,
                    opacity: 1
                }, 0.4)

            if (this.theme === 'detail') {
                tl.to(container, {
                    duration: 0.6,
                    height: scrollHeight
                }, 0)
            }
        },
        collapseThumb (index) {
            const { container, defaultContent, activeContent } = this.getThumbContent(index)

            const tl = gsap.timeline({
                defaults: { ease: 'power1' },
                onStart: () => {
                    if (this.theme === 'detail') {
                        container.style.height = container.scrollHeight + 'px'
                    }

                    activeContent.style.visability = 'hidden'
                    activeContent.style.position = 'absolute'

                    defaultContent.style.visability = 'visible'
                    defaultContent.style.position = 'relative'
                },
                onComplete: () => {
                    container.style.height = ''
                }
            })

            tl
                .to(container, {
                    duration: 0.6,
                    height: this.currentThumbMinHeight,
                    width: `${this.currentThumbSize.default}%`
                }, 0)
                .to(activeContent, {
                    duration: 0.6,
                    opacity: 0
                }, 0.2)
                .to(defaultContent, {
                    duration: 0.6,
                    opacity: 1
                }, 0.2)
        },
        changeStarted () {
            if (this.theme === 'detail') {
                if (this.isNotTouchDevices) this.disabled = true
                this.$emit('changeStarted')
            }
        },
        changeCompleted () {
            if (this.theme === 'detail') {
                if (this.isNotTouchDevices) this.disabled = false
                this.$emit('changeCompleted')
            }
        },
        getThumbContent (index) {
            const defaultContent = this.defaultContent[index]
            const activeContent = this.activeContent[index]
            const activeTitle = this.activeTitle[index]
            const container = this.thumbs[index]

            return { container, defaultContent, activeContent, activeTitle }
        },
        thumbClickHandler (index) {
            this.$emit('change', index + 1)
        },
        setThumbsSize () {
            this.thumbs.forEach(item => {
                item.style.width = `${this.currentThumbSize.default}%`
            })

            this.expandThumb(this.activeIndex)
        },
        setThumbsInitialStyles () {
            this.activeContent.forEach(item => {
                item.style.position = 'absolute'
            })
        },
        pointerEventsToggle (events = 'all') {
            this.thumbs.forEach(item => gsap.to(item, { pointerEvents: events }, 0))
        },
        isThumbVisible (thumb) {
            const rect = thumb.getBoundingClientRect()

            return (
                rect.left >= 0 &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        translateContainer (thumb, dir = 'right') {
            const thumbsContainerPos = this.thumbsContainer.getBoundingClientRect()
            const targetPos = thumb.getBoundingClientRect()
            let translateValue = dir === 'right'
                ? thumbsContainerPos.right - targetPos.right
                : -(targetPos.left - thumbsContainerPos.left)

            this.thumbsContainer.style.transform = `translateX(${translateValue}px)`
        },
        updatePosition () {
            setTimeout(() => {
                const containerPos = this.container.getBoundingClientRect()
                const thumbPos = this.thumbs[this.activeIndex].getBoundingClientRect()
                const thumbsContainerPos = this.thumbsContainer.getBoundingClientRect()

                let translateValue = thumbsContainerPos.right - thumbPos.right

                if (thumbsContainerPos.left + translateValue > containerPos.left + this.currentOffset) {
                    translateValue = 0
                }

                this.thumbsContainer.style.transform = `translateX(${translateValue}px)`
            }, 500)
        },
        getFormatedTitle (title, type = 'default') {
            return title.length > this.currentTitleSize[type]
                ? `${title.slice(0, this.currentTitleSize[type])}...`
                : title
        },
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    },
    mounted () {
        this.container = this.$refs.container
        this.thumbsContainer = this.$refs.thumbsContainer
        this.thumbs = this.$refs.thumbs
        this.defaultContent = this.$refs.defaultContent
        this.activeContent = this.$refs.activeContent
        this.activeTitle = this.$refs.activeTitle

        this.setThumbsSize()
        window.addEventListener('resize', this.updatePosition)
    },
    destroyed () {
        window.removeEventListener('resize', this.updatePosition)
    }
}
</script>

<style lang="scss">
.modern-thumbs {
    $parent: &;

    overflow: hidden;

    &--disabled {
        pointer-events: none;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        color: $token-colors-white;
        margin-bottom: 1.375rem;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__thumbs {
        display: flex;
        align-items: flex-end;
        transition: transform 0.5s ease;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: var(--thumb-min-height);
        flex-shrink: 0;
        padding: 0 4px;

        &:first-child {
            padding: 0 4px 0 0;
        }

        &:last-child {
            padding: 0 0 0 4px;
        }

        &:not(.modern-thumbs__item--active) {
            #{$parent}__item-content--active {
                opacity: 0 !important;
                pointer-events: none !important;
            }
        }

        &--active {
            #{$parent}__item-wrapper {
                background: rgba(6, 76, 118, 0.80);
            }
        }
    }

    &__item-wrapper {
        min-height: var(--thumb-min-height);

        #{$parent}--theme-link & {
            max-height: var(--thumb-min-height);
        }

        color: $token-colors-white;
        background: rgba(6, 76, 118, 0.60);
        backdrop-filter: blur(10px);
        border-radius: 20px 20px 20px 0px;
        position: relative;
    }

    &__item-content {
        width: 100%;
        height: 100%;
        // transition: opacity 0.1s ease;
        overflow: hidden;

        &--default {
            height: var(--thumb-min-height);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 1rem 1.5rem;
            cursor: pointer;

            #{$parent}--theme-detail & {
                padding: 24px 16px;
            }

            @include breakpoint(mobile) {
                padding: 1rem;
            }

            #{$parent}__item-title {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-clamp: 5;
                -webkit-line-clamp: 5;
                text-overflow: ellipsis;

                font-size: 16px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: -0.16px;
                color: $token-colors-white;

                #{$parent}--theme-detail & {
                    font-size: 24px;
                }
            }

            #{$parent}__item-tag {
                font-size: 14px;
            }
        }

        &--active {
            position: absolute;
            top: 0;
            left: 0;
            padding: 1.5rem 2rem;
            opacity: 1;
            cursor: default;

            @include breakpoint(tablet) {
                padding: 1rem 1.5rem;
            }

            @include breakpoint(mobile) {
                padding: 1rem;
            }

            #{$parent}__item-title {
                font-size: 36px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: -0.36px;
                color: $token-colors-white;
                transition: all $transition;
                width: 100% !important;

                #{$parent}--theme-link & {
                    &:hover {
                        color: $token-colors-white !important;
                        opacity: 0.7;
                    }
                }

                #{$parent}--theme-detail & {
                    margin-top: $token-spacers-m;

                    @include breakpoint(laptop) {
                        margin-top: $token-spacers-xs;
                    }

                    @include breakpoint(v-tablet) {
                        margin-top: 0;
                    }
                }

                @include breakpoint(laptop) {
                    font-size: 24px;
                }

                @include breakpoint(tablet) {
                    font-size: 20px;
                }

                @include breakpoint(v-tablet) {
                    font-size: 18px;
                }

                @include breakpoint(mobile) {
                    font-size: 16px;
                }
            }

            #{$parent}__item-description {
                font-size: 22px;
                font-weight: 600;
                line-height: 140%;
                word-break: break-all;

                @include breakpoint(laptop) {
                    font-size: 18px;
                }

                .z-tooltip {
                    margin: 0 6px;
                }
            }

            #{$parent}__item-link {
                margin-top: $token-spacers-3-xs;
            }

            #{$parent}__item-tag {
                font-size: 16px;

                @include breakpoint(mobile) {
                    font-size: 14px;
                }
            }
        }
    }

    &__item-body {
        height: 65%;

        #{$parent}--theme-detail & {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: $token-spacers-3-xs;

            &--active {
                justify-content: flex-start;
            }
        }
    }

    &__item-tag {
        color: $token-colors-blue-30;
        margin-bottom: 0.5rem;
    }
}
</style>
