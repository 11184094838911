/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-tank-storage': {
    width: 114,
    height: 114,
    viewBox: '0 0 114 114',
    data: '<circle pid="0" cx="57" cy="57" r="57" _fill="#fff"/><g clip-path="url(#clip0_9226_31774)"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M47.132 35.795v.001l.007 1.705h.238a.602.602 0 01.107-.009h4.845c.037 0 .073.003.109.01l20.958-.01.008-1.696v-.001a.895.895 0 00-.891-.896h-24.49c-.49 0-.89.401-.891.896zm27.464 1.697l.008-1.693v-.003c0-1.15-.93-2.097-2.091-2.097h-24.49c-1.162 0-2.091.947-2.091 2.097v.003l.007 1.696-.74-.003h-.002c-1.161 0-2.091.947-2.091 2.097v8.333h-.735a3.041 3.041 0 00-3.033 3.045v.005l.006.743h-.741a3.041 3.041 0 00-3.033 3.045V70.9h-1.677a.6.6 0 000 1.2H79.107a.6.6 0 100-1.2H77.43V39.59c0-1.151-.93-2.098-2.091-2.098h-.743zm-.6 1.2h-.005l-21.054.01-.008 3.085c.007.035.01.071.01.108V45.689l-.01 3.684a.61.61 0 01.01.109l-.01 3.683c.007.036.01.073.01.11l-.01 3.683c.007.036.01.073.01.11l-.01 3.683c.007.036.01.072.01.11l-.01 3.628c.007.036.01.072.01.108v6.304h23.29V39.589c0-.495-.4-.897-.89-.897h-1.343zM51.74 70.902v-2.288H48.076l.006 2.288h3.657zm-4.857 0l-.008-2.877V60.86c0-.037.003-.073.01-.108l-.01-3.684c0-.037.003-.073.01-.109l-.01-3.683c0-.037.003-.074.01-.11l-.01-3.683c0-.037.003-.074.01-.11l-.01-3.683c0-.037.003-.073.01-.11l-.01-3.683c0-.037.003-.073.01-.109l-.009-3.088-.335-.001h-.002l-1.341-.006h-.002c-.489 0-.889.402-.889.897V70.901h2.576zm-3.776 0V52.915h-2.568V70.9h2.568zm-3.768 0V52.915h-.735c-1.01 0-1.833.827-1.833 1.845V70.9h2.568zm3.768-19.186v-2.593h-.735a1.841 1.841 0 00-1.833 1.843v.002l.006.748h2.562zm4.97-13.023l.006 2.593h3.648l.007-2.593h-3.662zm3.663 3.793h-3.664l.007 2.593h3.657v-2.593zm-.002 3.793h-3.662l.007 2.592h3.648l.007-2.592zm0 3.792h-3.662l.007 2.593h3.648l.007-2.593zm0 3.793h-3.662l.007 2.593h3.648l.007-2.593zm0 3.793h-3.662l.007 2.593h3.648l.007-2.593zm0 3.793h-3.663v2.538h3.657l.006-2.538zm.002 3.738h-3.665v2.225h3.665v-2.225z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31774"><path pid="2" _fill="#fff" transform="translate(32 28)" d="M0 0h48v48H0z"/></clipPath></defs>'
  }
})
