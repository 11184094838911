import gsap from 'gsap'

class CardHover {
    static activeCard = null

    constructor (card) {
        if (!card) return

        this.card = card
        this.content = this.card.querySelector('.custom-card__content')
        this.gradient = this.card.querySelector('.custom-card__gradient')
        this.bgImg = this.card.querySelector('.custom-card__img')

        this.tl = null
        this.play = this.play.bind(this)
        this.reverse = this.reverse.bind(this)
    }

    init () {
        this.setTimelineAnimation()
        this.addEvents()
    }

    setTimelineAnimation () {
        const allCards = Array.from(document.querySelectorAll('.custom-card'))

        this.tl = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.2,
                ease: 'linear'
            },
            onStart: () => {
                if (CardHover.activeCard && CardHover.activeCard !== this) {
                    CardHover.activeCard.reverse()
                }

                CardHover.activeCard = this

                allCards.forEach(card =>
                    gsap.to(card.querySelector('.custom-card__title'), { autoAlpha: 0, duration: 0.1 })
                )
            },
            onComplete: () => {
                allCards.forEach(card =>
                    gsap.to(card.querySelector('.custom-card__title'), { autoAlpha: 0, duration: 0.1 })
                )
            },
            onReverseComplete: () => {
                if (CardHover.activeCard === this) {
                    CardHover.activeCard = null
                }

                if (!CardHover.activeCard) {
                    allCards.forEach(card =>
                        gsap.to(card.querySelector('.custom-card__title'), { autoAlpha: 1, duration: 0.1, delay: 0.2 })
                    )
                }
            }
        })
        this.tl
            .fromTo(this.card, {
                flex: '1 1 0%',
                duration: 0.4
            }, {
                flex: '2 1 0%',
                duration: 0.4
            }, '>+=0.1')
            .fromTo(this.content, {
                autoAlpha: 0,
                yPercent: 100,
                duration: 0.4
            },
            {
                autoAlpha: 1,
                yPercent: 0,
                duration: 0.4
            }, '>+=0.1')
            .fromTo(this.gradient, {
                opacity: 0,
                duration: 0.4
            }, {
                opacity: 1,
                duration: 0.4
            }, '<')
    }

    addEvents () {
        this.card.addEventListener('mouseenter', this.play)
        this.card.addEventListener('mouseleave', this.reverse)
    }

    removeEvents () {
        this.card.removeEventListener('mouseenter', this.play)
        this.card.removeEventListener('mouseleave', this.reverse)
    }

    revert () {
        this.tl.revert()
    }

    play () {
        this.tl.timeScale(1.4).play()
    }

    reverse () {
        this.tl.timeScale(1.8).reverse()
    }
}

export default CardHover
