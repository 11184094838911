/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-text': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.437 1.59a.598.598 0 00-.459-.19H6A2.6 2.6 0 003.4 4v16A2.6 2.6 0 006 22.6h12a2.6 2.6 0 002.6-2.6V8.004 8a.598.598 0 00-.189-.437L14.437 1.59zM13.4 2.6H6A1.4 1.4 0 004.6 4v16A1.4 1.4 0 006 21.4h12a1.4 1.4 0 001.4-1.4V8.6H14a.6.6 0 01-.6-.6V2.6zm5.151 4.8H14.6V3.45l3.951 3.95zM8 8.4a.6.6 0 000 1.2h2a.6.6 0 100-1.2H8zM7.4 13a.6.6 0 01.6-.6h8a.6.6 0 110 1.2H8a.6.6 0 01-.6-.6zm0 4a.6.6 0 01.6-.6h8a.6.6 0 110 1.2H8a.6.6 0 01-.6-.6z" _fill="#0077C8"/>'
  }
})
