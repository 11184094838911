<template>
    <div class="clean-media-card">
        <div
            v-if="showIcon"
            class="clean-media-card__icon-wrapper"
        >
            <div class="clean-media-card__icon-background"></div>
            <z-icon
                name="external"
                class="clean-media-card__icon"
            />
        </div>
        <div class="clean-media-card__content">
            <span
                v-if="date"
                class="clean-media-card__date"
                v-html="date"
            ></span>
            <z-link
                v-if="!!$slots.title"
                :href="href"
                theme="grey-60"
                class="text-size-xl"
            >
                <slot name="title" />
            </z-link>
            <p
                v-if="!!$slots.description"
                class="clean-media-card__description"
            >
                <slot name="description" />
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'clean-media-card',
    props: {
        showIcon: {
            type: Boolean,
            default: true
        },
        date: String,
        title: String,
        href: String,
        description: String
    }
}
</script>

<style lang="scss">
.clean-media-card {
    display: flex;
    gap: 20px;

    &__icon-wrapper {
        width: 100px;
        height: 120px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include breakpoint(tablet) {
            width: 80px;
            height: 100px;
        }

        @include breakpoint(v-tablet) {
            width: 70px;
            height: 84px;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &__icon-background {
        width: 148%;
        height: 140%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='148' height='168' viewBox='0 0 148 168' fill='none'%3E%3Cg filter='url(%23filter0_dd_2611_12)'%3E%3Cpath d='M24 43.5188C24 28.3206 36.3206 16 51.5188 16H124V108.481C124 123.679 111.679 136 96.4812 136H24V43.5188Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_dd_2611_12' x='0' y='0' width='148' height='168' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='8'/%3E%3CfeGaussianBlur stdDeviation='12'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2611_12'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow_2611_12' result='effect2_dropShadow_2611_12'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_2611_12' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__icon {
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
            width: 24px;
            height: 24px;
        }

        @include breakpoint(v-tablet) {
            width: 22px;
            height: 22px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__date {
        color: #737B90;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 1.4px;
    }

    &__description {
        color: $token-colors-gray-58;
        font-size: 18px;
        line-height: 150%;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }
}
</style>
