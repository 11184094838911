var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"annual-report-block"},[(!!this.$slots.header && _vm.hasData)?_c('div',{staticClass:"annual-report-block__header"},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"annual-report-block__content-wrapper"},[_c('div',{staticClass:"row"},[(_vm.shouldShowAnnualReport)?_c('div',{class:_vm.colClass},[_c('report-block',{attrs:{"horizontal":_vm.isHorizontal,"data":{
                    image: _vm.data['annual-report'].arImg.link,
                    name: _vm.data['annual-report'].arFile.name || ((_vm.text['annual-report']) + " " + (_vm.data['annual-report'].name)),
                    fileLink: _vm.data['annual-report'].arFile.link,
                    onlinelink: _vm.data['annual-report'].arUrl.value,
                    taggedFileLink: _vm.data['annual-report'].tvArFile.link,
                    brochure: _vm.data['annual-report'].brochure.link
                }}})],1):_vm._e(),_vm._v(" "),(_vm.shouldShowCsrReport)?_c('div',{class:_vm.colClass},[_c('report-block',{attrs:{"horizontal":_vm.isHorizontal,"data":{
                    image: _vm.data.csr.csrImg.link,
                    name: _vm.data.csr.csrFile.name || ((_vm.text.csr) + " " + (_vm.data.csr.name)),
                    fileLink: _vm.data.csr.csrFile.link,
                    onlinelink: _vm.data.csr.csrUrl.value,
                    brochure: _vm.data.csr.brochure.link
                }}})],1):_vm._e(),_vm._v(" "),(_vm.shouldShowWhiteBook)?_c('div',{class:_vm.colClass},[_c('report-block',{attrs:{"data":{
                    image: _vm.data['white-book'].wbImg.link,
                    name: _vm.data['white-book'].wbFile.name || _vm.text['white-book'],
                    fileLink: _vm.data['white-book'].wbFile.link
                }}})],1):_vm._e(),_vm._v(" "),(_vm.shouldShowCorporateSocialPolitic)?_c('div',{class:_vm.colClass},[_c('report-block',{attrs:{"data":{
                    image: _vm.data['corporate-social-politic'].cspImg.link,
                    name: _vm.data['corporate-social-politic'].cspFile.name || _vm.text['corporate-social-politic'],
                    fileLink: _vm.data['corporate-social-politic'].cspFile.link
                }}})],1):_vm._e(),_vm._v(" "),(_vm.data.additionalReports && _vm.data.additionalReports.length)?[_vm._l((_vm.data.additionalReports),function(item,index){return [(_vm.reportsList.includes(item.type.xmlId))?_c('div',{key:index,class:_vm.colClass},[_c('report-block',{attrs:{"data":{
                            image: item.image.link,
                            name: item.name,
                            fileLink: item.file.link,
                            onlinelink: item.url.value,
                            brochure: item.brochure.link
                        }}})],1):_vm._e()]})]:_vm._e()],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }