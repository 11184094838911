<template>
    <a
        class="fixed-anchor"
        :href="href"
        target="_blank"
    ></a>
</template>

<script>
export default {
    name: 'fixed-anchor',
    props: {
        href: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
.fixed-anchor {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url('/images/contacts/round_mail.svg');
    background-repeat: no-repeat;
    border: none;
    outline: none;
    position: fixed;
    bottom: 5%;
    right: 5%;
    box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);

    &:hover {
        background-image: url('/images/contacts/round_mail_hover.svg');
    }

}
</style>
