<template>
    <div
        :class="[
            'slide-content',
            { 'slide-content--full-height': fullHeight },
            { 'slide-content--theme-thumbs': $slots.thumbs },
        ]"
        :style="`background-image: url(${image})`"
    >
        <div
            class="slide-content__overlay"
            :style="`opacity: ${opacity}`"
        ></div>
        <div class="slide-content__container container u-relative">
            <slot></slot>

            <div v-if="$slots.thumbs" class="slide-content__thumbs-wrapper">
                <div class="slide-content__thumbs">
                    <slot name="thumbs"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'slide-content',
    props: {
        image: {
            type: String,
            required: true
        },
        opacity: {
            type: String,
            default: '0'
        },
        fullHeight: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {}
    }
}
</script>

<style lang="scss">
.slide-content {
    $parent: &;

    min-height: 650px;
    background-position: center;
    background-size: cover;
    position: relative;

    @include padding-level(top, 2XL);
    @include padding-level(bottom, 3XL);

    &--theme {
        &-thumbs {
            display: flex;
            align-items: flex-end;
            padding-bottom: 20px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 76, 151, 0.3);
            }
        }
    }

    .slider--half-screen & {
        @include breakpoint(laptop) {
            padding-bottom: $token-spacers-3-xl !important;
        }
    }

    @include breakpoint(tablet) {
        padding-bottom: $token-spacers-3-xl !important;
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__overlay {
        &:before {
            content: '';
            position: absolute;
            background-color: black;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &__thumbs-wrapper {
        margin-top: auto;
        padding-top: 60px;
        width: 50%;

        .slider--half-screen & {
            width: 60%;

            @include breakpoint(laptop) {
                width: 100%;
                padding-bottom: 12px;
            }
        }

        @include breakpoint(v-tablet) {
            width: 100%;
            padding-top: 24px;
            padding-bottom: 8px;
        }

        @include breakpoint(mobile) {
            padding-top: 12px;
        }

        #{$parent}__thumbs {
            padding: 32px;
            background: rgba(6, 76, 118, 0.80);
            backdrop-filter: blur(10px);
            border-radius: 20px 20px 0 0;
        }
    }

    &--full-height {
        height: 100%;
    }
}
</style>
