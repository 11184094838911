/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Icon/map-circl-block': {
    width: 118,
    height: 146,
    viewBox: '0 0 118 146',
    data: '<path pid="0" d="M0 20C0 8.954 8.954 0 20 0h78c11.046 0 20 8.954 20 20v106c0 11.046-8.954 20-20 20H0V20z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M45.398 74.63l.13.02 14.523 2.335L75.62 74.65l.127-.02.128.02 15.176 2.355.486.076.175.46 12.036 31.659.558 1.468-1.533-.341-23.345-5.188-19.16 5.179-.208.056-.21-.05-21.787-5.187-22.833 5.189-1.693.385.74-1.571 14.914-31.66.192-.407.446-.068 15.438-2.355.13-.02zM39.1 103.644l20.1 4.785V78.563l-13.176-2.118-6.924 27.199zm5.159-27.128l-6.884 27.041-20.826 4.734 13.981-29.68 13.729-2.095zm16.634 2.054v29.825l17.583-4.752-3.461-27.19-14.122 2.117zm15.834-2.074l3.446 27.075 21.435 4.763-11.303-29.732-13.578-2.106z" _fill="url(#paint0_linear_10351_2138)"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M60.423 86.134L75.29 71.267a21.026 21.026 0 10-29.734 0l14.867 14.867z" _fill="#0077C8"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M39.801 60.502a21.026 21.026 0 005.755 10.765l14.867 14.868L75.29 71.267a21.025 21.025 0 10-35.489-10.765zm34.293 9.57l-13.671 13.67-13.671-13.67a19.334 19.334 0 1127.342 0zm-6.171-13.557a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zm1.5 0a9 9 0 11-18 0 9 9 0 0118 0z" _fill="#fff"/><defs><linearGradient id="paint0_linear_10351_2138" x1="58.922" y1="74.63" x2="58.922" y2="110.711" gradientUnits="userSpaceOnUse"><stop stop-color="#fff" stop-opacity=".1"/><stop offset="1" stop-color="#fff"/></linearGradient></defs>'
  }
})
