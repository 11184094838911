/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'telegram': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.708 7.205a.844.844 0 00-.859-.147l-19 7.435a1.333 1.333 0 00.229 2.551l4.923.967V23.5a1.493 1.493 0 00.937 1.39 1.49 1.49 0 001.641-.35l2.374-2.461 3.766 3.296a1.489 1.489 0 001.454.3 1.487 1.487 0 001-1.09L25.98 8.032a.843.843 0 00-.271-.826zM20.706 24.25l-7.75-6.797L24.11 9.457 20.706 24.25z" _fill="#0077C8"/>'
  }
})
