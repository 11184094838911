<template>
    <div :class="['z-input-file', {'z-input-file--multiple' : multiple}]">
        <label
            :for="name"
            class="z-input-file__label"
            :class="{
                'is-disabled': disabled,
                'is-errored': !error,
            }"
        >
            <input
                :id="name"
                type="file"
                ref="file"
                :name="name"
                :disabled="disabled"
                @change="onChange($event.target)"
                :multiple="multiple"
            />
            <z-icon
                name="paperclip"
                width="20"
                height="20"
                class="z-input-file__icon"
            />
            <span v-if="label" v-html="label"></span>
            <span v-else v-html="text.label"></span>
        </label>

        <div class="z-input-file__description" v-if="description" v-html="description"></div>

        <div class="z-input-file__filelist" v-if="files.length">
            <ul
                class="z-input-file__filelist-item"
                v-for="(file, index) in files"
                :key="index"
            >
                <z-filelist-item
                    :name="getFormatedName(file.name)"
                    :icon-name="getFormatedType(file.name)"
                    :file-size="getFormatedSize(file.size)"
                ></z-filelist-item>
                <button class="z-input-file__remove">
                    <z-icon
                        name="close"
                        width="20"
                        height="20"
                        @click="remove(file)"
                    />
                </button>
            </ul>
        </div>
        <span
            :class="[
                'z-input-file__error',
                errorClass
            ]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n.js'

export default {
    inheritAttrs: false,
    name: 'z-input-file',
    props: {
        name: {
            type: String,
            required: true
        },
        label: String,
        description: String,
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        accept: Array,
        errorClass: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            files: [],
            uploaded: [],
            isValid: null,
            allowedFiles: ['doc', 'docx', 'jpg', 'mp3', 'mp4', 'pdf', 'png', 'ppt', 'pptx', 'rar', 'rtf', 'tif', 'txt', 'xls', 'xlsx', 'zip'],
            error: '',
            text: {
                label: localize({
                    ru: 'Прикрепить файлы',
                    en: 'Attach files'
                }),
                errors: {
                    required: localize({
                        ru: 'Поле обязательно для заполнения',
                        en: 'Required field'
                    }),
                    format: localize({
                        ru: 'Неверный формат',
                        en: 'Wrong format'
                    }),
                    numbers: localize({
                        ru: 'Превышено количество файлов',
                        en: 'Number of files exceeded'
                    })
                }
            }
        }
    },
    methods: {
        onChange (target) {
            this.updateList(target.files)
            this.uploaded = target.files
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        clear () {
            this.files = []
            this.uploaded = []
            this.$refs.file.value = null
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        updateList (data) {
            this.files = Array.from(data).map(item => {
                const re = /(?:\.([^.]+))?$/

                return {
                    name: item.name,
                    size: item.size,
                    type: re.exec(item.name)[1]
                }
            })
        },
        remove (file) {
            let dt = new DataTransfer()
            Array.from(this.$refs.file.files).forEach(item => {
                if (file.name !== item.name) {
                    dt.items.add(item)
                }
            })
            if (dt.files.length === 0) {
                this.clear()
                return
            }
            this.$refs.file.files = dt.files
            this.uploaded = dt.files
            this.updateList(this.$refs.file.files)
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        getFormatedSize (size) {
            let result = size / 1024
            if (result >= 1024) {
                return `${(result / 1024).toFixed(1)} Mb`
            }
            return `${result.toFixed(1)} Kb`
        },
        getFormatedName (str) {
            const index = str.lastIndexOf('.')
            if (!this.allowedFiles.includes(str.slice(index + 1))) {
                return str
            }
            return str.slice(0, index)
        },
        getFormatedType (str) {
            const index = str.lastIndexOf('.')
            if (!this.allowedFiles.includes(str.slice(index + 1))) {
                return 'file/default'
            }
            return `file/${str.slice(index + 1)}`
        },
        isFormatCorrect (file) {
            if (!this.accept) return true
            return this.accept.includes(file.type)
        },
        isFilesCorrect () {
            let result = true
            this.files.forEach(item => {
                if (!this.isFormatCorrect(item)) result = false
            })
            return result
        },
        validate () {
            if (this.uploaded.length > 10) {
                this.isValid = false
                this.error = this.text.errors.numbers
                return
            }

            if (this.required && this.uploaded.length === 0) {
                this.isValid = false
                this.error = this.text.errors.required
                return
            }

            if (!this.isFilesCorrect()) {
                this.isValid = false
                this.error = this.text.errors.format
                return
            }

            this.isValid = true
            this.error = ''
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
