<template>
    <component
        :is="link ? 'a' : 'div'"
        :href="link"
        :target="target"
        class="card-link"
        :class="`card-link--${theme}`"
    >
        <div class="card-link__wrapper">
            <z-icon name="faq-attention" width="40" height="40" class="card-link__icon" v-if="link && theme === 'warning'"/>
            <z-icon
                name="round-arrow"
                width="40"
                height="40"
                dir="right"
                class="card-link__icon"
                v-else-if="link"
            />
            <div class="card-link__content">
                <slot />
            </div>
        </div>
        <div
            class="card-link__image"
            :style="image ? `background-image: url(${image})` : ''"
        >
            <img
                class="card-link__logo"
                v-if="!image"
                :src="logSrc"
                alt=""
            >
        </div>
    </component>
    </template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'card-link',
    mixins: [mixinDevice],
    props: {
        link: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'default',
            validator: prop => ['warning', 'default'].includes(prop)
        },
        target: {
            type: String
        }
    },
    data () {
        return {
            lang: this.$root.lang
        }
    },
    computed: {
        logSrc () {
            if (this.device === 'v-tablet' || this.device === 'mobile') return `/images/logo/logo-sm-inverted.svg`
            return `/images/logo/logo-inverted-${this.$root.lang}.svg`
        }
    }
}
</script>

<style lang="scss">
$warning-hover-color: #DB830D;
$cardLink: '.card-link';

a#{$cardLink} {
    text-decoration: none;
    transition: box-shadow $transition, border-color $transition;

    &:hover {
        border-color: $token-colors-navy-blue;

        #{$cardLink}__content {
            color: $token-colors-navy-blue;
        }

        #{$cardLink}__icon {
            path {
                fill: $token-colors-navy-blue;
            }
        }
    }

    &--warning {
        &:hover {
            border-color: $warning-hover-color;

            #{$cardLink}__content {
                color: $warning-hover-color;
            }

            #{$cardLink}__icon {
                path {
                    fill: $warning-hover-color;
                }
            }

            #{$cardLink}__image {
                background-color: $warning-hover-color;
            }
        }
    }
}

#{$cardLink} {
    display: flex;
    border-radius: $boder-radius-m $boder-radius-m $boder-radius-m 0;
    border: 1px solid $token-colors-blue;
    overflow: hidden;

    &__content {
        @include typo-level(XL);
        color: $token-colors-blue;
        transition: color $transition;

        @include breakpoint(v-tablet) {
            @include typo-level(M);
        }

        @include breakpoint(mobile) {
            @include typo-level(XL);
        }
    }

    &__icon {
        @include margin-level(right, XS);

        path {
            transition: fill $transition;
        }

        @include breakpoint (mobile) {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &__wrapper {
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, S);
        @include padding-level(right, S);
        display: flex;
        align-items: center;
        flex-grow: 1;

        @include breakpoint (mobile) {
            padding: 16px;
        }
    }

    &__image {
        border-top-left-radius: $boder-radius-xl;
        background-color: $token-colors-blue;
        width: 26%;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        max-width: 280px;

        @include breakpoint(v-tablet) {
            width: 35%;
        }

        @include breakpoint (mobile) {
            display: none;
        }
    }

    &__logo {
        width: 80%;
        height: 80%;
        max-width: 150px;
        max-height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--warning {
        border: 1px solid $token-colors-warning;

        .z-icon {
            path {
                fill: $token-colors-warning-text;
            }
        }

        #{$cardLink}__content {
            color: $token-colors-warning-text;
        }

        #{$cardLink}__image {
            transition: background-color $transition;
            background-color: $token-colors-warning;
            background-size: auto;
        }
    }
}
</style>
