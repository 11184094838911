<template>
    <div class="custom-modal">
        <template v-if="$slots.trigger">
            <slot name="trigger"></slot>
        </template>
        <button v-else class="custom-modal__button" @click="openModal">
            <z-icon
                :name="iconName"
                width="32"
                height="32"/>
        </button>

        <z-modal
            ref="modal"
            id="customModal"
            @close="triggerEmit"
        >
            <slot :closeModal="closeModal" />
        </z-modal>
    </div>
</template>

<script>
export default {
    name: 'custom-modal',
    props: {
        iconName: {
            type: String,
            default: 'message'
        }
    },
    methods: {
        openModal () {
            this.$refs.modal.openModal({ id: 'customModal' })
        },
        closeModal () {
            this.$refs.modal.closeModal({ id: 'customModal' })
        },
        triggerEmit () {
            this.$emit('close')
        }
    }
}
</script>

<style scoped lang="scss">
.custom-modal {
    &__button {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: $token-colors-white;
        transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
        overflow: hidden;
        border: none;
        outline: none;
        position: fixed;
        bottom: 5%;
        right: 5%;
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);

        &::v-deep .z-icon path {
            transition: fill 0.15s ease-in-out;
        }

        &:hover {
            &::v-deep .z-icon path {
                fill: $token-colors-white;
            }
            background-color: $token-colors-navy-blue;
        }

        &:active {
            opacity: 0.6;
        }
    }
}
</style>
