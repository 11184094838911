/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.979 8.15h19.349c.226 0 .456 0 .635.026.165.023.554.101.719.5.165.397-.055.727-.155.86-.11.145-.272.308-.431.467l-.025.025-7.473 7.472 7.473 7.473.025.025c.16.16.322.321.43.466.101.134.32.464.156.862-.165.398-.554.476-.72.5a4.91 4.91 0 01-.634.024H11.1v6.4a.6.6 0 11-1.2 0v-24-.02c0-.099 0-.224.015-.334a.843.843 0 01.234-.497.843.843 0 01.497-.234c.11-.015.235-.015.333-.015zm.121 17.5h18.952l-7.443-7.442-.013-.013a2.175 2.175 0 01-.19-.208.795.795 0 01-.173-.487c0-.225.099-.389.173-.486.059-.077.135-.154.19-.208l.013-.013L30.05 9.35H11.1v16.3zM30.814 9.367h-.002.002zm-.236-.566V8.8v.002zm0 17.4V26.2v.002zm.234-.567a.01.01 0 01.002 0h-.002z" _fill="#0077C8"/>'
  }
})
