<template>
    <div :class="[
        'date-block',
        { [`date-block--theme-${theme}`] : theme }
    ]">
        <div v-if="theme !== 'inline'">
            <div class="date-block__container">
                 <span class="date-block__month">
                    {{ date | momentFilter('MMMM') }}
                </span>
                <span class="date-block__year" v-if="withoutDate !== 'Y'">
                    {{ date | momentFilter('YYYY') }}
                </span>
                <span class="date-block__date" v-if="withoutDate !== 'Y'">
                    {{ date | momentFilter('DD') }}
                </span>
            </div>
        </div>
        <template v-else>
            <z-icon
                class="date-block__icon"
                name="calendar-2"
                width="28"
                height="28"
                v-if="icon"
            />
            <span class="date-block__text">
                {{ formatedDate(dateObject) }}<slot />
            </span>
        </template>
    </div>
</template>

<script>
import { calendarFormatDate } from '@/utils/mixin'

export default {
    name: 'date-block',
    mixins: [calendarFormatDate],
    props: {
        date: [String, Number],
        dateEnd: [String, Number],
        year: {
            type: Boolean,
            default: true
        },
        theme: {
            type: String,
            default: 'gray',
            validator: prop => ['white', 'gray', 'blue', 'inline']
        },
        icon: {
            type: Boolean,
            default: false
        },
        withoutDate: {
            type: String,
            default: ''
        }
    },
    computed: {
        dateObject () {
            return {
                start: this.date,
                end: this.dateEnd ? this.dateEnd : this.date
            }
        }
    },
    data () {
        return {
            lang: this.$root.lang
        }
    },
}
</script>

<style lang="scss">
.date-block {
    $parent: &;

    display: inline-block;
    color: $token-colors-gray-35;
    border-radius: 0px 15px 0px 0px;

    &__container {
        width: 80px;
        min-height: 85px;
        border-radius: 0px 15px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 11px 7px;
        color: $token-colors-gray-35;
        border: 1px solid rgba($token-colors-gray-35, 0.5);
    }

    &__date {
        font-size: 36px;
        line-height: 1;
        font-weight: 300;
        border-top: 1px solid currentColor;
        padding-top: 8px;
        color: currentColor;
    }

    &__month {
        display: block;
        font-weight: 600;
        font-size: 9px;
        text-transform: uppercase;
        color: currentColor;
        margin-bottom: 8px;
        padding-top: 4px;
        letter-spacing: 0.1em;
    }

    &__year {
        display: none;
    }

    &__text {
        @include typo-level(XS);
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    &--theme-blue {
        #{$parent}__container {
            color: $token-colors-blue-30;
            border-color: rgba($token-colors-blue-30, 0.5);
        }
    }

    &--theme-white {
        #{$parent}__container {
            background: $token-colors-white;
            border-color: $token-colors-white;
        }
    }

    &--theme-white-alt {
        #{$parent}__container {
            border: none;
            border-radius: 0;
            background: $token-colors-white;
            border-color: $token-colors-white;
        }

        #{$parent}__date {
            font-size: 36px;
            font-weight: 300;
            border-top: none;
            padding-top: 0;

            &:before {
                content: "";
                display: block;
                margin-bottom: 10px;
                width: 30px;
                height: 1px;
                background-color: $token-colors-gray-35;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &--theme-inline {
        display: flex;
        align-items: center;
        box-shadow: none !important;

        #{$parent}__icon {
            margin-right: 16px;
        }
    }
}
</style>
