/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faq-answer': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.834 16.083C.834 7.201 8.034 0 16.917 0 25.799 0 33 7.2 33 16.083v12.059c0 .622 0 1.13-.038 1.541-.039.427-.122.81-.327 1.16a2.67 2.67 0 01-.958.959c-.35.204-.734.288-1.16.327-.411.037-.92.037-1.541.037H16.916c-8.882 0-16.082-7.2-16.082-16.083zm16.083-14.85c-8.202 0-14.85 6.648-14.85 14.85s6.648 14.85 14.85 14.85h12.03c.657 0 1.11 0 1.458-.032.34-.031.52-.088.65-.164.213-.124.391-.302.516-.516.076-.13.133-.31.164-.65.032-.349.032-.8.032-1.458v-12.03c0-8.202-6.648-14.85-14.85-14.85zm-5.772 10.984c0-.34.276-.616.616-.616h11.6a.616.616 0 010 1.232h-11.6a.616.616 0 01-.616-.616zm6.416 7.117a.616.616 0 100 1.233h5.8a.616.616 0 100-1.233h-5.8z" _fill="#0077C8"/>'
  }
})
