import { localize } from '@/utils/i18n'

export const companyOptions = [
    {
        id: localize({
            ru: 'АП-инвест',
            en: 'AP-Invest'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'АП-инвест',
            en: 'AP-Invest'
        })
    },
    {
        id: localize({
            ru: 'Арена-Аквапарк',
            en: 'Arena Aquapark'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Арена-Аквапарк',
            en: 'Arena Aquapark'
        })
    },
    {
        id: localize({
            ru: 'Арктик палладий',
            en: 'Arctic Palladium'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Арктик палладий',
            en: 'Arctic Palladium'
        })
    },
    {
        id: localize({
            ru: 'Арктик-энерго',
            en: 'Arctic-Energo'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Арктик-энерго',
            en: 'Arctic-Energo'
        })
    },
    {
        id: localize({
            ru: 'Аэропорт Норильск',
            en: 'Norilsk Airport'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Аэропорт Норильск',
            en: 'Norilsk Airport'
        })
    },
    {
        id: localize({
            ru: 'Бугдаинский рудник',
            en: 'Bugdainsky Mine'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Бугдаинский рудник',
            en: 'Bugdainsky Mine'
        })
    },
    {
        id: localize({
            ru: 'Быстринская сервисная компания',
            en: 'Bystrinskaya Service Company'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Быстринская сервисная компания',
            en: 'Bystrinskaya Service Company'
        })
    },
    {
        id: localize({
            ru: 'Быстринское',
            en: 'Bystrinskoye'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Быстринское',
            en: 'Bystrinskoye'
        })
    },
    {
        id: localize({
            ru: 'БЭСК',
            en: 'BEGC'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'БЭСК',
            en: 'BEGC'
        })
    },
    {
        id: localize({
            ru: 'Валла-Тунтури',
            en: 'Valla Tunturi'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Валла-Тунтури',
            en: 'Valla Tunturi'
        })
    },
    {
        id: localize({
            ru: 'Востокгеология',
            en: 'Vostokgeologiya'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Востокгеология',
            en: 'Vostokgeologiya'
        })
    },
    {
        id: localize({
            ru: 'ГМК Норильский никель',
            en: 'PJSC MMC Norilsk Nickel'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'ГМК Норильский никель',
            en: 'PJSC MMC Norilsk Nickel'
        })
    },
    {
        id: localize({
            ru: 'ЕРП',
            en: 'YRSC'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'ЕРП',
            en: 'YRSC'
        })
    },
    {
        id: localize({
            ru: 'Единство',
            en: 'Edinstvo'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Единство',
            en: 'Edinstvo'
        })
    },
    {
        id: localize({
            ru: 'Заполярная строительная компания',
            en: 'Polar Construction Company'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Заполярная строительная компания',
            en: 'Polar Construction Company'
        })
    },
    {
        id: localize({
            ru: 'Заполярный филиал',
            en: 'Polar Division'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Заполярный филиал',
            en: 'Polar Division'
        })
    },
    {
        id: localize({
            ru: 'Заполярный транспортный филиал',
            en: 'Polar Transport Division'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Заполярный транспортный филиал',
            en: 'Polar Transport Division'
        })
    },
    {
        id: localize({
            ru: 'Затундра',
            en: 'Zatundra'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Затундра',
            en: 'Zatundra'
        })
    },
    {
        id: localize({
            ru: 'Инвест-Юг',
            en: 'Invest-Yug'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Инвест-Юг',
            en: 'Invest-Yug'
        })
    },
    {
        id: localize({
            ru: 'Институт Гипроникель',
            en: 'Gipronickel Institute'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Институт Гипроникель',
            en: 'Gipronickel Institute'
        })
    },
    {
        id: localize({
            ru: 'Интергеопроект',
            en: 'Intergeoproject'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Интергеопроект',
            en: 'Intergeoproject'
        })
    },
    {
        id: localize({
            ru: 'Интерпромлизинг',
            en: 'Interpromleasing'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Интерпромлизинг',
            en: 'Interpromleasing'
        })
    },
    {
        id: localize({
            ru: 'Колабыт',
            en: 'Kolabyt'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Колабыт',
            en: 'Kolabyt'
        })
    },
    {
        id: localize({
            ru: 'Кольская ГМК',
            en: 'Kola MMC'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Кольская ГМК',
            en: 'Kola MMC'
        })
    },
    {
        id: localize({
            ru: 'Корпоративный универститет',
            en: 'Corporate University'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Корпоративный универститет',
            en: 'Corporate University'
        })
    },
    {
        id: localize({
            ru: 'Клуб безопасности информации в промышленности',
            en: 'Club of Information Security in Industry'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Клуб безопасности информации в промышленности',
            en: 'Club of Information Security in Industry'
        })
    },
    {
        id: localize({
            ru: 'КРП',
            en: 'KRP'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'КРП',
            en: 'KRP'
        })
    },
    {
        id: localize({
            ru: 'КСРЗ',
            en: 'Krasnoyarsk Shipyard'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'КСРЗ',
            en: 'Krasnoyarsk Shipyard'
        })
    },
    {
        id: localize({
            ru: 'КЦЗ Норникель',
            en: 'Nornickel Corporate Health Centre'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'КЦЗ Норникель',
            en: 'Nornickel Corporate Health Centre'
        })
    },
    {
        id: localize({
            ru: 'КЦРП',
            en: 'KCPT'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'КЦРП',
            en: 'KCPT'
        })
    },
    {
        id: localize({
            ru: 'Лесосибирский порт',
            en: 'Lesosibirsk Port'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Лесосибирский порт',
            en: 'Lesosibirsk Port'
        })
    },
    {
        id: localize({
            ru: 'Масловское',
            en: 'Maslovskoye'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Масловское',
            en: 'Maslovskoye'
        })
    },
    {
        id: localize({
            ru: 'Медвежий ручей',
            en: 'South Cluster'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Медвежий ручей',
            en: 'South Cluster'
        })
    },
    {
        id: localize({
            ru: 'ММОР Союз предприятий МНПиОК',
            en: 'ICIAE Union of Entities of the CNSI'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'ММОР Союз предприятий МНПиОК',
            en: 'ICIAE Union of Entities of the CNSI'
        })
    },
    {
        id: localize({
            ru: 'Мурманский транспортный филиал',
            en: 'Murmansk Transport Division'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Мурманский транспортный филиал',
            en: 'Murmansk Transport Division'
        })
    },
    {
        id: localize({
            ru: 'МФК Норильский никель',
            en: 'Norilsk Nickel MFC'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'МФК Норильский никель',
            en: 'Norilsk Nickel MFC'
        })
    },
    {
        id: localize({
            ru: 'НН Девелопмент',
            en: 'NN Development'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НН Девелопмент',
            en: 'NN Development'
        })
    },
    {
        id: localize({
            ru: 'НН-Сервис',
            en: 'NN-Service'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НН-Сервис',
            en: 'NN-Service'
        })
    },
    {
        id: localize({
            ru: 'НН Технические Сервисы',
            en: 'NN Technical Services'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НН Технические Сервисы',
            en: 'NN Technical Services'
        })
    },
    {
        id: localize({
            ru: 'НордСтар',
            en: 'NordStar Airlines'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НордСтар',
            en: 'NordStar Airlines'
        })
    },
    {
        id: localize({
            ru: 'Норникель-ЕРП',
            en: 'Nornickel-YRSС'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норникель-ЕРП',
            en: 'Nornickel-YRSС'
        })
    },
    {
        id: localize({
            ru: 'Норникель-ОЦО',
            en: 'Nornickel-SSC'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норникель-ОЦО',
            en: 'Nornickel-SSC'
        })
    },
    {
        id: localize({
            ru: 'Норильск Авиа',
            en: 'Norilsk Avia'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильск Авиа',
            en: 'Norilsk Avia'
        })
    },
    {
        id: localize({
            ru: 'Норильскгазпром',
            en: 'Norilskgazprom'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильскгазпром',
            en: 'Norilskgazprom'
        })
    },
    {
        id: localize({
            ru: 'Норильский комбинат',
            en: 'Norilsk Combine'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильский комбинат',
            en: 'Norilsk Combine'
        })
    },
    {
        id: localize({
            ru: 'Норильский обеспечивающий комплекс',
            en: 'Norilsk Production Support Complex'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильский обеспечивающий комплекс',
            en: 'Norilsk Production Support Complex'
        })
    },
    {
        id: localize({
            ru: 'Норильскникельремонт',
            en: 'Norilsknickelremont'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильскникельремонт',
            en: 'Norilsknickelremont'
        })
    },
    {
        id: localize({
            ru: 'Норильскпромтранспорт',
            en: 'Norilskpromtransport'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильскпромтранспорт',
            en: 'Norilskpromtransport'
        })
    },
    {
        id: localize({
            ru: 'Норильск-ТАВС',
            en: 'Norilsk – TAVS'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильск-ТАВС',
            en: 'Norilsk – TAVS'
        })
    },
    {
        id: localize({
            ru: 'Норильсктрансгаз',
            en: 'Norilsktransgaz'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Норильсктрансгаз',
            en: 'Norilsktransgaz'
        })
    },
    {
        id: localize({
            ru: 'НОРМЕТИМПЭКС',
            en: 'NORMETIMPEX'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НОРМЕТИМПЭКС',
            en: 'NORMETIMPEX'
        })
    },
    {
        id: localize({
            ru: 'Нортранс-Норильск',
            en: 'Nortrans-Norilsk'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Нортранс-Норильск',
            en: 'Nortrans-Norilsk'
        })
    },
    {
        id: localize({
            ru: 'НТПО',
            en: 'NTPO'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НТПО',
            en: 'NTPO'
        })
    },
    {
        id: localize({
            ru: 'НТЭК',
            en: 'NTEC'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'НТЭК',
            en: 'NTEC'
        })
    },
    {
        id: localize({
            ru: 'Печенгастрой',
            en: 'Pechengastroy'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Печенгастрой',
            en: 'Pechengastroy'
        })
    },
    {
        id: localize({
            ru: 'Подмосковная Слобода',
            en: 'Podmoskovnaya Sloboda'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Подмосковная Слобода',
            en: 'Podmoskovnaya Sloboda'
        })
    },
    {
        id: localize({
            ru: 'Полигон-техноген',
            en: 'Poligon-Tekhnogen'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Полигон-техноген',
            en: 'Poligon-Tekhnogen'
        })
    },
    {
        id: localize({
            ru: 'Профессиональный баскетбольный клуб ЦСКА',
            en: 'CSKA professional basketball club'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Профессиональный баскетбольный клуб ЦСКА',
            en: 'CSKA professional basketball club'
        })
    },
    {
        id: localize({
            ru: 'Ренонс',
            en: 'Renons'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Ренонс',
            en: 'Renons'
        })
    },
    {
        id: localize({
            ru: 'РМОР Союз предприятий МНПиОК Красноярского края',
            en: 'Union of Entities of the CNSI, Krasnoyarsk Territory'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'РМОР Союз предприятий МНПиОК Красноярского края',
            en: 'Union of Entities of the CNSI, Krasnoyarsk Territory'
        })
    },
    {
        id: localize({
            ru: 'РМОР Союз предприятий МНПиОК Мурманской области',
            en: 'Union of Entities of the CNSI, Murmansk Region'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'РМОР Союз предприятий МНПиОК Мурманской области',
            en: 'Union of Entities of the CNSI, Murmansk Region'
        })
    },
    {
        id: localize({
            ru: 'Россо',
            en: 'Rosso'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Россо',
            en: 'Rosso'
        })
    },
    {
        id: localize({
            ru: 'С Джи М',
            en: 'SGM'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'С Джи М',
            en: 'SGM'
        })
    },
    {
        id: localize({
            ru: 'Санаторий Заполярье',
            en: 'Zapolyarye Health Resort'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Санаторий Заполярье',
            en: 'Zapolyarye Health Resort'
        })
    },
    {
        id: localize({
            ru: 'Таймыргаз',
            en: 'Taimyrgaz'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Таймыргаз',
            en: 'Taimyrgaz'
        })
    },
    {
        id: localize({
            ru: 'Таймырская топливная компания',
            en: 'Taimyr Fuel Company'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Таймырская топливная компания',
            en: 'Taimyr Fuel Company'
        })
    },
    {
        id: localize({
            ru: 'Твой дом',
            en: 'Your Home'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Твой дом',
            en: 'Your Home'
        })
    },
    {
        id:  localize({
            ru: 'Ширинское',
            en: 'Shirinskoye'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Ширинское',
            en: 'Shirinskoye'
        })
    },
    {
        id: 'Norilsk Nickel Finland',
        selected: false,
        disabled: false,
        text: 'Norilsk Nickel Finland'
    }
]

export const text = {
    company: localize({
        ru: 'Выберите предприятие*',
        en: 'Choose a category*'
    }),
    name: localize({
        ru: 'Фамилия Имя Отчество',
        en: 'Full name'
    }),
    email: localize({
        ru: 'E-mail',
        en: 'E-mail'
    }),
    tel: localize({
        ru: 'Телефон',
        en: 'Phone'
    }),
    captcha: localize({
        ru: 'Введите код с картинки*',
        en: 'Enter the code from the image*'
    }),
    question: localize({
        ru: 'Ваш вопрос*',
        en: 'Question*'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки',
        en: 'Enter the code from the image'
    }),
    policy: localize({
        ru: 'Я&nbsp;ознакомлен (а) с&nbsp;<z-link href="/files/ru/corporate_documents/policies/Politika-v-oblasti-obrabotki-personalnih-dannih-2017.pdf" target="_blank">Политикой</z-link> в&nbsp;области обработки персональных данных и&nbsp;даю <z-link href="/files/ru/sustainability/agreement-hotline.pdf" target="_blank">согласие</z-link> на&nbsp;обработку своих персональных данных.',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC “MMC “Norilsk Nickel”'
    }),
    resultsAppeal: localize({
        ru: 'Прошу предоставить результаты работы с обращением',
        en: 'Please provide review results for my report'
    })
}
