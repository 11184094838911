/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flagbanner': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5a.75.75 0 01.75-.75H21a.75.75 0 01.586 1.219l-4.125 5.156 4.125 5.156A.75.75 0 0121 16.5H4.5v3.75a.75.75 0 01-1.5 0V4.5zM4.5 15h14.94l-3.526-4.406a.75.75 0 010-.938L19.44 5.25H4.5V15z" _fill="#0077C8"/>'
  }
})
