<template>
    <div class="calendar container">
        <layout>
            <template v-slot:content>
                <z-tabs kind="drop" size="m" @open-tab="onTabChange" :padding="false">
                    <template v-slot:label>
                        <z-tabs-label id="future">
                            <span v-html="text.future"></span>
                        </z-tabs-label>
                        <z-tabs-label id="past">
                            <span v-html="text.past"></span>
                        </z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content id="future">
                            <calendar-filter
                                :data="filter"
                                @change="onFilterChange"
                                v-show="currentType === 'future' && isMobileView"
                                class="u-bottom-margin--m"
                            />

                            <div class="calendar-datepicker u-bottom-padding--xl" v-if="events && events.length" :data="events">
                                <div class="row calendar-datepicker__row">
                                    <div class="col-default-5 col-v-tablet-6 col-mobile-12">
                                        <calendar-block :data="events" />
                                    </div>
                                    <div class="col-default-5 col-v-tablet-6 col-mobile-12 hide-me--mobile">
                                        <calendar-block :data="events" next-month />
                                    </div>
                                </div>
                            </div>

                            <p
                                class="gray-35-text u-bottom-margin--s text-size-l"
                                v-if="countText"
                                v-html="countText"
                            ></p>

                            <content-container>
                                <results v-if="events && events.length" :data="events" :typeOfCards="'future'"/>
                                <not-found v-else/>
                                <z-preloader v-if="isLoading"></z-preloader>
                            </content-container>
                        </z-tabs-content>
                        <z-tabs-content id="past">
                            <calendar-filter
                                :data="filterArchive"
                                @change="onFilterChange"
                                v-show="currentType === 'past' && isMobileView"
                                showPeriod
                                class="u-bottom-margin--m"
                            />

                            <p
                                class="gray-35-text u-bottom-margin--s text-size-l"
                                v-if="countText"
                                v-html="countText"
                            ></p>
                            <content-container>
                                <results
                                    class="calendar__past-events"
                                    v-if="eventsArchive && eventsArchive.length"
                                    :data="eventsArchive"
                                    :typeOfCards="'past'"
                                />
                                <not-found v-else/>
                                <z-preloader v-if="isLoading"></z-preloader>
                            </content-container>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </template>
            <template v-slot:aside>
                <div class="calendar__filter" v-show="!isMobileView">
                    <calendar-filter
                        :data="filter"
                        @change="onFilterChange"
                        v-show="currentType === 'future'"
                    />
                    <calendar-filter
                        :data="filterArchive"
                        @change="onFilterChange"
                        v-show="currentType === 'past'"
                        showPeriod
                    />
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import CalendarFilter from './components/Filter.vue'
import CalendarBlock from './components/CalendarBlock.vue'
import NotFound from './components/NotFound.vue'
import Results from './components/Results.vue'
import { getEvents, getArchiveEvents } from '@/api/calendar'
import { throttle } from 'throttle-debounce'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'calendar',
    mixins: [mixinDevice],
    components: {
        CalendarFilter,
        Results,
        CalendarBlock,
        NotFound
    },
    data () {
        return {
            events: [],
            eventsArchive: [],
            text: {
                future: localize({
                    ru: 'Предстоящие',
                    en: 'Future events'
                }),
                past: localize({
                    ru: 'Прошедшие',
                    en: 'Last events'
                }),
                filter: localize({
                    ru: 'Фильтр',
                    en: 'Filter'
                }),
                found: localize({
                    ru: 'Найдено',
                    en: 'Found'
                })
            },
            currentType: 'future',
            isLoading: false,
            params: {
                page: 1,
                types: []
            },
            paramsArchive: {
                page: 1,
                types: []
            },
            nav: {
                count: 0,
                current: 1,
                total: 1
            },
            navArchive: {
                count: 0,
                current: 1,
                total: 1
            },
            filter: {},
            filterArchive: {}
        }
    },
    created () {
        if (this.$root.app.components.events) {
            this.events = this.$root.app.components.events.items
            this.filter = this.$root.app.components.events.filter
            this.nav = this.$root.app.components.events.nav
        }
        if (this.$root.app.components['events-archive']) {
            this.eventsArchive = this.$root.app.components['events-archive'].items.filter(item => !item.withoutDate)
            this.filterArchive = this.$root.app.components['events-archive'].filter
            this.navArchive = this.$root.app.components['events-archive'].nav
        }

        this.currentType === 'future' && this.filterFutureEvents()
        this.currentType === 'past' && this.filterArchiveEvents()
    },
    mounted () {
        this.listenScroll()
    },
    computed: {
        countText () {
            const data = this.currentType === 'future' ? this.nav : this.navArchive
            if (!(data && data.count)) return ''

            const str1 = this.buildString({
                value: data.count,
                ru: ['событие', 'события', 'событий'],
                en: ['event', 'events', 'events']

            })

            return str1
        },
        isMobileView () {
            return (
                this.device === 'mobile' ||
                this.device === 'v-tablet' ||
                this.device === 'tablet'
            )
        }
    },
    methods: {
        buildString (obj) {
            if (obj.value === null) return ''
            if (obj.value.length === 0) return ''

            let string = this.$root.lang === 'ru' ? this.declOfNum(obj.value, obj.ru) : this.declOfNum(obj.value, obj.en)

            return `${this.text.found} ${obj.value} ${string}`
        },
        declOfNum (number, titles) {
            let cases = [2, 0, 1, 1, 1, 2]

            return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
        },
        onTabChange (tab) {
            this.currentType = tab.replace('tabs-', '')
            this.params.types = []
        },
        onFilterChange (data) {
            this.isLoading = false
            if (this.currentType === 'future') {
                this.params = { ...this.params, ...data }
                this.params.page = 1
                this.getEventsData()
            }

            if (this.currentType === 'past') {
                this.paramsArchive = { ...this.paramsArchive, ...data }
                this.paramsArchive.page = 1
                this.getEventsArchiveData()
            }
        },
        getEventsData () {
            this.isLoading = true

            getEvents(this.params)
                .then(res => {
                    this.isLoading = false

                    if (this.params.page < 2) {
                        this.events = res.items
                    } else {
                        this.events = this.events.concat(res.items)
                    }
                    this.nav = res.nav
                    this.filter = res.filter
                })
                .catch(error => {
                    this.isLoading = false
                    console.log(error)
                })
            this.filterFutureEvents()
        },
        getEventsArchiveData () {
            this.isLoading = true

            getArchiveEvents(this.paramsArchive)
                .then(res => {
                    this.isLoading = false

                    if (this.paramsArchive.page < 2) {
                        this.eventsArchive = res.items
                    } else {
                        this.eventsArchive = this.eventsArchive.concat(res.items)
                    }

                    this.navArchive = res.nav
                    this.filterArchive = res.filter
                })
                .catch(error => {
                    this.isLoading = false
                    console.log(error)
                })
            this.filterArchiveEvents()
        },

        filterFutureEvents () {
            this.events.sort((cur, prev) => {
                return cur.start - prev.start
            })
        },

        filterArchiveEvents () {
            this.eventsArchive.sort((cur, prev) => {
                return prev.start - cur.start
            })
        },

        loadMore () {
            if (this.currentType === 'future' && this.nav.current < this.nav.total) {
                this.params.page = this.params.page + 1
                this.getEventsData()
            }

            if (this.currentType === 'past' && this.navArchive.current < this.navArchive.total) {
                this.paramsArchive.page = this.paramsArchive.page + 1
                this.getEventsArchiveData()
            }
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.isLoading) this.loadMore()
            }))
        }

    }
}
</script>

<style lang="scss">
.calendar {
    &__past-events {
        .calendar-item {
            background: $token-colors-gray-10;
        }
    }
}

.calendar-datepicker {
    &__row {
        display: flex;
        justify-content: center;

        @include breakpoint (mobile) {
            margin-bottom: 0;
        }
    }
}
</style>
