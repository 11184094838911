/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_healthcare': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M29.093 20.224a2.279 2.279 0 00-1.744-.447C29.109 18 30 16.233 30 14.5c0-2.482-1.996-4.5-4.45-4.5a4.462 4.462 0 00-3.425 1.57A4.461 4.461 0 0018.699 10c-2.453 0-4.449 2.018-4.449 4.5 0 1.031.304 2.034.943 3.094a2.99 2.99 0 00-1.383.787l-2.12 2.12H9A1.5 1.5 0 007.5 22v3.75a1.5 1.5 0 001.5 1.5h9.75a.744.744 0 00.182-.023l6-1.5a.653.653 0 00.111-.038l3.645-1.55.04-.02a2.306 2.306 0 00.37-3.896h-.005zM18.7 11.5a2.92 2.92 0 012.732 1.781.75.75 0 001.388 0 2.92 2.92 0 012.732-1.78c1.598 0 2.949 1.373 2.949 3 0 1.828-1.48 3.897-4.28 5.99l-1.04.239a2.625 2.625 0 00-2.555-3.23h-3.686c-.81-1.091-1.189-2.051-1.189-3 0-1.627 1.35-3 2.95-3zM9 22h2.25v3.75H9V22zm19.072.77l-3.563 1.517-5.853 1.463H12.75v-4.19l2.122-2.12a1.486 1.486 0 011.06-.44h4.693a1.125 1.125 0 110 2.25H18a.75.75 0 100 1.5h3a.782.782 0 00.168-.019l6.281-1.444.03-.008a.806.806 0 01.59 1.49h.003z" _fill="#fff"/>'
  }
})
