<template>
    <section class="section-branches">
        <section-title :text="text.title" />
        <div class="section-branches__cards">
            <custom-card
                v-for="card in cards"
                :data="card"
                :key="card.imgSrc"
                :gradient="card.gradient"
                :gradient-mobile="card.gradientMobile" />
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
import SectionTitle from '../components/SectionTitle.vue'
import CustomCard from '../components/CustomCard.vue'

export default {
    name: 'section-branches',
    components: {
        SectionTitle,
        CustomCard
    },
    data () {
        return {
            text: {
                title: localize({
                    ru: 'Направления деятельности',
                    en: 'Activity Areas'
                })
            },
            cards: [
                {
                    imgSrc: '1.jpg',
                    title: localize({
                        ru: 'Цифровизация производственной цепочки',
                        en: 'Digitization of&nbsp;the Manufacturing Chain'
                    }),
                    link: '/innovation/digital-manufacturing-chain/',
                    list: localize({
                        ru: [
                            'Компьютерное зрение и&nbsp;искусственный интеллект',
                            'Цифровые технологии в&nbsp;строительстве'
                        ],
                        en: [
                            'Computer vision and artificial intelligence',
                            'Digital technologies in&nbsp;construction'
                        ]
                    }),
                },
                {
                    imgSrc: '2.jpg',
                    title: localize({
                        ru: 'Совершенствование технологии производства',
                        en: 'Improving Production Technology'
                    }),
                    link: '/innovation/improvement-technology/',
                    list: localize({
                        ru: [
                            'Аддитивные технологии',
                            'Компьютерное моделирование'
                        ],
                        en: [
                            'Additive technologies',
                            'Computer modeling'
                        ]
                    }),
                },
                {
                    imgSrc: '3.jpg',
                    title: localize({
                        ru: 'Высокотехнологичные продукты',
                        en: 'High-Tech Products'
                    }),
                    link: '/innovation/hightech-products/',
                    list: localize({
                        ru: [
                            'Новые применения палладия',
                            'Батарейные технологии',
                            'Новые материалы на основе Ni, Co (сплавы и порошки)'
                        ],
                        en: [
                            'New applications for palladium',
                            'Battery technologies',
                            'New materials based on Ni, Co (alloys and powders)'
                        ]
                    }),
                },
                {
                    imgSrc: '4.jpg',
                    title: localize({
                        ru: 'Экология и&nbsp;промышленная безопасность',
                        en: 'Ecology and Industry'
                    }),
                    link: '/innovation/ecology-industrial-safety/',
                    list: localize({
                        ru: [
                            'Климатические проекты',
                            'Переработка отходов'
                        ],
                        en: [
                            'Climate projects',
                            'Waste processing'
                        ]
                    })
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.section-branches {
    &__cards {
        display: flex;
        gap: 16px;
        width: 100%;

        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }
}
</style>
