import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class HiddenBlockAnimation {
    constructor () {
        this.hiddenBlocks = document.querySelectorAll('[data-hidden-block]')
        this.revealBlocks()
    }

    revealBlocks () {
        this.hiddenBlocks.forEach((block) => {
            gsap.set(block, {
                opacity: 0,
                y: '40%',
                scale: 0.95
                // height: 0 // height animation causes issues with other scroll triggers
            })
        })
        ScrollTrigger.batch('[data-hidden-block]', {
            start: 'top 90%',
            onEnter: (batch) => {
                gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    scale: 1
                    // height: 'auto'
                })
            },
            onLeaveBack: (batch) => {
                gsap.to(batch, {
                    opacity: 0,
                    y: '40%',
                    scale: 0.95
                    // height: 0
                })
            },
            ease: 'power3'

            // ,markers: true
        })
    }
}

export default HiddenBlockAnimation
