/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share-2': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<g clip-path="url(#clip0_4299_24903)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.458 2.182a1.775 1.775 0 100 3.55 1.775 1.775 0 000-3.55zm-2.215 3.761a2.975 2.975 0 10-.605-1.036L6.173 7.513a2.975 2.975 0 100 3.972l4.466 2.602a2.975 2.975 0 10.607-1.035l-4.467-2.604a2.971 2.971 0 000-1.899l4.464-2.606zM5.472 8.571a.614.614 0 00.04.07 1.767 1.767 0 01-.041 1.789 1.774 1.774 0 01-3.288-.93 1.775 1.775 0 013.289-.93zm6.211 6.47c0-.296.073-.575.2-.82a.615.615 0 00.09-.151 1.775 1.775 0 11-.289.971z" _fill="#0077C8"/></g><defs><clipPath id="clip0_4299_24903"><path pid="1" _fill="#fff" d="M0 0h19v19H0z"/></clipPath></defs>'
  }
})
