<template>
    <header class="header" v-click-outside="hidePopup">
        <div class="header__wrapper container">
            <div class="header__top">
                <div class="header__logo-wrapper">
                    <slot name="logo"/>
                </div>
                <header-navigation-top/>
                <div class="header__actions">
                    <div class="header-search">
                        <slot name="search"/>
                    </div>
                    <z-site-selector/>
                </div>
                <div class="header__body">
                    <navigation-mobile/>
                    <header-navigation-main/>
                </div>
            </div>
        </div>
        <header-navigation-popup/>
    </header>
</template>

<script>
import HeaderNavigationPopup from '@/components/HeaderNavigation/components/HeaderNavigationPopup.vue'
import HeaderNavigationMain from '@/components/HeaderNavigation/components/HeaderNavigationMain.vue'
import HeaderNavigationTop from '@/components/HeaderNavigation/components/HeaderNavigationTop.vue'

export default {
    name: 'HeaderNavigation',
    components: {
        HeaderNavigationTop,
        HeaderNavigationPopup,
        HeaderNavigationMain
    },
    provide () {
        return {
            currentNav: this.currentNav,
            currentNavId: this.currentNavId,
            navHandler: this.navHandler,
            getHref: this.getHref,
            isRedirect: this.isRedirect,
            isBlank: this.isBlank
        }
    },
    data () {
        return {
            currentNav: {
                value: null
            },
            currentNavId: {
                value: ''
            }
        }
    },
    methods: {
        navHandler (evt, id, item) {
            if (!item) {
                this.hidePopup()
                return
            }
            if (item.hasOwnProperty('items') && item.items.length) {
                evt.preventDefault()
                this.currentNav.value = item
                this.currentNavId.value === id
                    ? this.hidePopup()
                    : (this.currentNavId.value = id)
            } else {
                this.hidePopup()
            }
        },
        getHref (item) {
            if (Array.isArray(item.params)) {
                return item.link
            }

            if (item.params.redirect) {
                return item.params.redirect
            } else {
                return item.link
            }
        },
        isRedirect (item) {
            if (Array.isArray(item.params)) {
                return false
            }

            if (item.params.redirect) {
                return true
            } else {
                return false
            }
        },
        isBlank (item) {
            return (item.blank || item.params.blank) ? '_blank' : null
        },
        hidePopup () {
            this.currentNav.value = null
            this.currentNavId.value = ''
        }
    }
}
</script>

<style lang="scss">
</style>
