<template>
    <div class="main-page" ref="mainPage">
        <mp-slider
            :data="mainSliderSub"
            v-if="mainSliderSub && mainSliderSub.length"
            goal-name="main-1screen-slider_nav_btn"
            goal-name-thumb="main-1screen-slider_link"
        ></mp-slider>

        <section-news :news="news" />
        <section-info :events="events" />
        <section-reports
            :media="media"
            :reports="reports" />
        <section-development />
        <section class="u-bottom-margin--3xl main-page__innovation-slider" data-scroll-translate>
            <modern-slider
                :count-slides="subSliderSub.length"
                :thumbs-data="subSliderSub"
                :thumbs-default-tag="text.innovationSection.title"
                goal-name="main-6screen-slider_nav_btn"
                goal-name-thumb="main-6screen-slider_link"
                class="u-bottom-margin--2xl"
            >
                <slide
                    v-for="(slide, index) in subSliderSub"
                    :key="index"
                    class="main-page__innovation-slider-slide"
                >
                    <div class="main-page__innovation-slider-title">
                        <div class="container">
                            <div class="row u-collapse--all">
                                <z-caption
                                    uppercase
                                    tag="h2"
                                    class="white-text u-collapse--all"
                                    decor-right
                                    decor-left
                                >
                                    <span v-html="text.innovationSection.title" data-scroll-heading></span>
                                </z-caption>
                            </div>
                        </div>
                    </div>

                    <video
                        v-if="slide.previewVideo"
                        autoplay
                        loop
                        muted
                        playsinline
                        class="main-page__innovation-slider-video"
                    >
                        <source :src="slide.previewVideo" type="video/mp4">
                    </video>

                    <z-image
                        v-else
                        class="main-page__innovation-slider-image"
                        :src="slide.previewPicture"
                        background
                    ></z-image>
                </slide>
            </modern-slider>
        </section>
        <section-metals />
        <section-career />
        <section-contacts />

        <z-modal
            ref="modal"
            class="main-page__contacts-form"
            id="mainPageFormModal">
            <z-caption
                tag="h3"
                size="2xl"
                icon="message">
                <span data-scroll-heading>
                    {{ text.contactsFormTitle }}
                </span>
            </z-caption>
            <main-contacts-form @closeModal="closeModal" />
        </z-modal>
    </div>
</template>

<script>
import { usePageAnimation } from '@/utils/pageAnimation'
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import MpSlider from './components/Slider.vue'

import SectionNews from './sections/SectionNews'
import SectionInfo from './sections/SectionInfo'
import SectionReports from './sections/SectionReports'
import SectionDevelopment from './sections/SectionDevelopment'
import SectionMetals from './sections/SectionMetals'
import SectionCareer from './sections/SectionCareer'
import SectionContacts from './sections/SectionContacts'

export default {
    name: 'main-page-view',
    mixins: [mixinDevice],
    props: {
        props: {
            source: {
                type: String,
                default: 'main-page'
            }
        }
    },
    components: {
        MpSlider,
        SectionNews,
        SectionInfo,
        SectionReports,
        SectionDevelopment,
        SectionMetals,
        SectionCareer,
        SectionContacts
    },
    data () {
        return {
            lang: this.$root.lang,
            text: {
                innovationSection: {
                    title: localize({
                        ru: 'Инновации',
                        en: 'Innovations'
                    })
                },
                contactsFormTitle: localize({
                    ru: 'Отправьте ваш вопрос',
                    en: 'Send your question'
                })
            },
            news: [],
            events: [],
            media: [],
            mainSlider: [],
            mainSliderSub: [],
            subSlider: [],
            subSliderSub: [],
            subSliderNews: [],
            reports: {}
        }
    },
    created () {
        this.updateMainPage()
    },
    methods: {
        updateMainPage () {
            if (this.$root.app.components['main-page']) {
                this.news = this.$root.app.components['main-page'].news
                this.media = this.$root.app.components['main-page']['media-library']
                this.events = this.$root.app.components['main-page'].events
                    .map(({ dateFrom, dateTo, presentationLink, name, withoutDate }) => ({
                        date: {
                            start: dateFrom.timestamp,
                            end: dateTo.timestamp
                        },
                        name,
                        link: '',
                        withoutDate
                    }))
                    .reverse()
                // this.mainSlider = this.$root.app.components['main-page']['banners-top'].main
                this.mainSliderSub = this.$root.app.components['main-page']['banners-top'].sub
                this.subSlider = this.$root.app.components['main-page']['banners-bottom'].main
                this.subSliderSub = this.$root.app.components['main-page']['banners-bottom'].sub
                this.subSliderNews = this.subSlider.map(
                    ({ activeFrom, newsLink, newsConnection, name }) => [
                        {
                            date: activeFrom,
                            link: newsLink?.value || newsConnection.detailPageUrl,
                            name
                        }
                    ]
                )
                this.reports = this.$root.app.components['main-page']['results-and-reports']
            }
        },
        closeModal () {
            this.$refs.modal.closeModal({ id: 'mainPageFormModal' })
        },
        trackGoal () {
            const lang = this.$root.lang
            const goalId = lang === 'ru' ? 45303309 : 45303330
            ym(goalId, 'reachGoal', 'main-footer-links')
        }
    },
    mounted () {
        this.$nextTick(() => {
            usePageAnimation(this.$refs.mainPage)
        })

        const trackGoal = this.trackGoal
        document.querySelectorAll('.footer__info-link').forEach(function (link) {
            if (link) {
                link.addEventListener('click', trackGoal)
            }
        })
        document.querySelectorAll('.group-sites-button').forEach(function (link) {
            if (link) {
                link.addEventListener('click', trackGoal)
            }
        })
        // console.log()
        // console.log(123)
    }
}
</script>

<style src="./index.scss" lang="scss"></style>
