/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_culture': {
    width: 39,
    height: 38,
    viewBox: '0 0 39 38',
    data: '<rect pid="0" width="39" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M30.53 10.97l-3-3a.75.75 0 00-1.192.881l-5.664 5.664c-2.137-1.112-4.53-1.02-5.979.43a3.962 3.962 0 00-.88 1.346.773.773 0 01-.708.459c-1.368.046-2.555.536-3.436 1.416-1.132 1.136-1.608 2.765-1.352 4.59.25 1.747 1.156 3.477 2.556 4.869 1.4 1.392 3.126 2.302 4.875 2.552.314.047.63.071.947.073 1.436 0 2.703-.49 3.634-1.421.88-.88 1.37-2.068 1.416-3.436a.772.772 0 01.462-.708 3.958 3.958 0 001.347-.88c1.448-1.45 1.54-3.844.429-5.979l5.663-5.664a.75.75 0 00.883-1.193zm-4.28 2.469l-1.189-1.19 2.314-2.314 1.19 1.19-2.315 2.314zm-3.75 9.304a2.448 2.448 0 01-.839.547 2.272 2.272 0 00-1.406 2.052c-.034.98-.375 1.82-.978 2.426-.791.79-1.969 1.12-3.315.928-1.43-.204-2.862-.96-4.03-2.128-1.168-1.168-1.924-2.6-2.128-4.023-.187-1.348.137-2.524.928-3.315.606-.605 1.445-.944 2.426-.98a2.27 2.27 0 002.052-1.406 2.45 2.45 0 01.547-.844c.514-.515 1.218-.762 2.004-.762.618.01 1.228.143 1.793.393l-1.882 1.887a3 3 0 103.31 3.31l1.883-1.882c.611 1.433.523 2.905-.365 3.797zm-.975-5.763c.198.199.384.41.556.632l-1.446 1.446a3.021 3.021 0 00-1.188-1.189l1.445-1.446c.22.172.43.358.628.557h.005zM18 19a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm4.58-3.08a8 8 0 00-.627-.562L24 13.31l1.19 1.19-2.047 2.046a8.142 8.142 0 00-.562-.627l-.001.001zm-6.3 9.3a.75.75 0 01-1.06 1.06l-3-3a.75.75 0 011.06-1.06l3 3z" _fill="#fff"/>'
  }
})
