<template>
    <div
        :class="[
            'principle-card',
            {'principle-card--with-image': imageSrc}
        ]"
    >
       <div class="principle-card__body">
            <div v-if="numberSrc" class="principle-card__number">
                <img :src="numberSrc" class="principle-card__number-img">
            </div>

            <div
                v-if="imageSrc"
                @click="clickHanlder"
                class="principle-card__image-wrapper hide-me--default show-me--v-tablet"
            >
                <img :src="imageSrc" class="principle-card__image-img">
                <z-icon
                    class="principle-card__image-zoom"
                    name="magnifier"
                    width="24"
                    height="24"
                ></z-icon>
            </div>

            <div
                v-if="description"
                class="principle-card__description"
                v-html="description"
            ></div>
       </div>

       <div v-if="imageSrc" @click="clickHanlder" class="principle-card__image-wrapper hide-me--v-tablet">
            <div class="principle-card__image-bg" :style="{ backgroundImage: `url(${imageSrc})` }">
                <div class="principle-card__image-overlay">
                    <z-icon
                        name="magnifier"
                        width="48"
                        height="48"
                    ></z-icon>
                </div>
            </div>
       </div>
    </div>
</template>

<script>
export default {
    name: 'principle-card',
    props: {
        numberSrc: String,
        imageSrc: String,
        description: String
    },
    methods: {
        clickHanlder () {
            const data = [
                {
                    'type': 'image',
                    'image': {
                        'preview': this.imageSrc,
                        'full': this.imageSrc
                    }
                }
            ]

            this.$emit('open-modal', data)
        }
    }
}
</script>

<style lang="scss">
.principle-card {
    --bodyPadding: #{$token-spacers-xl} #{$token-spacers-2-xl};

    &--with-image {
        --bodyPadding: #{$token-spacers-xl} 72px #{$token-spacers-xl} #{$token-spacers-2-xl};
    }
}

.principle-card {
    $parent: &;

    width: 100%;
    display: flex;
    border-radius: 0px 40px 40px 40px;
    background: $token-colors-white;
    overflow: hidden;

    &__body {
        width: 100%;
        display: flex;
        gap: $token-spacers-m;
        padding: var(--bodyPadding);

        @include breakpoint(tablet) {
            gap: $token-spacers-s;
            padding: $token-spacers-m $token-spacers-xl;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: $token-spacers-xs;
            padding: $token-spacers-s $token-spacers-m;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-xs;
        }
    }

    &__number-img {
        max-width: 106px !important;

        @include breakpoint(tablet) {
            max-width: 76px !important;
        }
    }

    &__description {
        color: $token-colors-gray-60;
        font-size: 22px;
        line-height: 130%;
        letter-spacing: -0.11px;

        @include breakpoint(tablet) {
            font-size: 18px;
        }

        @include breakpoint(v-tablet) {
            font-size: 16px;
        }
    }

    &__image-wrapper {
        position: relative;
        width: 42%;
        max-width: 418px;

        @include breakpoint(v-tablet) {
            width: 100%;
            max-width: none;
        }
    }

    &__image-bg {
        max-width: 418px;
        width: 100%;
        max-height: 228px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 0px 18.6px 0px 20px;
        aspect-ratio: 1.8;
        position: relative;
        overflow: hidden;
    }

    &__image-overlay {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity $transition;
        cursor: zoom-in;

        &:hover {
            opacity: 1;
        }

        .z-icon {
            path {
                fill: $token-colors-white;
            }
        }
    }

    &__image-zoom {
        position: absolute;
        right: 8px;
        bottom: 8px;
    }
}
</style>
