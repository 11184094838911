/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mine': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_9226_32631)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.417 45.764H22.91l-1.394 2.825H1.522v-2.825h1.895zm1.103-1.522L19.083 4.73h25.835L59.48 44.242h-4.166l-10.663-30.92a.765.765 0 00-.723-.51H32a.767.767 0 00-.109.008H20.08a.765.765 0 00-.723.51L8.678 44.242H4.52zM32.008 3.207h-13.45a.759.759 0 00-.716.495L2.895 44.242H.761a.763.763 0 00-.761.761v4.347c0 .418.343.76.761.76h20.004l-4.095 8.297a.752.752 0 00.677 1.096c.282 0 .548-.16.685-.426l7.215-14.607h.93l-4.24 15.338a.77.77 0 00.738.967c.335 0 .64-.228.731-.563l2.792-10.1H37.93l3.013 10.114a.763.763 0 00.73.549.7.7 0 00.214-.039.752.752 0 00.51-.944L37.834 44.47h.923l7.21 14.607a.76.76 0 00.686.427.765.765 0 00.677-1.104l-4.092-8.29h20a.754.754 0 00.762-.76v-4.347a.763.763 0 00-.761-.76h-2.131L46.166 3.701a.766.766 0 00-.716-.495H32.007zm4.049 40.435a.761.761 0 00.03.28l.096.32H27.82l.071-.258a.754.754 0 00.052-.275v-5.137h8.114v5.07zm.58 2.122l.84 2.826H26.619l.781-2.826H36.636zm23.938 0H41.092l1.395 2.825h19.99v-2.825h-1.902zm-28.459-31.43a.766.766 0 01-.108.009H20.62l-10.322 29.9h13.365l.345-.7v-4.971h-1.332a.757.757 0 01-.754-.663L20.08 24.08a.753.753 0 01.183-.601.746.746 0 01.57-.26h4.5v-3.531c0-.419.342-.761.76-.761h11.814c.418 0 .76.342.76.761v3.532h4.5c.22 0 .426.091.57.259.145.167.213.38.183.6L42.078 37.91a.757.757 0 01-.754.662h-1.332v4.964l.349.706h13.361L43.387 14.335h-11.27zM32 20.449h-5.145v2.77h10.29v-2.77H32zm5.895 4.293H21.71l1.636 12.308h1.395a.711.711 0 01.058 0h2.355a.707.707 0 01.058 0H40.655l1.636-12.308h-4.396zm-.316 13.83h.89v4.377h-.89v-4.376zm-11.159 0h-.89v4.377h.89v-4.376zM32 35.665h-6.592a.757.757 0 01-.753-.663l-.64-4.818a.757.757 0 01.655-.852c.41-.054.8.236.852.654l.556 4.164h5.813A.767.767 0 0132 34.14h5.922l.556-4.164a.766.766 0 01.852-.654.761.761 0 01.655.852l-.64 4.818a.763.763 0 01-.753.67H32z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_32631"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
