<template>
    <div
        ref="container"
        class="modal-video"
        :style="`width: ${maxWidth}px`"
    >

        <div
            class="modal-video__media"
            >
            <z-video ref="video"
                :muted="false"
                :src="data.image.full"
                :autoplay="data.autoplay"
                :poster="data.image.preview"
            />
        </div>
        <div class="modal-video__info" v-if="data.name || data.detail">
            <div
                ref="name"
                v-if="data.name"
                v-html="data.name"
                class="modal-video__name"
            ></div>
            <div
                ref="detail"
                v-html="data.detail"
                class="modal-video__detail"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        offsetTop: {
            type: Number,
            default: 0
        },
        offsetBottom: {
            type: Number,
            default: 0
        },
    },
    data () {
        return {
            ratio: 0.56,
            maxWidth: 1024, // пересчитывается на resize
            defaultMaxWidth: 1024
        }
    },
    mounted () {
        this.setMaxWidth()

        if (this.$refs.container) {
            this.defaultMaxWidth = parseFloat(this.$refs.container.style.width)
        }

        window.addEventListener('resize', this.setMaxWidth)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.setMaxWidth)
    },
    updated () {
        this.$nextTick(() => this.setMaxWidth())
    },
    methods: {
        setMaxWidth () {
            let nameHeight, detailHeight

            this.$refs['name'] ? nameHeight = this.$refs['name'].offsetHeight : nameHeight = 0
            this.$refs['detail'] ? detailHeight = this.$refs['detail'].offsetHeight : detailHeight = 0

            const availableHeight = window.innerHeight - (this.offsetTop + this.offsetBottom + nameHeight + detailHeight)
            const maxWidth = Math.floor(availableHeight / this.ratio)

            if (maxWidth < this.defaultMaxWidth) {
                this.maxWidth = maxWidth
            }
        },
        update () {
            this.$nextTick(() => this.setMaxWidth())
        }
    }
}
</script>

<style lang="scss">
    .modal-video {
        width: 1024px;
        max-width: 100%;
        margin: 0 auto;

        &__name {
            font-size: 28px;
            line-height: 1.2;
            color: $token-colors-black;
            margin: 0 !important;
            padding-bottom: 24px;

        }

        &__info {
            @include padding-level(top, S);
            // @include padding-level(bottom, S);
            @include padding-level(left, S);
            @include padding-level(right, S);
        }

        &__detail {
            font-size: 13px;
            @include typo-level(S);
            // @include margin-level(bottom, L);
            // line-height: 18px;
            // padding-top: 24px;
        }
    }
</style>
