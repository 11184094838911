/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leader_big': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M45.224 41.585V77h2V41.214a6.787 6.787 0 002.731-1.866c1.138-1.267 1.82-2.921 1.82-4.451v-1H47.225v-6.503c4.447-1.816 9.563-1.644 13.794.789V15.779a15.837 15.837 0 00-13.794-.696V11h-2v22.897H24.603c-2.746 0-5.26.431-7.11 1.66-1.926 1.279-2.993 3.323-2.993 6.167v11.38c0 2.941 2.61 5.551 5.552 5.551v-2c-1.838 0-3.552-1.714-3.552-3.552V41.724c0-2.276.811-3.646 2.1-4.5 1.363-.906 3.402-1.327 6.003-1.327H45.224v3.687a4.525 4.525 0 01-.138.002H33.845V77h2V41.586h9.241l.138-.001zm2-2.565c.468-.264.885-.609 1.243-1.008a5.303 5.303 0 001.167-2.115H47.224v3.123zm13.794-19.712v12.29c4.551-1.707 9.444-.91 13.769 2.162l-1.025-1.821-3.3-6.145 4.325-4.324c-4.325-3.072-9.218-3.869-13.77-2.162zm-39.69 4.779c0-3.453 2.553-6.349 6.12-6.349s6.121 2.896 6.121 6.349v1.82c0 3.453-2.554 6.348-6.12 6.348-3.594 0-6.121-3.037-6.121-6.462v-1.706zm6.12-4.349c-2.35 0-4.12 1.884-4.12 4.349v1.707c0 2.492 1.796 4.462 4.12 4.462 2.35 0 4.121-1.884 4.121-4.349v-1.82c0-2.465-1.77-4.349-4.12-4.349zm-7.258 57.26V41.724h2v35.276h-2zm6.828-20.48V77h2V56.518h-2z" _fill="#0077C8"/>'
  }
})
