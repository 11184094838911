/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/danger': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><g clip-path="url(#clip0_9226_31981)"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M25.248 19.56c-5.896 0-10.688 4.793-10.688 10.689s4.792 10.688 10.688 10.688c5.896 0 10.688-4.792 10.688-10.688 0-5.896-4.792-10.688-10.688-10.688zM13.622 30.25c0-6.414 5.212-11.626 11.626-11.626s11.626 5.212 11.626 11.626-5.212 11.626-11.626 11.626-11.626-5.212-11.626-11.626zm6.799-8.29a.469.469 0 01.64.17l2.966 5.133a.47.47 0 01-.073.564 2.74 2.74 0 012.537-.026.469.469 0 01-.04-.529l2.965-5.132c.13-.224.415-.3.64-.172a9.621 9.621 0 014.837 8.356c0 .259-.21.469-.469.469h-5.931a.469.469 0 110-.938h5.45a8.68 8.68 0 00-3.955-6.83l-2.726 4.716a.47.47 0 01-.484.228 2.747 2.747 0 11-3.03-.02.469.469 0 01-.533-.217l-2.725-4.716a8.677 8.677 0 00-3.939 6.84h5.452a.469.469 0 110 .937H16.07a.469.469 0 01-.469-.468 9.617 9.617 0 014.819-8.366zm-.17 16.074a.469.469 0 00.171.64 9.617 9.617 0 009.654-.01c.224-.13.3-.417.17-.64l-2.965-5.137a.469.469 0 00-.812.468l2.726 4.722a8.677 8.677 0 01-7.892.008l2.721-4.718a.469.469 0 00-.812-.468l-2.962 5.135zm3.308-7.135a1.81 1.81 0 113.378-1.297 1.81 1.81 0 01-3.378 1.297z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31981"><path pid="2" _fill="#fff" transform="translate(13 18)" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
