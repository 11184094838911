<template>
    <div v-if="currentDirection" class="innovation-detail-page">
        <div class="container">
            <div class="innovation-detail-page__background">
                <canvas class="scene scene--full" id="scene"></canvas>
                <script type="x-shader/x-vertex" id="wrapVertexShader">
                    attribute float size;
                    attribute vec3 color;
                    varying vec3 vColor;
                    void main() {
                        vColor = color;
                        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
                        gl_PointSize = size * ( 350.0 / - mvPosition.z );
                        gl_Position = projectionMatrix * mvPosition;
                    }
                </script>
                <script type="x-shader/x-fragment" id="wrapFragmentShader">
                    varying vec3 vColor;
                    uniform sampler2D texture;
                    void main(){
                        vec4 textureColor = texture2D( texture, gl_PointCoord );
                        if ( textureColor.a < 0.3 ) discard;
                        vec4 color = vec4(vColor.xyz, 1.0) * textureColor;
                        gl_FragColor = color;
                    }
                </script>
            </div>

            <div ref="wrapper" class="innovation-detail-page__wrapper">
                <div class="innovation-detail-page__header">
                    <z-breadcrumbs class="hide-me--v-tablet" theme="white"/>
                    <z-caption
                        class="innovation-detail-page__title"
                        tag="h1"
                        size="4xl"
                        weight="600"
                        theme="white"
                    >{{ currentDirection.title }}</z-caption>
                    <div class="row">
                        <div class="col-default-6 col-tablet-10 col-v-tablet-12" style="position: relative;">
                            <p
                                data-animated-text
                                class="innovation-detail-page__description"
                                v-html="currentDirection.description"
                            ></p>
                        </div>
                    </div>
                </div>

                <div class="innovation-detail-page__body">
                    <div v-if="currentDirection.projects.length" class="innovation-detail-page__projects">
                        <template v-for="(project, index) in currentDirection.projects">
                            <div v-if="project.id"
                                :key="project.id"
                                data-project-card
                                :class="[
                                    'innovation-detail-page__project-card',
                                    `innovation-detail-page__project-card--${index + 1}`
                                ]"
                            >
                                <div class="innovation-detail-page__project-wrapper">
                                    <z-caption
                                        v-if="project.title"
                                        data-project-card-title
                                        class="innovation-detail-page__project-title"
                                        decor-right
                                        tag="h2"
                                        weight="600"
                                    >
                                        <span v-html="project.title"></span>
                                    </z-caption>
                                    <p
                                        v-if="project.description"
                                        class="innovation-detail-page__project-text"
                                        v-html="project.description"
                                    ></p>
                                    <div
                                        v-if="project.subProjects.length"
                                        data-project-card-buttons
                                        class="innovation-detail-page__project-buttons"
                                    >
                                        <template v-for="subProject in project.subProjects">
                                            <custom-button
                                                :key="subProject.id"
                                                data-project-card-button
                                                class="innovation-detail-page__project-button"
                                                @click="openPopupHandler(project.id, subProject.id)"
                                            >
                                                <span v-html="subProject.title"></span>
                                            </custom-button>
                                        </template>
                                    </div>
                                </div>
                                <div
                                    data-project-card-bg
                                    :class="[
                                        'innovation-detail-page__project-bg',
                                        {'innovation-detail-page__project-bg--crop-image': index === 1}
                                    ]"
                                >
                                    <img :src="projectImages[index] || projectImages[0]" />
                                </div>
                            </div>
                        </template>
                    </div>

                    <z-link class="innovation-detail-page__back" href="/innovation/#areas-of-activity">
                        <template v-slot:left>
                            <div class="innovation-detail-page__back-icon">
                                <z-icon width="24" height="24" dir="left" name="arrow-long" />
                            </div>
                        </template>
                        <span class="innovation-detail-page__back-text" v-html="text.back"></span>
                    </z-link>
                </div>
            </div>
        </div>
        <z-modal
            id="projectPopup"
            @close="closePopupHandler"
            class="innovation-detail-page__project-popup"
        >
            <project-popup
                v-if="popupIsOpen"
                :project="openedProject"
                :sub-project-id="openSubProjectId"
            ></project-popup>
        </z-modal>

        <custom-modal
            v-show="!popupIsOpen"
            ref="formModal"
            class="innovation-detail-page__modal"
            @close="closeFormHandler"
        >
            <template v-slot:trigger>
                <form-button
                    :start-offset="600"
                    @click="openFormHandler"
                ></form-button>
            </template>
            <innovations-form
                class="innovation-detail-page__form"
            ></innovations-form>
        </custom-modal>
        <div data-end-trigger class="innovation-detail-page__end-trigger"></div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import PageNav from '../../components/PageNav/PageNav.vue'
import CustomButton from './components/CustomButton.vue'
import ProjectPopup from './components/ProjectPopup.vue'
import SphereBackground from './modules/sphere-background'
import ProjectCardAnimation from './modules/project-card-animation'
import TextAnimation from './modules/text-animation'
import FormButton from './components/FormButton'
import { directions } from './directions'

export default {
    name: 'innovation-detail-page',
    components: {
        PageNav,
        CustomButton,
        ProjectPopup,
        FormButton
    },
    props: {
        direction: String
    },
    data () {
        return {
            currentDirection: null,
            openedProject: null,
            openSubProjectId: null,
            popupIsOpen: false,
            wrapper: null,
            projectCardAnimation: null,
            textAnimation: null,
            projectImages: [
                '/images/innovation/digital-manufacturing-chain/project-first-bg.png',
                '/images/innovation/digital-manufacturing-chain/project-second-bg.png',
                '/images/innovation/digital-manufacturing-chain/project-third-bg.png'
            ],
            text: {
                back: localize({
                    ru: 'Назад',
                    en: 'Back'
                })
            },
        }
    },
    methods: {
        openPopupHandler (projectId, subProjectId) {
            this.openedProject = this.currentDirection.projects.find(item => item.id === projectId)
            if (!this.openedProject) return

            const hasChildProjects = this.openedProject.subProjects.some(item => item.subProjects && item.subProjects.length)
            if (hasChildProjects) {
                this.openedProject = this.openedProject.subProjects.find(item => item.id === subProjectId)
            }

            this.openSubProjectId = subProjectId
            this.popupIsOpen = true

            this.projectCardAnimation.pause()
            this.$root.$bus.$emit('open-modal', { id: 'projectPopup' })
        },
        closePopupHandler () {
            this.openedProject = null
            this.openSubProjectId = null
            this.popupIsOpen = false

            this.projectCardAnimation.play()
        },
        openFormHandler () {
            this.$refs.formModal.openModal()
            this.projectCardAnimation.pause()
        },
        closeFormHandler () {
            this.projectCardAnimation.play()
        },
    },
    mounted () {
        this.currentDirection = directions[this.direction]
        if (!this.currentDirection) return

        this.$nextTick(() => {
            this.wrapper = this.$refs.wrapper

            SphereBackground()
            this.projectCardAnimation = new ProjectCardAnimation()
            this.textAnimation = new TextAnimation(this.wrapper)
        })
    }
}
</script>

<style lang="scss">
.innovation-detail-page {
    $parent: &;

    height: 100%;
    background: rgb(0,44,111);
    background: linear-gradient(180deg, rgba(0,44,111,1) 50%, rgba(2,132,190,1) 100%);
    padding-bottom: $token-spacers-l;
    position: relative;
    overflow: hidden;

    @include breakpoint(v-tablet) {
        padding-bottom: $token-spacers-m;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    &__background {
        max-width: 1920px;
        min-width: 1300px;
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        right: -35%;
        aspect-ratio: 1;
        z-index: 1;

        @media screen and (min-width: 1920px) {
            right: -500px;
        }

        @include breakpoint(tablet) {
            right: -50%;
        }

        @include breakpoint(v-tablet) {
            right: -800px;
        }

        @include breakpoint(mobile) {
            min-width: 900px;
            right: -600px;
        }

        canvas {
            width: 100%;
        }
    }

    &__wrapper {
        padding-top: $token-spacers-l;
        position: relative;
        overflow: hidden;
        z-index: 1;

        @include breakpoint(v-tablet) {
            padding-top: $token-spacers-2-xs;
        }
    }

    &__header {
        color: $token-colors-white;
    }

    &__title {
        margin-top: $token-spacers-xs;
        margin-bottom: 60px !important;

        @include breakpoint(mobile) {
            margin-top: $token-spacers-s;
            margin-bottom: $token-spacers-xs !important;
        }
    }

    &__description {
        font-size: 36px;
        line-height: 140%;
        letter-spacing: -0.36px;
        margin-bottom: 93px !important;

        @include breakpoint(v-tablet) {
            font-size: 30px;
        }

        @include breakpoint(mobile) {
            font-size: 24px;
            margin-bottom: $token-spacers-m !important;
        }

        &--blue {
            color: #5DBDFF;
        }
    }

    &__projects {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-xs;

        @include breakpoint(mobile) {
            gap: $token-spacers-m;
        }
    }

    &__project-card {
        background: #F4F8FF;
        border-radius: 0px 40px 40px 40px;
        padding: $token-spacers-xl $token-spacers-2-xl;
        position: relative;

        @include breakpoint(tablet) {
            padding: $token-spacers-l $token-spacers-m;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-m $token-spacers-xs;
        }

        &--1 {
            &.active {
                #{$parent}__project-bg {
                    @include breakpoint(v-tablet) {
                        right: -15%;
                    }
                }
            }
        }

        &--2 {
            #{$parent}__project-bg {
                transform: scale(0.75);
            }
        }

        &--3 {
            #{$parent}__project-bg {
                @include breakpoint(tablet) {
                    right: -15%;
                }
            }
        }
    }

    &__project-wrapper {
        position: relative;
        z-index: 2;
    }

    &__project-title {
        margin-bottom: $token-spacers-s !important;

        @include breakpoint(tablet) {
            &:after {
                display: none;
            }
        }

        @include breakpoint(v-tablet) {
            font-size: 30px !important;
        }

        @include breakpoint(mobile) {
            font-size: 24px !important;
        }
    }

    &__project-text {
        max-width: 1060px;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: -0.12px;
        color: $token-colors-gray-60;
        margin-bottom: 0 !important;

        @include breakpoint(mobile) {
            font-size: 20px;
        }
    }

    &__project-buttons {
        display: flex;
        align-items: center;
        gap: $token-spacers-xs;

        @include breakpoint(tablet) {
            flex-direction: column;
            align-items: flex-start;
            gap: $token-spacers-2-xs;
        }

        @include breakpoint(mobile) {
            align-items: center;
        }
    }

    &__project-bg {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        right: 0;
        transform-origin: bottom right;
        transform: scale(0.5);
        z-index: 1;

        @include breakpoint(tablet) {
            max-height: 480px;
            right: -28%;
            transform: scale(0.75);

            img {
                height: 100%;
            }
        }

        @include breakpoint(v-tablet) {
            right: -33%;
            transform: scale(0.75) !important;

            img {
                height: auto;
            }
        }

        &--crop-image {
            img {
                height: 100%;
            }
        }
    }

    &__contact-btn {
        position: absolute;
        overflow: hidden;
        bottom: clamp(16px, 7.5%, 58px);
        z-index: 3;
        right: 0;
        text-align: right;
        @include margin-level(right, 2XL);

        @include breakpoint(v-tablet) {
            @include margin-level(right, M);
        }

        .z-button {
            padding: $token-spacers-2-xs;
        }
    }

    &__contact-btn-text {
        white-space: nowrap;
        margin-right: 9px;
        margin-left: $token-spacers-2-xs;
    }

    &__back {
        background: transparent;
        border: none;
        margin-top: $token-spacers-s;

        .z-link__text {
            color: $token-colors-white;
        }
    }

    &__back-icon {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $token-colors-white;
        border-radius: 50%;
        margin-right: $token-spacers-2-xs;
    }

    &__back-text {
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.2px;
        color: $token-colors-white;
    }

    &__project-popup {

        &.z-modal {
            padding: $token-spacers-xl $token-spacers-2-xl;

            @include breakpoint(tablet) {
                padding: $token-spacers-m;
            }

            @include breakpoint(v-tablet) {
                padding: $token-spacers-2-xs;
            }

            @include breakpoint(mobile) {
                padding: $token-spacers-3-xs;
            }

            & > .z-modal__container {
                max-width: 1760px;
                border-radius: 0 40px 0 0;

              & > .modal-html {
                    padding: 0;

                   & > .z-modal__close {
                        display: none;
                    }
                }
            }
        }
    }

    .form-captcha__captcha img {
        height: 50px;
    }
}
</style>
