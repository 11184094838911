<template>
    <div
        class="announce"
        :class="buildClass"
        :style="!setImageAtInner ? `background-image: url(${imageSrc})` : ''"
    >
        <div
            :class="[
                'announce__inner',
                setImageAtInner && 'announce__inner--image',
                !image && 'announce__inner--placeholder'
            ]"
            :style="setImageAtInner ? `background-image: url(${imageSrc})` : ''"
        >
            <z-caption
                v-if="caption"
                class="announce__caption u-top-margin--s"
                :href="link"
                decor-left
                weight="700"
                uppercase
                :size="size"
                :theme="theme === 'white' && 'white'"
            >
                <span v-html="`${caption}`"></span>
            </z-caption>
        </div>
        <date-block
            v-if="date"
            class="announce__date"
            :date="date"
            :theme="theme === 'white' && 'white'"
        />
    </div>
</template>

<script>
export default {
    name: 'announce',
    props: {
        link: String,
        image: String,
        caption: String,
        date: [String, Number],
        theme: {
            type: String,
            default: 'white',
            validator: prop => ['default', 'white'].includes(prop)
        },
        size: {
            type: String,
            default: 'm',
            validator: prop => ['5xl', '4xl', '3xl', '2xl', 'xl', 'l', 'm', 's', 'xs'].includes(prop)
        },
        setImageAtInner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buildClass () {
            return [
                `announce--theme-${this.theme}`
            ]
        },
        imageSrc () {
            return this.image || '/images/news-placeholder.webp'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
