<template>
    <section class="section-development">
        <section-title :text="text.title" />
        <div class="section-development__block">
            <div class="section-development__block-container">
                <icon-block
                    icon-name="financial-growth"
                    size="s">
                    <p class="section-development__icon-text">
                        {{ text.iconText.growth.caption }}
                    </p>
                </icon-block>
                <div
                    class="section-development__hidden-container"
                    data-hidden-block>
                    <div class="section-development__hidden-decor">
                        {{ text.goals }}
                    </div>
                    <p
                        class="section-development__hidden-text"
                        v-html="text.iconText.growth.description"></p>
                </div>
            </div>
            <div class="section-development__block-container">
                <icon-block
                    icon-name="esg-numbers"
                    size="s">
                    <p class="section-development__icon-text">{{ text.iconText.esg.caption }}</p>
                </icon-block>
                <div
                    class="section-development__hidden-container"
                    data-hidden-block>
                    <div class="section-development__hidden-decor">
                        {{ text.goals }}
                    </div>
                    <p
                        class="section-development__hidden-text"
                        v-html="text.iconText.esg.description"></p>
                </div>
            </div>
            <div class="section-development__block-container">
                <icon-block
                    icon-name="tech-leadership"
                    size="s">
                    <p class="section-development__icon-text">
                        {{ text.iconText.leadership.caption }}
                    </p>
                </icon-block>
                <div
                    class="section-development__hidden-container"
                    data-hidden-block>
                    <div class="section-development__hidden-decor">
                        {{ text.goals }}
                    </div>
                    <p
                        class="section-development__hidden-text"
                        v-html="text.iconText.leadership.description"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HiddenBlockAnimation from '../modules/hidden-block-animation'
import { localize } from '@/utils/i18n'
import SectionTitle from '../components/SectionTitle.vue'

export default {
    name: 'section-development',
    components: {
        SectionTitle
    },
    data() {
        return {
            text: {
                title: localize({
                    ru: 'Направления развития',
                    en: 'Development Areas'
                }),
                iconText: {
                    growth: {
                        caption: localize({
                            ru: 'Рост финансовых показателей',
                            en: 'Financial Growth'
                        }),
                        description: localize({
                            ru: 'Ежегодное увеличение EBITDA при реализации портфеля за&nbsp;счет цифровизации производства, совершенствования технологий, развития новых высокотехнологичных продуктов.',
                            en: 'Achieve annual EBITDA growth through portfolio implementation by&nbsp;digitizing production, improving technologies, and developing new high-tech products.'
                        })
                    },
                    esg: {
                        caption: localize({
                            ru: 'Повышение ESG показателей',
                            en: 'Improving ESG Performance'
                        }),
                        description: localize({
                            ru: 'Снижение выбросов парниковых газов и&nbsp;реализация более долгосрочных front-end проектов по&nbsp;достижению углеродной нейтральности. <br><br> Реализация инновационных технологических проектов по&nbsp;актуальным ESG направлениям, вкл. утилизацию серы.',
                            en: 'Reduce greenhouse gas emissions and implement long-term front-end projects to&nbsp;achieve carbon neutrality. <br><br> Implement innovative technological projects in&nbsp;key ESG areas, including sulfur utilization.'
                        })
                    },
                    leadership: {
                        caption: localize({
                            ru: 'Глобальное технологическое лидерство',
                            en: 'Global Technological Leadership'
                        }),
                        description: localize({
                            ru: 'Формирование собственных R&D компетенций и инфраструктуры, развитие глобальной партнерской сети для обеспечения долгосрочного конкурентного преимущества на мировых рынках',
                            en: 'Develop in-house R&amp;D competencies and infrastructure. <br><br> Expand a&nbsp;global partnership network to&nbsp;secure long-term competitive advantages in&nbsp;global markets.'
                        })
                    }
                },
                goals: localize({
                    ru: 'Цели',
                    en: 'Goals'
                })
            },
            hiddenBlockAnimation: null
        }
    },
    mounted() {
        this.hiddenBlockAnimation = new HiddenBlockAnimation()
    }
}
</script>

<style lang="scss">
.section-development {
    &__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 160px;

        @include breakpoint(laptop) {
            gap: 80px;
        }
        @include breakpoint(tablet) {
            gap: 48px;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }
    }

    &__block-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
    }

    &__hidden-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 26px;

        @include breakpoint(tablet) {
            margin-top: 18px;
        }

        @include breakpoint(v-tablet) {
            @include margin-level(top, L);
        }
    }

    &__hidden-decor {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 100%;
        color: $token-colors-gray-20;
        line-height: 1.4;
        @include typo-level(M);
        @include margin-level(bottom, XS);

        &::before,
        &::after {
            content: '';
            display: block;
            flex: 1;
            border-top: 1px solid $token-colors-gray-20;
            height: 15px;
        }
        &::before {
            border-left: 1px solid $token-colors-gray-20;
            margin-right: 8px;
        }

        &::after {
            border-right: 1px solid $token-colors-gray-20;
            margin-left: 8px;
        }
    }

    &__hidden-text {
        font-size: 20px;
        color: $token-colors-gray-58;
        line-height: 1.4 !important;
        font-weight: 400 !important;
        letter-spacing: 0.01em;

        @include breakpoint(v-tablet) {
            font-size: 16px;
        }
    }

    .icon-block {
        margin-top: 0;

        &__item {
            @include breakpoint(v-tablet) {
                flex-direction: row;
                align-items: center;
            }
        }

        &__icon {
            @include breakpoint(v-tablet) {
                margin-bottom: 0;

                width: 48px;
                height: 48px;

                svg {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }

    &__icon-text {
        @include typo-level(2XL);
        font-weight: 600;
        line-height: 1.2;
        color: $token-colors-gray-60;
        max-width: 330px;
        height: 3lh;

        @include breakpoint(v-tablet) {
            height: auto;
        }
    }
}
</style>
