/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_5706_48347)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.916 9.562a15.25 15.25 0 0120.168 0 .75.75 0 00.992-1.125 16.75 16.75 0 00-22.152 0 .75.75 0 10.992 1.125zm3.564 3.564a10.25 10.25 0 0113.12 0 .75.75 0 10.96-1.152 11.75 11.75 0 00-15.04 0 .75.75 0 00.96 1.152zm6.525 2.626a5.25 5.25 0 00-3.04.97.75.75 0 01-.87-1.223 6.75 6.75 0 017.82 0 .75.75 0 01-.87 1.223 5.25 5.25 0 00-3.04-.97zM12 19.25a.75.75 0 000 1.5h.01a.75.75 0 000-1.5H12z" _fill="#0077C8"/></g><defs><clipPath id="clip0_5706_48347"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
